// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chapter-editor .ql-editor{height:13em}.chapter-editor .ql-editor p{font-size:1.2em;margin-bottom:.35em}", "",{"version":3,"sources":["webpack://./resources/js/components/chapters/chapter-editor.vue"],"names":[],"mappings":"AAGE,2BACE,WADJ,CAGI,6BACE,eAAA,CACA,mBADN","sourcesContent":["\n@import '@vueup/vue-quill/dist/vue-quill.snow.css';\n.chapter-editor {\n  .ql-editor {\n    height: 13em;\n\n    p {\n      font-size: 1.2em;\n      margin-bottom: 0.35em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<script>
import BannerStack from '../common/banner-stack.vue';
import Axios from 'axios';
import ObjectUtil from '../../shared/objectutil.js';
import timezones from 'timezones-list';
export default {
  components: {
    BannerStack
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {
          name: '',
          timeZone: '-1'
        };
      }
    }
  },
  emits: {
    updated: null
  },
  data() {
    return this.initialData();
  },
  computed: {
    displayNameValidationErrors() {
      if (!this.$data.displayName) {
        return 'Required';
      }

      if (!/^[\w -]+$/.test(this.$data.displayName)) {
        return 'Display name may only contain alphanumeric characters, _, -, and spaces';
      }

      return null;
    },
    timeZoneValidationErrors() {
      if (!this.$data.timeZone || this.$data.timeZone == '-1') {
        return 'Required';
      }

      if (!timezones.find(x => x.tzCode == this.$data.timeZone)) {
        return 'Invalid time zone selected';
      }

      return null;
    },
    isFormValid() {
      // Don't make the form ugly until a submission attempt has been made.
      if (!this.$data.validationEnabled) {
        return true;
      }

      return !this.displayNameValidationErrors &&
        !this.timeZoneValidationErrors;
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  methods: {
    beforeWindowUnload(e) {
      if (ObjectUtil.commonPropertiesMismatch(this.$data.initialState, this.$data)) {
        e.preventDefault();
        return e.returnValue = 'There are unsaved changes. Are you sure you want to leave?';
      }
    },
    initialData() {
      let data = {
        validationEnabled: false,
        displayName: this.userInfo?.name,
        timeZone: this.userInfo?.timeZone,
        timeZones: timezones,
        submitPending: false
      };

      data.initialState = {
        timeZone: data.timeZone,
        displayName: data.displayName
      };

      return data;
    },
    reset() {
      Object.assign(this.$data, this.initialData());
    },
    handleSave() {
      this.$data.validationEnabled = true;

      if (!this.isFormValid) {
        return;
      }

      this.$data.submitPending = true;
      this.$refs.banners.clearType('danger');

      let formData = new FormData();
      formData.append('name', this.$data.displayName);
      formData.append('timeZone', this.$data.timeZone);

      // Spoof the method so Laravel understands the form body.
      formData.append('_method', 'PUT');

      Axios.post(`/api/user/${this.userInfo.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          this.$emit('updated');
          this.$refs.banners.add({
            class: 'success',
            dismissible: true,
            message: 'Settings saved successfully!',
            timeout: 5000
          });
        })
        .catch((error) => {
          let errorMessage = "Failed to save user settings. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.banners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        })
        .then(() => {
          this.$data.submitPending = false;
        });
    }
  }
};
</script>

<template>
  <div class="user-settings">
    <h1>User Preferences</h1>
    <banner-stack ref="banners" />
    <form
      novalidate
      @submit.prevent.stop="handleSave"
    >
      <div class="mb-3">
        <label
          class="form-label label-required"
          for="displayNameField"
        >Display Name</label>
        <input
          id="displayNameField"
          v-model.trim="displayName"
          class="form-control"
          :class="{ 'is-invalid': validationEnabled && !!displayNameValidationErrors}"
          placeholder="John Doe"
        >
        <div class="invalid-feedback">
          {{ displayNameValidationErrors }}
        </div>
      </div>
      <div class="mb-3">
        <label
          class="form-label label-required"
          for="timeZoneField"
        >Time Zone</label>
        <select
          id="timeZoneField"
          v-model="timeZone"
          class="form-select"
          :class="{ 'is-invalid': validationEnabled && !!timeZoneValidationErrors}"
        >
          <option
            value="-1"
            disabled
          >
            (Select)
          </option>
          <option
            v-for="tz in timeZones"
            :key="tz"
            :value="tz.tzCode"
          >
            {{ tz.name }}
          </option>
        </select>
        <div class="invalid-feedback">
          {{ timeZoneValidationErrors }}
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-sm btn-primary me-2"
        :disabled="submitPending"
        @click="handleSave"
      >
        Save
      </button>
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary"
        :disabled="submitPending"
        @click="reset"
      >
        Reset
      </button>
    </form>
  </div>
</template>

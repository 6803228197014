<script>
import BannerStack from '../common/banner-stack.vue';
import ModalDialog from '../common/modal-dialog.vue';
import Axios from 'axios';
import * as EmailValidator from 'email-validator';
import ObjectUtil from '../../shared/objectutil.js';
export default {
  components: {
    BannerStack,
    ModalDialog
  },
  emits: {
    create: null
  },
  data() {
    return this.initialData();
  },
  computed: {
    emailAddressValidationErrors() {
      if (!this.$data.emailAddress) {
        return 'Required';
      }

      if (!EmailValidator.validate(this.$data.emailAddress)) {
        return 'Must be a valid email address.';
      }

      return null;
    },
    isFormValid() {
      if (!this.$data.validationEnabled) {
        return true;
      }

      return !this.emailAddressValidationErrors;
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  methods: {
    beforeWindowUnload(e) {
      if (ObjectUtil.commonPropertiesMismatch(this.$data.initialState, this.$data)) {
        e.preventDefault();
        return e.returnValue = 'There are unsaved changes. Are you sure you want to leave?';
      }
    },
    initialData() {
      let data = {
        validationEnabled: false,
        emailAddress: null,
        submitPending: false
      };

      data.initialState = {
        emailAddress: data.emailAddress
      };

      return data;
    },
    reset() {
      this.$refs.dialogBanners.clearAll();
      Object.assign(this.$data, this.initialData());
    },
    showModal() {
      this.$refs.userModal.show();
    },
    createUser() {
      this.$data.validationEnabled = true;

      if (!this.isFormValid) {
        return;
      }

      this.$data.submitPending = true;
      this.$refs.dialogBanners.clearType('danger');
      let formData = new FormData();
      formData.append('email', this.$data.emailAddress);

      Axios.post('/api/user', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          this.$emit('create');
          this.$refs.userModal.dismiss();
        })
        .catch((error) => {
          let errorMessage = "Failed to save user. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.dialogBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        })
        .then(() => {
          this.$data.submitPending = false;
        });
    },
    handleModalOpened() {
      this.$refs.emailInput.focus();
    }
  }
};
</script>

<template>
  <div class="new-user-dialog">
    <modal-dialog
      ref="userModal"
      waiting-text="Saving"
      title="Authorize User"
      ok-button-text="Authorize"
      :ok-button-disabled="validationEnabled && !isFormValid"
      :is-waiting="submitPending"
      size-large
      @closed="reset"
      @accept="createUser"
      @shown="handleModalOpened"
    >
      <banner-stack ref="dialogBanners" />
      <div class="mb-3 smalltext text-muted">
        Enter the email address for a user that you would like to authorize to access the control panel. This address
        must be associated with a valid Google account for login. Note that this email address must exactly match the
        one in the Google account. Do not add additional + or . chracters. Once the user is authorized, you can assign admin
        permissions by clicking the shield icon.
      </div>
      <form
        novalidate
        @submit.prevent.stop="createUser"
      >
        <div>
          <label
            class="form-label label-required"
            for="userEmailField"
          >
            Email Address
          </label>
          <input
            id="userEmailField"
            ref="emailInput"
            v-model.trim="emailAddress"
            class="form-control"
            :class="{ 'is-invalid': validationEnabled && !!emailAddressValidationErrors }"
            type="text"
            spellcheck="false"
            autocomplete="off"
          >
          <div class="invalid-feedback">
            {{ emailAddressValidationErrors }}
          </div>
        </div>
      </form>
    </modal-dialog>
  </div>
</template>

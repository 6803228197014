import { decode } from 'he';

export default {
  decodeHtmlEntities(html) {
    if (html == null) {
      return null;
    }

    return decode(html);
  }
};

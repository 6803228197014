<script>
import BannerStack from '../common/banner-stack.vue';
import IncentiveEntry from './incentive-entry.vue';
import Axios from 'axios';
export default {
  components: {
    IncentiveEntry,
    BannerStack
  },
  data() {
    return {
      errorLoading: false,
      currentIncentive: null,
      queuedIncentive: null
    };
  },
  created() {
    this.loadIncentives();
  },
  methods: {
    deleteQueued() {
      Axios.delete(`/api/incentives`)
        .then(() => {
          this.$refs.mainBanners.clearType('danger');
          this.$data.queuedIncentive = null;
        })
        .catch((error) => {
          let errorMessage = "Failed to delete queued incentive. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            timeout: 5000,
            message: errorMessage
          });
        });
    },
    uploadNew() {
      this.$refs.mainBanners.add({
        class: 'success',
        timeout: 5000,
        message: 'Incentive queued successfully.'
      });
      this.loadIncentives();
    },
    loadIncentives() {
      Axios.get('/api/incentives')
        .then((response) => {
          this.$data.currentIncentive = response.data.current;
          this.$data.queuedIncentive = response.data.queued;
          this.$refs.mainBanners.clearType('danger');
        })
        .catch((error) => {
          this.$data.errorLoading = true;
          let errorMessage = "Failed to load incentive data. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    }
  }
};
</script>

<template>
  <h1 class="mb-1">
    Incentive
  </h1>
  <p class="smalltext text-muted mb-4">
    Only one incentive image can be queued at a time, and they cannot be scheduled. Queuing a new
    incentive image will replace the currently queued image.
  </p>
  <banner-stack ref="mainBanners" />
  <div
    v-if="!errorLoading"
    class="row gx-3 gy-3"
  >
    <div class="col">
      <incentive-entry
        is-current
        :incentive-data="currentIncentive"
        @upload="uploadNew"
      />
    </div>
    <div class="col">
      <incentive-entry
        v-if="queuedIncentive"
        :incentive-data="queuedIncentive"
        @delete="deleteQueued"
      />
    </div>
  </div>
</template>

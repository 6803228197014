export default {
  commonPropertiesMismatch(a, b) {
    const commonKeys = [];
    for (var key in a) {
      if (key in b) {
        commonKeys.push(key);
      }
    }

    const firstMismatchedKey = commonKeys.find((compareKey) => {
      if (isValueEmpty(a[compareKey]) && isValueEmpty(b[compareKey])) {
        return false;
      }

      if (a[compareKey] != b[compareKey]) {
        // console.log(`Mismatched key was ${compareKey}`);
        // console.log(a[compareKey]);
        // console.log(b[compareKey]);
        return true;
      }
      return false;
    });

    return !!firstMismatchedKey;
  }
};

function isValueEmpty(val) {
  if (val === '' || val === null || typeof(val) === 'undefined') {
    return true;
  }

  return false;
}

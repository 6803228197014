<script>
import BannerStack from '../common/banner-stack.vue';
import SketchEntry from './sketch-entry.vue';
import UploadDialog from './upload-dialog.vue';
import Axios from 'axios';
import { nextTick } from 'vue';
export default {
  components: {
    SketchEntry,
    UploadDialog,
    BannerStack
  },
  data() {
    return {
      errorLoading: false,
      currentSketch: null,
      queuedSketches: [],
      editingSketch: null,
      loadingDialog: false
    };
  },
  computed: {
    sketchPairs() {
      let pairs = [];
      for (let i = 0; i < this.queuedSketches.length; i += 2) {
        let pair = [{ sketch: this.queuedSketches[i], index: i }];

        if (i + 1 < this.queuedSketches.length) {
          pair.push({ sketch: this.queuedSketches[i + 1], index: i + 1 });
        }

        pairs.push(pair);
      }
      return pairs;
    }
  },
  created() {
    this.loadSketches();
  },
  methods: {
    async showUploadDialog() {
      await nextTick();
      this.$refs.uploadDialog.showModal();
    },
    handleDelete(index) {
      let id = this.$data.queuedSketches[index].id;

      Axios.delete(`/api/sketches/${id}`)
        .then(() => {
          this.$refs.mainBanners.clearType('danger');
          this.$data.queuedSketches.splice(index, 1);
        })
        .catch((error) => {
          let errorMessage = "Failed to delete daily sketch. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            timeout: 5000,
            message: errorMessage
          });
        });
    },
    handleQueueButton() {
      this.$data.editingSketch = null;
      this.showUploadDialog();
    },
    handleEditButton(index) {
      this.$data.loadingDialog = true;
      this.reloadSketch(index);
    },
    handleEditReloadCallback(index, success) {
      this.$data.loadingDialog = false;

      if (!success) {
        return;
      }

      this.$data.editingSketch = this.$data.queuedSketches[index];
      this.showUploadDialog();
    },
    handleDialogComplete() {
      this.$refs.mainBanners.add({
        class: 'success',
        timeout: 5000,
        message: `Daily sketch ${this.$data.editingSketch ? 'updated' : 'created'} successfully.`
      });

      this.loadSketches();
    },
    loadSketches() {
      Axios.get('/api/sketches')
        .then((response) => {
          this.$data.currentSketch = response.data.current;
          this.$data.queuedSketches = response.data.queued;
          this.$refs.mainBanners.clearType('danger');
        })
        .catch((error) => {
          this.$data.errorLoading = true;
          let errorMessage = "Failed to load daily sketch data. " +
            "Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    },
    reloadSketch(index) {
      let sketchToReload = this.$data.queuedSketches[index];
      Axios.get('/api/sketches/' + sketchToReload.id)
        .then((response) => {
          this.$data.queuedSketches[index] = response.data;
          this.$refs.mainBanners.clearType('danger');
          this.handleEditReloadCallback(index, true);
        })
        .catch((error) => {
          this.$data.errorLoading = true;
          let errorMessage = "Failed to load daily sketch data. " +
            "Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });

          this.handleEditReloadCallback(index, false);
        });
    }
  }
};
</script>

<template>
  <div class="daily-sketch-wrapper">
    <h1 class="mb-1">
      Daily Sketch Links
    </h1>
    <p class="smalltext text-muted mb-4">
      You can schedule as many daily sketch links as you want here. When a new daily sketch link is
      published, the previously live one is deleted.
    </p>
    <banner-stack ref="mainBanners" />
    <div v-if="!errorLoading">
      <upload-dialog
        ref="uploadDialog"
        :key="editingSketch"
        :initial-sketch-data="editingSketch"
        @upload="handleDialogComplete"
      />

      <sketch-entry
        class="mb-3"
        is-current
        :sketch-data="currentSketch"
        :buttons-disabled="loadingDialog"
        @create="handleQueueButton"
      />

      <div
        v-for="sketchPair in sketchPairs"
        :key="sketchPair"
        class="row gx-3 mb-3"
      >
        <div class="col">
          <sketch-entry
            :sketch-data="sketchPair[0].sketch"
            :buttons-disabled="loadingDialog"
            @delete="handleDelete(sketchPair[0].index)"
            @edit="handleEditButton(sketchPair[0].index)"
          />
        </div>
        <div class="col">
          <sketch-entry
            v-if="sketchPair.length > 1"
            :sketch-data="sketchPair[1].sketch"
            :buttons-disabled="loadingDialog"
            @delete="handleDelete(sketchPair[1].index)"
            @edit="handleEditButton(sketchPair[1].index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerAlert from '../common/banner-alert.vue';
import BannerStack from '../common/banner-stack.vue';
import ChapterList from './chapter-list.vue';
import Editor from './chapter-editor.vue';
import Axios from 'axios';
import { decode } from 'html-entities';
export default {
  components: {
    ChapterList,
    Editor,
    BannerStack,
    BannerAlert
  },
  props: {
    hasPendingChanges: Boolean
  },
  data() {
    return {
      changesPendingFlag: this.hasPendingChanges,
      selectedChapter: null,
      selectedChapterIndex: null,
      chapterListRerenderKey: 1,
      chapters: [],
      errorLoading: false,
      waitingForWrite: false,
      loadingEditor: false
    };
  },
  computed: {
    unsavedChapter() {
      return this.$data.selectedChapter && this.$data.selectedChapter.id == null;
    }
  },
  created() {
    this.loadChapters();
  },
  methods: {
    loadChapters() {
      Axios.get('/api/chapters')
        .then((response) => {
          this.$data.chapters = response.data.map(chap => {
            chap.description = decode(chap.description);
            return chap;
          });

          this.$refs.mainBanners.clearType('danger');
        })
        .catch((error) => {
          console.error(error);

          this.$data.errorLoading = true;
          let errorMessage = "Failed to load chapters. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    },
    reloadChapter(index) {
      let chapterToReload = this.$data.chapters[index];
      Axios.get('/api/chapters/' + chapterToReload.id)
        .then((response) => {
          let reloadedChapter = response.data;
          reloadedChapter.description = decode(reloadedChapter.description);
          this.$data.chapters[index] = reloadedChapter;

          this.$refs.mainBanners.clearType('danger');
          this.handleEditReloadCallback(index, true);
        })
        .catch((error) => {
          console.error(error);

          this.$data.errorLoading = true;
          let errorMessage = "Failed to load chapter data. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });

          this.handleEditReloadCallback(index, false);
        });
    },
    handleEditChapter(index) {
      if (this.unsavedChapter) {
        return;
      }

      this.$data.loadingEditor = true;
      this.reloadChapter(index);
    },
    handleEditReloadCallback(index, success) {
      this.$data.loadingEditor = false;

      if (!success) {
        return;
      }

      this.$data.selectedChapter = this.$data.chapters[index];
      this.$data.selectedChapterIndex = index;
    },
    cancelEditor() {
      let index = this.$data.selectedChapterIndex;

      if (this.unsavedChapter) {
        this.$data.chapters.splice(index, 1);
      }

      this.$data.selectedChapterIndex = null;
      this.$data.selectedChapter = null;
    },
    handleEditorSave(newData) {
      let index = this.$data.selectedChapterIndex;
      this.$data.waitingForWrite = true;

      if (this.unsavedChapter) {
        // Set the order.
        if (index > 0) {
          newData.after = this.$data.chapters[index - 1].id;
        }
        else {
          newData.after = -1;
        }

        Axios
          .post(
            '/api/chapters',
            newData
          )
          .then((response) => {
            newData.id = response.data.id;
            this.handleSaveSuccess(index, newData);
          })
          .catch((error) => {
            this.handleSaveFailure(error);
          })
          .then(() => {
            this.$data.waitingForWrite = false;
          });
      }
      else {
        let chapterId = this.$data.selectedChapter.id;
        Axios
          .post(
            `/api/chapters/${chapterId}`,
            newData
          )
          .then(() => {
            this.handleSaveSuccess(index, newData);
          })
          .catch((error) => {
            this.handleSaveFailure(error);
          })
          .then(() => {
            this.$data.waitingForWrite = false;
          });
      }
    },
    handleSaveSuccess(chapterIndex, newData) {
      this.$refs.editorBanners.clearType('danger');
      Object.assign(this.$data.chapters[chapterIndex], newData);

      this.$refs.editorBanners.add({
        class: 'success',
        timeout: 5000,
        message: `Successfully saved "${this.$data.selectedChapter.title}". Changes will be applied on the next publish.`
      });

      this.$data.selectedChapterIndex = null;
      this.$data.selectedChapter = null;
      this.$refs.editor.reset();
      this.$data.changesPendingFlag = true;
    },
    handleSaveFailure(error) {
      let errorMessage = "Failed to save chapter. Please try reloading the page or try again later.";
      if (error.response && error.response.data.message) {
        errorMessage += ` Message: ${error.response.data.message}`;
      }

      this.$refs.editorBanners.add({
        class: 'danger',
        dismissible: false,
        message: errorMessage
      });
    },
    handleChapterListCreate() {
      if (this.unsavedChapter) {
        return;
      }

      this.$data.chapters.push({});

      let index = this.$data.chapters.length - 1;
      this.$data.selectedChapterIndex = index;
      this.$data.selectedChapter = this.$data.chapters[index];
    },
    handleDeleteChapter(index) {
      let chapterId = this.$data.chapters[index].id;

      if (index == this.$data.selectedChapterIndex) {
        this.cancelEditor();
        this.$data.selectedChapterIndex = null;
        this.$data.selectedChapter = null;
      }

      this.$data.waitingForWrite = true;
      Axios.delete(`/api/chapters/${chapterId}`)
        .then(() => {
          this.$refs.mainBanners.clearType('danger');
          this.$data.chapters.splice(index, 1);
          this.relocateSelectedChapter();
          this.$data.changesPendingFlag = true;
        })
        .catch((error) => {
          let errorMessage = "Failed to delete chapter. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            timeout: 5000,
            message: errorMessage
          });
        })
        .then(() => {
          this.$data.waitingForWrite = false;
        });
    },
    handleReorderChapter(oldIndex, newIndex) {
      var chapterToMove = this.$data.chapters[oldIndex];
      this.$data.chapters.splice(oldIndex, 1);
      this.$data.chapters.splice(newIndex, 0, chapterToMove);
      this.relocateSelectedChapter();

      // Force the chapter list to rerender.
      this.$data.chapterListRerenderKey++;

      if (this.unsavedChapter) {
        return;
      }

      let afterChapterId = -1;
      if (newIndex > 0) {
        afterChapterId = this.$data.chapters[newIndex - 1].id;
      }

      this.$data.waitingForWrite = true;
      Axios.put(`/api/chapters/${chapterToMove.id}/reorderafter/${afterChapterId}`)
        .then(() => {
          this.$refs.mainBanners.clearType('danger');
          this.$data.changesPendingFlag = true;
        })
        .catch((error) => {
          this.$data.chapters.splice(newIndex, 1);
          this.$data.chapters.splice(oldIndex, 0, chapterToMove);
          this.$data.chapterListRerenderKey++;

          let errorMessage = "Failed to move chapter. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            timeout: 5000,
            message: errorMessage
          });
        })
        .then(() => {
          this.$data.waitingForWrite = false;
        });
    },
    relocateSelectedChapter() {
      if (this.$data.selectedChapter) {
        this.$data.selectedChapterIndex =
          this.$data.chapters.findIndex((el) => el === this.$data.selectedChapter);
      }
    }
  }
};
</script>

<template>
  <div class="chapter-content">
    <h1>Chapter Management</h1>
    <banner-alert
      v-if="changesPendingFlag"
      alert-class="info"
    >
      Some changes to chapters have not yet been published.
      <a
        href="/publishing"
        class="alert-link"
      >Go to Publishing</a>
      to deploy them.
    </banner-alert>
    <banner-stack ref="mainBanners" />

    <div
      v-if="!errorLoading"
      class="row gx-5 flex-nowrap"
    >
      <div class="col-3 py-2">
        <chapter-list
          :key="chapterListRerenderKey"
          :chapters="chapters"
          :selected-chapter="selectedChapter"
          :prevent-edit="waitingForWrite || loadingEditor"
          @edit="handleEditChapter"
          @create="handleChapterListCreate"
          @reorder="handleReorderChapter"
          @delete="handleDeleteChapter"
        />
      </div>
      <div class="col chapter-editor-area py-2">
        <banner-stack ref="editorBanners" />
        <editor
          v-if="selectedChapter !== null"
          ref="editor"
          :title="selectedChapter.title"
          :description="selectedChapter.description"
          :prevent-close="waitingForWrite"
          @canceled="cancelEditor"
          @updated="handleEditorSave"
        />
        <div
          v-else
          class="center italic text-muted mt-5"
        >
          Select a chapter to modify it, or add a new one.
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.chapter-content {
  .chapter-editor-area {
    min-height: 600px;
    border-left: 1px solid $gray-400;
  }
}
</style>

<script>
import BannerStack from '../common/banner-stack.vue';
import ImageUpload from '../common/image-upload.vue';
import ModalDialog from '../common/modal-dialog.vue';
import Axios from 'axios';
import ObjectUtil from '../../shared/objectutil.js';
export default {
  components: {
    ModalDialog,
    ImageUpload,
    BannerStack
  },
  emits: {
    upload: null
  },
  data() {
    return this.initialData();
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  methods: {
    beforeWindowUnload(e) {
      if (ObjectUtil.commonPropertiesMismatch(this.$data.initialState, this.$data)) {
        e.preventDefault();
        return e.returnValue = 'There are unsaved changes. Are you sure you want to leave?';
      }
    },
    initialData() {
      let data = {
        requiredUploadHeightPx: 72,
        requiredUploadWidthPx: 160,
        uploadValidationError: null,
        incentiveFile: null,
        submitPending: false
      };

      data.initialState = {
        incentiveFile: data.incentiveFile
      };

      return data;
    },
    reset() {
      this.$refs.dialogBanners.clearAll();
      this.$refs.uploadArea.reset();
      Object.assign(this.$data, this.initialData());
    },
    showModal() {
      this.$refs.queueModal.show();
    },
    handleFileSelected(file) {
      this.$data.incentiveFile = file;
    },
    handlePreviewImageLoad(imageHeight, imageWidth) {
      let requiredHeight = this.$data.requiredUploadHeightPx;
      let requiredWidth = this.$data.requiredUploadWidthPx;
      if (imageHeight != requiredHeight || imageWidth != requiredWidth) {
        this.$refs.uploadArea.reset();
        this.$data.incentiveFile = null;
        this.$data.uploadValidationError =
          `Image dimensions must be ${requiredHeight}px tall by ${requiredWidth}px wide.`;
        return;
      }

      this.$data.uploadValidationError = null;
    },
    submitUpload() {
      this.$data.submitPending = true;
      this.$refs.dialogBanners.clearType('danger');
      let formData = new FormData();
      formData.append('image', this.$data.incentiveFile);

      Axios.post('/api/incentives', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          this.$emit('upload');
          this.$refs.queueModal.dismiss();
        })
        .catch((error) => {
          this.$data.errorLoading = true;
          let errorMessage = "Failed to save incentive. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.dialogBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        })
        .then(() => {
          this.$data.submitPending = false;
        });
    }
  }
};
</script>

<template>
  <div class="incentive-upload-wrapper">
    <modal-dialog
      ref="queueModal"
      title="Upload Incentive"
      ok-button-text="Queue for Publishing"
      waiting-text="Saving"
      enable-clickaway
      :ok-button-disabled="!incentiveFile"
      :is-waiting="submitPending"
      @closed="reset"
      @accept="submitUpload"
    >
      <banner-stack ref="dialogBanners" />
      <p class="mb-3 smalltext text-muted">
        The incentive image you upload will be queued for the next site publish. If an image is already
        queued, it will be replaced.
      </p>
      <div style="text-align: center;">
        <image-upload
          ref="uploadArea"
          class="mb-3 ms-auto"
          :validation-enabled="true"
          :validation-errors="uploadValidationError"
          :preview-width="requiredUploadWidthPx"
          :preview-height="requiredUploadHeightPx"
          @select="handleFileSelected"
          @image-loaded="handlePreviewImageLoad"
        />
        <p class="smalltext">
          Uploaded image must be {{ requiredUploadHeightPx }}x{{ requiredUploadWidthPx }} pixels.
        </p>
      </div>
    </modal-dialog>
  </div>
</template>

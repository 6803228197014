<script>
import HtmlUtil from '../../shared/htmlutil.js';
import ObjectUtil from '../../shared/objectutil.js';
import { QuillEditor } from '@vueup/vue-quill';
export default {
  components: {
    QuillEditor
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    preventClose: Boolean
  },
  emits: {
    updated: null,
    canceled: null
  },
  data() {
    return this.initialData();
  },
  computed: {
    titleValidationErrors() {
      if (!this.$data.dataTitle) {
        return 'Required';
      }

      return null;
    },
    isFormValid() {
      if (!this.$data.validationEnabled) {
        return true;
      }

      return !this.titleValidationErrors;
    }
  },
  watch: {
    title(newVal) {
      this.$data.dataTitle = newVal || '';
    },
    description(newVal) {
      this.$data.dataDescription = newVal || '';
      this.$refs.editor.setHTML(newVal || '');
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  mounted() {
    if (!this.title) {
      this.$refs.titleField.focus();
    }
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  methods: {
    beforeWindowUnload(e) {
      if (ObjectUtil.commonPropertiesMismatch(this.$data.initialState, this.$data)) {
        e.preventDefault();
        return e.returnValue = 'There are unsaved changes. Are you sure you want to leave?';
      }
    },
    initialData() {
      let data = {
        validationEnabled: false,
        dataDescription: this.description || '',
        dataTitle: this.title || '',
        quillOptions: {
          theme: 'snow',
          formats: ['bold', 'italic', 'underline', 'link', 'strike'],
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike', 'link'],
              ['clean']
            ]
          }
        }
      };

      data.initialState = {
        dataDescription: data.dataDescription,
        dataTitle: data.dataTitle,
      };

      return data;
    },
    reset() {
      Object.assign(this.$data, this.initialData());
    },
    handleSave() {
      this.$data.validationEnabled = true;

      if (!this.isFormValid || this.preventClose) {
        return;
      }

      this.$emit(
        'updated',
        { title: HtmlUtil.decodeHtmlEntities(this.$data.dataTitle), description: this.$data.dataDescription }
      );
    },
    handleCancel() {
      if (this.preventClose) {
        return;
      }

      this.$emit('canceled');
      this.reset();
    },
    decodeEntities(value) {
      return HtmlUtil.decodeHtmlEntities(value);
    }
  }
};
</script>

<template>
  <div class="chapter-editor">
    <form
      novalidate
      @submit.prevent.stop="handleSave"
    >
      <div class="mb-3">
        <label
          class="form-label"
          for="chapterTitle"
        >
          Title
        </label>
        <input
          id="chapterTitle"
          ref="titleField"
          :value="decodeEntities(dataTitle)"
          class="form-control"
          type="text"
          :class="{ 'is-invalid': validationEnabled && !!titleValidationErrors }"
          @input="dataTitle = $event.target.value"
        >
        <div class="invalid-feedback">
          {{ titleValidationErrors }}
        </div>
      </div>
      <div class="mb-3">
        <label
          class="form-label"
          for="chapterDescription"
        >
          Description
        </label>
        <QuillEditor
          ref="editor"
          v-model:content="dataDescription"
          content-type="html"
          :options="quillOptions"
        />
      </div>
      <div class="row">
        <div class="col" />
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-outline-secondary me-2"
            :disabled="preventClose"
            @click="handleCancel"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="preventClose || (validationEnabled && !isFormValid)"
            @click="handleSave"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
@import '@vueup/vue-quill/dist/vue-quill.snow.css';
.chapter-editor {
  .ql-editor {
    height: 13em;

    p {
      font-size: 1.2em;
      margin-bottom: 0.35em;
    }
  }
}
</style>

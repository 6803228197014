<script>
import BannerStack from '../common/banner-stack.vue';
import ImageUpload from '../common/image-upload.vue';
import ModalDialog from '../common/modal-dialog.vue';
import DateUtil from '../../shared/dateutil.js';
import HtmlUtil from '../../shared/htmlutil.js';
import ObjectUtil from '../../shared/objectutil.js';
import Axios from 'axios';
import { DatePicker } from 'v-calendar';
export default {
  components: {
    ModalDialog,
    DatePicker,
    ImageUpload,
    BannerStack
  },
  props: {
    initialSketchData: {
      type: Object,
      default: () => {}
    }
  },
  emits: {
    upload: null
  },
  data() {
    var data = {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content')
    };
    Object.assign(data, this.initialData());
    return data;
  },
  computed: {
    minimumScheduleDate() {
      if (this.isEditing && Date.parse(this.initialSketchData?.publishScheduledAt) < new Date()) {
        return Date.parse(this.initialSketchData?.publishScheduledAt);
      }

      return new Date();
    },
    isScheduled() {
      return this.$data.radioSelection === 'schedule';
    },
    scheduleTzString() {
      if (!this.isScheduled) {
        return null;
      }

      return DateUtil.getTimeZoneShortName(this.$data.scheduleDate, this.$data.timeZonePreference);
    },
    isEditing() {
      return !!this.initialSketchData;
    },
    modalHeaderText() {
      if (this.isEditing) {
        return "Edit Daily Sketch";
      }
      return "Create Daily Sketch";
    },
    modalAcceptText() {
      if (this.isEditing) {
        return "Save";
      }
      return "Queue for Publishing";
    },
    radioValidationErrors() {
      if (
        this.$data.radioSelection != 'publish' &&
        this.$data.radioSelection != 'schedule'
      ) {
        return 'Required';
      }
      return null;
    },
    uploadValidationErrors() {
      if (this.isEditing && !this.$data.sketchFile) {
        return null;
      }

      if (!this.$data.sketchFile) {
        return 'Required';
      }

      if (this.$data.uploadError) {
        return this.$data.uploadError;
      }

      return null;
    },
    dateValidationErrors() {
      if (!this.isScheduled) {
        return null;
      }

      if (!this.$data.scheduleDate) {
        return 'Required';
      }
      else if (this.$data.scheduleDate <= new Date()) {
        return 'Scheduled time must be in the future';
      }

      return null;
    },
    linkValidationErrors() {
      let linkRegex = /^https?:\/\/.+$/i;
      if (!this.$data.link) {
        return 'Required';
      }
      else if (!linkRegex.test(this.$data.link)) {
        return 'Must be a valid URL';
      }

      return null;
    },
    isFormValid() {
      // Don't make the form ugly until a submission attempt has been made.
      if (!this.$data.validationEnabled) {
        return true;
      }

      return !this.radioValidationErrors &&
        !this.uploadValidationErrors &&
        !this.dateValidationErrors &&
        !this.linkValidationErrors;
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  methods: {
    beforeWindowUnload(e) {
      if (ObjectUtil.commonPropertiesMismatch(this.$data.initialState, this.$data)) {
        e.preventDefault();
        return e.returnValue = 'There are unsaved changes. Are you sure you want to leave?';
      }
    },
    initialData() {
      var tomorrowNoon = new Date();
      tomorrowNoon.setDate(tomorrowNoon.getDate() + 1);
      tomorrowNoon.setHours(12, 0, 0, 0);

      let initialScheduleDate = tomorrowNoon;
      let initialRadioSelection = 'publish';
      if (this.initialSketchData?.publishScheduledAt) {
        initialScheduleDate = new Date(this.initialSketchData.publishScheduledAt);
        initialRadioSelection = 'schedule';
      }

      let data = {
        requiredUploadHeightPx: 208,
        requiredUploadWidthPx: 160,
        uploadError: null,
        sketchFile: null,
        scheduleDate: initialScheduleDate,
        radioSelection: initialRadioSelection,
        link: this.initialSketchData?.link,
        validationEnabled: false,
        submitPending: false
      };

      // Used to display the confirmation dialog when leaving the page.
      data.initialState = {
        sketchFile: data.sketchFile,
        scheduleDate: data.scheduleDate,
        radioSelection: data.radioSelection,
        link: data.link
      };

      return data;
    },
    reset() {
      this.$refs.dialogBanners.clearAll();
      this.$refs.uploadArea.reset();
      Object.assign(this.$data, this.initialData());
    },
    showModal() {
      this.$refs.queueModal.show();
    },
    handleFileSelected(file) {
      this.$data.sketchFile = file;
    },
    handlePreviewImageLoad(imageHeight, imageWidth) {
      let requiredHeight = this.$data.requiredUploadHeightPx;
      let requiredWidth = this.$data.requiredUploadWidthPx;
      if (imageHeight != requiredHeight || imageWidth != requiredWidth) {
        this.$refs.uploadArea.reset();
        this.$data.sketchFile = null;
        this.$data.validationEnabled = true;
        this.$data.uploadError = `Image dimensions must be ${requiredHeight}px tall by ${requiredWidth}px wide.`;
        return;
      }

      this.$data.uploadError = null;
    },
    submitUpload() {
      this.$data.validationEnabled = true;

      if (!this.isFormValid) {
        return;
      }

      this.$data.submitPending = true;
      this.$refs.dialogBanners.clearType('danger');
      let formData = new FormData();

      if (!this.isEditing || !!this.$data.sketchFile) {
        formData.append('image', this.$data.sketchFile);
      }

      formData.append('link', HtmlUtil.decodeHtmlEntities(this.$data.link));

      if (this.isScheduled) {
        formData.append('scheduleDate', this.$data.scheduleDate.toISOString());
      }

      let route = this.isEditing
        ? `/api/sketches/${this.initialSketchData.id}`
        : '/api/sketches';

      Axios.post(route, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          this.$emit('upload');
          this.$refs.queueModal.dismiss();
        })
        .catch((error) => {
          let errorMessage = "Failed to save daily sketch. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.dialogBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        })
        .then(() => {
          this.$data.submitPending = false;
        });
    },
    decodeEntities(value) {
      return HtmlUtil.decodeHtmlEntities(value);
    }
  }
};
</script>

<template>
  <div class="sketch-upload-wrapper">
    <modal-dialog
      ref="queueModal"
      waiting-text="Saving"
      size-large
      :title="modalHeaderText"
      :ok-button-text="modalAcceptText"
      :ok-button-disabled="validationEnabled && !isFormValid"
      :is-waiting="submitPending"
      @closed="reset"
      @accept="submitUpload"
    >
      <banner-stack ref="dialogBanners" />
      <div class="row gx-3">
        <div class="col-sm-auto">
          <div
            class="mx-auto"
            :style="`width: calc(${requiredUploadWidthPx}px + 15px);`"
          >
            <div style="text-align: center;">
              <image-upload
                ref="uploadArea"
                class="mb-3 ms-auto"
                :validation-enabled="validationEnabled"
                :validation-errors="uploadError || uploadValidationErrors"
                :preview-width="requiredUploadWidthPx"
                :preview-height="requiredUploadHeightPx"
                :initial-image="initialSketchData?.imageUrl"
                @select="handleFileSelected"
                @image-loaded="handlePreviewImageLoad"
              />
              <p class="smalltext mb-0">
                Uploaded image must be {{ requiredUploadHeightPx }}x{{ requiredUploadWidthPx }} pixels.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md">
          <form
            ref="uploadForm"
            novalidate
            @keyup.enter.prevent.stop="submitUpload"
            @submit.prevent
          >
            <div class="form-text label-required">
              <b>Available</b>
            </div>
            <div class="form-check form-check-inline mb-2">
              <input
                id="radioOnPublish"
                v-model="radioSelection"
                :class="{ 'is-invalid': validationEnabled && !!radioValidationErrors }"
                class="form-check-input"
                name="scheduleRadio"
                type="radio"
                value="publish"
              >
              <label
                class="form-check-label"
                for="`radioOnPublish"
              >
                On publish
              </label>
            </div>
            <div class="form-check form-check-inline mb-2">
              <input
                id="radioSchedule"
                v-model="radioSelection"
                :class="{ 'is-invalid': validationEnabled && !!radioValidationErrors }"
                class="form-check-input"
                name="scheduleRadio"
                type="radio"
                value="schedule"
              >
              <label
                class="form-check-label"
                for="radioSchedule"
              >Schedule&hellip;</label>
            </div>
            <div class="invalid-feedback">
              {{ radioValidationErrors }}
            </div>
            <div
              v-if="!isScheduled"
              class="text-muted smalltext mb-2 italic"
            >
              Note: If another Daily Sketch is queued for the next publish, it will be replaced by this one.
            </div>

            <date-picker
              v-if="isScheduled"
              v-model="scheduleDate"
              mode="dateTime"
              is-required
              :masks="{ input: 'MM/DD/YYYY hh:mm A' }"
              :min-date="minimumScheduleDate"
              :timezone="timeZonePreference"
            >
              <template #default="{ inputValue, showPopover }">
                <div class="input-group has-validation">
                  <span class="input-group-text">
                    <span
                      class="fas fa-calendar-alt center"
                      style="width: 1rem;"
                    />
                  </span>
                  <input
                    ref="publishDate"
                    type="text"
                    class="form-control"
                    :disabled="!isScheduled"
                    :value="inputValue"
                    :class="{ 'is-invalid': validationEnabled && !!dateValidationErrors }"
                    @click="showPopover()"
                    @focus="showPopover()"
                  >
                  <div class="invalid-feedback">
                    {{ dateValidationErrors }}
                  </div>
                </div>
                <div class="smalltext text-muted italic mb-2">
                  Time zone is {{ scheduleTzString }}.
                </div>
              </template>
            </date-picker>
            <div>
              <label
                class="form-label label-required"
                for="sketchLink"
              >Link</label>
              <input
                id="sketchLink"
                ref="sketchLink"
                :value="decodeEntities(link)"
                type="text"
                class="form-control"
                placeholder="https://www.patreon.com/..."
                :class="{ 'is-invalid': validationEnabled && !!linkValidationErrors }"
                @input="link = $event.target.value"
              >
              <div class="invalid-feedback">
                {{ linkValidationErrors }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
export default {
  props: {
    validationEnabled: Boolean,
    validationErrors: {
      type: String,
      default: null
    },
    initialImage: {
      type: String,
      default: null
    },
    fileTypeAccept: {
      type: String,
      default: "image/*"
    },
    previewWidth: {
      type: Number,
      default: 0
    },
    previewHeight: {
      type: Number,
      default: 300
    }
  },
  emits: {
    error: null,
    select: null,
    imageLoaded: null,
    reset: null
  },
  data() {
    return this.initialData();
  },
  computed: {
    cssProps() {
      var props = {
        '--preview-width': 'auto',
        '--preview-height': `${this.previewHeight}px`
      };

      if (this.previewWidth != 0) {
        props['--preview-width'] = `${this.previewWidth}px`;
      }

      return props;
    }
  },
  methods: {
    initialData() {
      return {
        stagedFilePath: null,
        uploadFiles: null,
        uploadDragging: 0
      };
    },
    reset() {
      if (this.$data.stagedFilePath) {
        this.$emit('reset');
      }

      Object.assign(this.$data, this.initialData());
    },
    handleUploadAreaClick() {
      if (!this.cropping) {
        this.triggerUploadDialog();
      }
    },
    triggerUploadDialog() {
      this.$refs.uploadField.click();
    },
    handleFileDragEnter() {
      this.$data.uploadDragging++;
    },
    handleFileDragLeave() {
      this.$data.uploadDragging--;
    },
    handleFileDrop(ev) {
      this.$data.uploadDragging = 0;

      if (!ev.dataTransfer || ev.dataTransfer.files.length === 0) {
        return;
      }

      if (!ev.dataTransfer.files[0].type.startsWith("image/")) {
        return;
      }

      this.handleFiles(ev.dataTransfer.files);
    },
    handleFileSelection(ev) {
      if (!ev.target.files[0].type.startsWith("image/")) {
        this.$data.uploadFiles = null;
        return;
      }

      this.handleFiles(ev.target.files);
    },
    handleFiles(files) {
      if (files.length === 0) {
        this.$data.stagedUpload = null;
        return;
      }

      this.$data.uploadFiles = files;
      this.$data.uploadError = null;
      this.$data.stagedUpload = null;
      this.$data.stagedFilePath = URL.createObjectURL(files[0]);
      this.$emit('select', files[0]);
    },
    handleImageLoaded(ev) {
      let imageHeight = ev.target.naturalHeight;
      let imageWidth = ev.target.naturalWidth;
      this.$emit('imageLoaded', imageHeight, imageWidth);
      URL.revokeObjectURL(this.$data.stagedFilePath);
    }
  }
};
</script>

<template>
  <div class="image-upload-wrapper">
    <input
      ref="uploadField"
      :class="{ 'is-invalid': validationEnabled && !!validationErrors }"
      type="file"
      hidden
      :files="uploadFiles"
      :accept="fileTypeAccept"
      @change="handleFileSelection"
    >
    <div
      class="me-auto ms-auto upload-area d-flex align-items-center justify-content-center"
      :class="{ 'has-image': stagedFilePath }"
      :style="cssProps"
      @click="handleUploadAreaClick"
      @drop.stop.prevent="handleFileDrop"
      @dragenter.stop.prevent="handleFileDragEnter"
      @dragover.stop.prevent
      @dragleave.stop.prevent="handleFileDragLeave"
    >
      <div
        v-if="uploadDragging > 0"
        class="upload-hover"
      />
      <div class="upload-img-preview">
        <img
          v-if="!!stagedFilePath || !!initialImage"
          ref="previewImg"
          :src="stagedFilePath || initialImage"
          @load="handleImageLoaded"
        >
        <div v-else>
          Drag an image here or click this area to get started.
        </div>
      </div>
    </div>
    <div class="invalid-feedback">
      {{ validationErrors }}
    </div>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.image-upload-wrapper {
  .upload-area {
    border: 1px solid #aaa;
    background-color: #ccc;
    height: var(--preview-height);
    width: var(--preview-width);
    position: relative;
    cursor: pointer;

    .upload-img-preview {
      text-align: center;
      font-size: 0.8rem;
    }

    .upload-hover {
      position: absolute;
      top: 0.3rem;
      bottom: 0.3rem;
      left: 0.3rem;
      right: 0.3rem;
      background-color: rgba(var(--bs-primary-rgb), 0.5);
      background-image: url(../img/upload-white.svg);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 10%;
      border: 3px dashed $primary;
      border-radius: 5px;
      z-index: 15001;
    }

    &.has-image {
      background-color: $black;

      .upload-img-preview {
        height: 100%;

        div {
          height: 100%;
          width: 100%;
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>

<script>
import BannerStack from '../common/banner-stack.vue';
import DateUtil from '../../shared/dateutil.js';
import PublishChangeContent from '../publishing/publish-change-content.vue';
import CurrentEntry from './current-entry.vue';
import Axios from 'axios';
export default {
  components: {
    CurrentEntry,
    BannerStack,
    PublishChangeContent
  },
  data() {
    return {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content'),
      errorLoadingLatest: false,
      errorLoadingPublish: false,
      latestItems: {
        page: null,
        sketch: null,
        incentive: null
      },
      publishItems: {
        unscheduled: [],
        scheduled: []
      }
    };
  },
  computed: {
    nextPublishDate() {
      if (this.$data.publishItems.scheduled.length) {
        return this.$data.publishItems.scheduled[0].publishDateTime;
      }

      return null;
    },
    formattedPublishDate() {
      let date = DateUtil.getLongUsDateString(this.nextPublishDate, this.$data.timeZonePreference);
      let time = DateUtil.getUsTimeString(this.nextPublishDate, this.$data.timeZonePreference);
      return `${date} at ${time}`;
    },
    numberOfUnscheduled() {
      return this.$data.publishItems.unscheduled.length;
    },
    numberOfScheduled() {
      let scheduled = this.$data.publishItems.scheduled;

      if (!scheduled.length) {
        return 0;
      }

      return scheduled.filter(x => x.publishDateTime == this.nextPublishDate).length;
    },
    hasLaterPublishes() {
      return !!this.$data.publishItems.scheduled.filter(x => x.publishDateTime != this.nextPublishDate).length;
    }
  },
  created() {
    this.loadLatestItems();
    this.loadPublishItems();
  },
  methods: {
    loadLatestItems() {
      Axios.get('/api/publish/latest')
        .then((response) => {
          this.$data.latestItems = response.data;
          this.$refs.latestBanners.clearType('danger');
        })
        .catch((error) => {
          this.$data.errorLoading = true;
          let errorMessage = "Failed to load currently live items. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.latestBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    },
    loadPublishItems() {
      Axios.get('/api/publish')
        .then((response) => {
          this.$data.publishItems = response.data;
          this.$refs.publishBanners.clearType('danger');
        })
        .catch((error) => {
          this.$data.errorLoading = true;
          let errorMessage = "Failed to load publishing data. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.publishBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    },
    pluralizeItems(count) {
      return count == 1 ? 'item' : 'items';
    }
  }
};
</script>

<template>
  <div class="dashboard-content">
    <h1>Dashboard</h1>

    <h2 class="mb-2">
      Live Now
    </h2>
    <banner-stack ref="latestBanners" />
    <div
      v-if="!errorLoadingLatest"
      class="row gx-0 current-entry-list"
    >
      <current-entry
        :is-unavailable="!latestItems.page"
        :preview-img="latestItems.page?.imageDashThumbUrl"
        :preview-fallback="(latestItems.page?.title || 'Untitled page') + ' (no image)'"
        preview-alt="Latest Comic Image"
        heading="Latest Page"
        button-link="/pages"
      />

      <current-entry
        :is-unavailable="!latestItems.sketch"
        :preview-img="latestItems.sketch?.imageUrl"
        preview-alt="Latest Daily Sketch Image"
        heading="Daily Sketch"
        button-link="/dailysketch"
      />

      <current-entry
        :is-unavailable="!latestItems.incentive"
        :preview-img="latestItems.incentive?.imageUrl"
        preview-alt="Latest Incentive Image"
        heading="Incentive"
        button-link="/incentive"
      />
    </div>

    <banner-stack ref="publishBanners" />
    <publish-change-content
      :unscheduled-change-data="publishItems.unscheduled"
      :scheduled-change-data="publishItems.scheduled"
    />
  </div>
</template>

<style lang="scss">
.dashboard-content {
  .current-entry-list {
    margin: 0 0.5rem;
  }
}
</style>

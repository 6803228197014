<script>
import ModalDialog from '../common/modal-dialog.vue';
import DateUtil from '../../shared/dateutil.js';
import HtmlUtil from '../../shared/htmlutil.js';
export default {
  components: {
    ModalDialog
  },
  props: {
    pageData: {
      type: Object,
      default: () => {}
    },
    pages: {
      type: Array,
      default: () => []
    },
    chapters: {
      type: Array,
      default: () => []
    },
    buttonsDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    edit: null,
    delete: null,
    hide: null,
    show: null
  },
  data() {
    return {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content')
    };
  },
  computed: {
    heading() {
      if (this.pageData.pageNumber) {
        return `${this.pageData.pageNumber}: ${this.title}`;
      }
      return this.title;
    },
    title() {
      return HtmlUtil.decodeHtmlEntities(this.pageData.title) || 'Untitled';
    },
    unpublished() {
      return !this.pageData.publishedAt;
    },
    publishedWithChangesPending() {
      return !!this.pageData.changesPending;
    },
    subheading() {
      if (this.pageData.hidden) {
        return {
          text: 'Hidden',
          icon: ['fa-minus-circle'],
          color: 'text-danger'
        };
      }
      else if (this.publishedWithChangesPending) {
        return {
          text: 'Published, changes pending',
          icon: ['fa-ellipsis-h'],
          color: 'text-modified'
        };
      }
      else if (!this.unpublished) {
        return {
          text: 'Published',
          icon: ['fa-check-circle'],
          color: 'text-success'
        };
      }
      else if (this.pageData.publishScheduledAt) {
        return {
          text: `Publishes ${this.dateString} ${this.timeString}`,
          icon: ['fas-alternate', 'fa-clock']
        };
      }
      else {
        return {
          text: 'Queued for Next Publish',
          icon: ['fa-ellipsis-h']
        };
      }
    },
    dateString() {
      return DateUtil.getUsDateString(new Date(this.pageData.publishScheduledAt), this.$data.timeZonePreference);
    },
    timeString() {
      return DateUtil.getUsTimeString(new Date(this.pageData.publishScheduledAt), this.$data.timeZonePreference);
    }
  },
  methods: {
    confirmHide() {
      if (!this.pageData.hidden && !this.unpublished) {
        this.$refs.hideModal.show();
      }
    },
    triggerShow() {
      if (this.pageData.hidden && !this.unpublished) {
        this.$emit('show');
      }
    },
    confirmDelete() {
      this.$refs.deleteModal.show();
    },
    handleDelete() {
      this.$emit('delete');
      this.$refs.deleteModal.dismiss();
    },
    handleHide() {
      this.$emit('hide');
      this.$refs.hideModal.dismiss();
    }
  }
};
</script>

<template>
  <div>
    <div class="row gx-0 comic-entry">
      <div
        v-if="pageData.imageDashThumbUrl"
        class="thumb-container"
      >
        <img
          :src="pageData.imageDashThumbUrl"
          alt="Comic thumbnail"
          class="col-auto mr-3"
        >
      </div>
      <div
        v-else
        class="col-auto mr-3"
      >
        <img
          src="/img/html.svg"
          style="height: 100px;"
        >
      </div>
      <div class="col">
        <div class="heading">
          {{ heading }}
        </div>
        <div
          class="subheading"
          :class="[ subheading.color ]"
        >
          <span
            :class="subheading.icon"
            class="fas"
          />
          {{ subheading.text }}
        </div>
        <div>
          <button
            :disabled="buttonsDisabled"
            type="button"
            class="btn btn-sm btn-outline-secondary"
            @click="$emit('edit')"
          >
            <span class="fas fa-pencil-alt" /> Edit
          </button>
          <button
            v-if="!unpublished && !pageData.hidden"
            :disabled="buttonsDisabled"
            type="button"
            class="btn btn-sm btn-outline-secondary"
            @click="confirmHide"
          >
            <span class="fas fa-eye-slash" /> Hide
          </button>
          <button
            v-if="!unpublished && pageData.hidden"
            :disabled="buttonsDisabled"
            type="button"
            class="btn btn-sm btn-outline-secondary"
            @click="triggerShow"
          >
            <span class="fas fa-eye" /> Show
          </button>
          <button
            :disabled="buttonsDisabled"
            type="button"
            class="btn btn-sm btn-outline-danger"
            @click="confirmDelete"
          >
            <span class="fas fa-trash-alt" /> Delete
          </button>
        </div>
      </div>
    </div>
    <modal-dialog
      ref="deleteModal"
      title="Confirm Delete"
      is-destructive
      ok-button-text="Delete"
      enable-clickaway
      @accept="handleDelete"
    >
      Are you sure you want to delete the comic page "{{ title }}"?
      This action cannot be undone!
    </modal-dialog>
    <modal-dialog
      v-if="!pageData.hidden && !unpublished"
      ref="hideModal"
      title="Confirm Hide"
      is-destructive
      enable-lickaway
      ok-button-text="Hide"
      @accept="handleHide"
    >
      Are you sure you want to hide the comic page "{{ title }}"?
      Existing links to this page will no longer work and it will not appear in the archive.
    </modal-dialog>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.comic-entry {
  padding: 1rem;
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-radius: 3px;

  .subheading.text-modified {
    color: $orange-400;
  }

  .thumb-container {
    width: 75px;
    height: 100px;
    overflow: hidden;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      flex: 0 0 auto;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .btn {
    margin-right: 0.4rem;
  }

  .heading {
    color: $black;
    font-weight: bold;
    font-size: 1.1em;
  }

  .subheading {
    color: $secondary;
    font-weight: bold;
    margin-bottom: 0.6rem;
  }
}
</style>

<script>
import BannerAlert from '../common/banner-alert.vue';
import BannerStack from '../common/banner-stack.vue';
import ModalDialog from '../common/modal-dialog.vue';
import PublishChangeContent from './publish-change-content.vue';
import PublishSummary from './publish-summary.vue';
import Axios from 'axios';
export default {
  components: {
    BannerAlert,
    BannerStack,
    ModalDialog,
    PublishChangeContent,
    PublishSummary
  },
  props: {
    hasPendingChanges: Boolean,
    unconfigured: Boolean
  },
  data() {
    return {
      publishRunning: false,
      errorLoading: false,
      publishItems: {
        'unscheduled': [],
        'scheduled': []
      }
    };
  },
  computed: {
    publishNowTitle() {
      if (this.publishRunning) {
        return 'Publishing cannot be triggered when a publish is already running.';
      }
      else if (this.unconfigured) {
        return 'Publishing is not set up. Contact an administrator to configure publishing.';
      }
      return null;
    }
  },
  created() {
    this.loadPublishItems();
  },
  methods: {
    loadPublishItems() {
      Axios.get('/api/publish')
        .then((response) => {
          this.$data.publishItems = response.data;
          this.$data.errorLoading = false;
        })
        .catch((error) => {
          this.$data.errorLoading = true;
          let errorMessage = "Failed to load publishing data. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            timeout: 5000,
            message: errorMessage
          });
        });
    },
    handlePublishRunUpdate(publishRunning) {
      const currentState = this.$data.publishRunning;
      this.$data.publishRunning = publishRunning;

      if (currentState != publishRunning) {
        this.loadPublishItems();
      }
    },
    triggerPublish() {
      this.$refs.startPublishDialog.dismiss();
      Axios.post('/api/publish')
        .then(() => {
          this.$refs.mainBanners.clearType('danger');
          this.$refs.mainBanners.add({
            class: 'success',
            dismissible: true,
            timeout: 5000,
            message: 'Publish started successfully.'
          });
        })
        .catch((error) => {
          this.$data.errorLoading = true;
          let errorMessage = "Failed to start publish.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissable: true,
            message: errorMessage
          });
        })
        .then(() => {
          this.$refs.publishSummary.reload();
        });
    }
  }
};
</script>

<template>
  <div class="publish-content">
    <h1>Publishing</h1>

    <banner-stack ref="mainBanners" />

    <div
      v-if="publishItems.unscheduled.length"
      class="mb-4"
    >
      <banner-alert alert-class="info">
        Unscheduled items are available to publish. If you don't want to wait for the next automatic publish, you
        can trigger a publish manually.
      </banner-alert>
    </div>

    <modal-dialog
      ref="startPublishDialog"
      :ok-button-disabled="publishRunning"
      title="Start Publish"
      ok-button-text="Publish Now"
      @accept="triggerPublish"
    >
      <banner-alert
        v-if="publishRunning"
        alert-class="warning"
      >
        Publishing cannot be triggered when a publish is already running.
        Please wait a moment for publishing to complete&hellip;
      </banner-alert>
      Are you sure you want to publish the site?
    </modal-dialog>

    <div class="row gx-5">
      <div class="col-auto left-pane py-2">
        <publish-change-content
          v-if="!errorLoading"
          :unscheduled-change-data="publishItems.unscheduled"
          :scheduled-change-data="publishItems.scheduled"
        />
      </div>
      <div class="col py-2">
        <h2 class="mb-1">
          Run Publish
        </h2>
        <p class="text-muted smalltext mb-2">
          Publishes can be run at any time, as long as another publish is not already running. If no changes are
          queued for publishing, the site's current state will simply be rebuilt.
        </p>
        <p
          v-if="unconfigured"
          class="text-danger smalltext mb-2"
        >
          Publishing is not set up. Contact an administrator to configure publishing.
        </p>
        <div
          :title="publishNowTitle"
          class="col-auto mb-3"
        >
          <button
            :class="{ 'disabled': publishRunning || unconfigured }"
            type="button"
            class="btn btn-primary"
            @click="$refs.startPublishDialog.show()"
          >
            Publish Now
          </button>
        </div>

        <publish-summary
          ref="publishSummary"
          @publishing-state-changed="handlePublishRunUpdate"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.publish-content {
  .left-pane {
    border-right: none;
  }
}

@include media-breakpoint-up(xl) {
  .publish-content {
    .left-pane {
      border-right: 1px solid $gray-400;
    }
  }
}
</style>

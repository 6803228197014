<script>
import BackgroundStatus from '../common/background-status.vue';
import KeyRegenDialog from './key-regen-dialog.vue';
import KeyViewerDialog from './key-viewer-dialog.vue';
import Axios from 'axios';
import ObjectUtil from '../../shared/objectutil.js';
export default {
  components: {
    BackgroundStatus,
    KeyRegenDialog,
    KeyViewerDialog
  },
  emits: {
    error: null,
    success: null
  },
  data() {
    return this.initialData();
  },
  computed: {
    hasSshKey() {
      return !!this.$data.sshPublicKey;
    },
    sshKeyStatusText() {
      return (this.hasSshKey)
        ? "SSH key configured"
        : "SSH key not configured";
    },
    sshKeyStatusIcon() {
      return (this.hasSshKey)
        ? "success"
        : "failed";
    },
    publishServerAddressValidationErrors() {
      if (!this.$data.publishServerAddress) {
        return 'Address is required';
      }

      const ipRegex =
        /(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
      const hostnameRegex =
        /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$/;
      if (!ipRegex.test(this.$data.publishServerAddress) && !hostnameRegex.test(this.$data.publishServerAddress)) {
        return 'Address must be a valid IP address or hostname';
      }

      return null;
    },
    publishServerPortValidationErrors() {
      if (!this.$data.publishServerPort) {
        return null;
      }

      let port = parseInt(this.$data.publishServerPort);
      if (!/^\d+$/.test(this.$data.publishServerPort) || port < 1 || port > 65535) {
        return 'Port must be a valid port number between 1 and 65535, inclusive';
      }

      return null;
    },
    publishServerUserValidationErrors() {
      if (!this.$data.publishServerUser) {
        return 'Username is required';
      }

      var usernameRegex = /^[a-z0-9_-]+$/i;
      if (!usernameRegex.test(this.$data.publishServerUser)) {
        return 'Username may only contain letters, numbers, _, and -';
      }

      return null;
    },
    publishServerValidationErrors() {
      return [
        this.publishServerAddressValidationErrors,
        this.publishServerPortValidationErrors,
        this.publishServerUserValidationErrors
      ].filter(err => err != null).join('; ');
    },
    sshKeyValidationErrors() {
      if (!this.hasSshKey) {
        return 'An SSH key must be created';
      }

      return null;
    },
    publishDirectoryValidationErrors() {
      if (!this.$data.publishDirectory) {
        return 'Required';
      }

      if (!this.$data.publishDirectory.startsWith('/') && !/^[a-z]:\\/i.test(this.$data.publishDirectory)) {
        return 'Must be an absolute file path';
      }

      return null;
    },
    publishJekyllFileValidationErrors() {
      if (!this.$data.publishJekyllFile) {
        return null;
      }

      // Also includes spaces to handle separating multiple files.
      if (!/^[a-z0-9_\-., ]+$/.test(this.$data.publishJekyllFile)) {
        return 'May only contain letters, numbers, -, ., and _';
      }

      return null;
    },
    isFormValid() {
      // Don't make the form ugly until a submission attempt has been made.
      if (!this.$data.validationEnabled) {
        return true;
      }

      return !this.publishServerAddressValidationErrors &&
        !this.publishServerPortValidationErrors &&
        !this.publishServerUserValidationErrors &&
        !this.sshKeyValidationErrors &&
        !this.publishDirectoryValidationErrors &&
        !this.publishJekyllFileValidationErrors;
    }
  },
  created() {
    this.getPublishSettings();
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  methods: {
    beforeWindowUnload(e) {
      if (ObjectUtil.commonPropertiesMismatch(this.$data.initialState, this.$data)) {
        e.preventDefault();
        return e.returnValue = 'There are unsaved changes. Are you sure you want to leave?';
      }
    },
    initialData() {
      return {
        publishServerAddress: null,
        publishServerPort: null,
        publishServerUser: null,
        publishDirectory: null,
        publishJekyllFile: null,
        sshPublicKey: null,
        validationEnabled: false,
        submitPending: false,
        errorLoading: false,
        dataReady: false,
        initialState: {}
      };
    },
    applyLoadedPublishSettings() {
      let settings = this.$data.rawPublishSettings;
      if (!settings) {
        return;
      }

      // Used for determining if user is nagivating away after making changes.
      this.$data.initialState = {
        publishServerAddress: settings.destinationServer,
        publishServerPort: settings.destinationPort,
        publishServerUser: settings.destinationUsername,
        publishDirectory: settings.destinationDirectory,
        publishJekyllFile: settings.jekyllConfig
      };

      Object.assign(this.$data, this.$data.initialState);
      this.$data.sshPublicKey = settings.publicKey;
    },
    handleKeyChanged(newPublicKey) {
      this.$data.sshPublicKey = newPublicKey;
      this.$refs.keyRegenDialog.dismiss();
      this.$emit('success', 'SSH key generated successfully.');
    },
    getPublishSettings() {
      Axios.get('/api/settings/publish')
        .then((response) => {
          this.$data.rawPublishSettings = response.data;
          this.applyLoadedPublishSettings();
          this.$data.dataReady = true;
          this.$data.errorLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$data.rawPublishSettings = {};
            this.applyLoadedPublishSettings();
            this.$data.dataReady = true;
            this.$data.errorLoading = false;
            return;
          }

          console.error(error);

          this.$data.errorLoading = true;
          let errorMessage = "Failed to load publishing settings. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$emit('error', errorMessage);
        });
    },
    reset() {
      let publishSettings = { ...this.$data.rawPublishSettings };
      Object.assign(
        this.$data,
        this.initialData(),

        // Include initialPublishSettings separately so we don't lose them.
        {
          rawPublishSettings: publishSettings,
          dataReady: true
        }
      );
      this.applyLoadedPublishSettings();
    },
    handleSave() {
      this.$data.validationEnabled = true;

      if (!this.isFormValid) {
        return;
      }

      this.$data.submitPending = true;

      let jsonData = {
        serverAddress: this.$data.publishServerAddress,
        serverPort: this.$data.publishServerPort ?? null,
        username: this.$data.publishServerUser,
        serverPath: this.$data.publishDirectory,
        jekyllConfig: this.$data.publishJekyllFile ?? null
      };

      Axios.put(`/api/settings/publish`, jsonData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(() => {
          this.$emit('success', 'Successfully saved publishing settings.');

          // Fetch the settings again so reset() doesn't revert to old ones.
          this.getPublishSettings();
        })
        .catch((error) => {
          let errorMessage = "Failed to save publishing settings. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$emit('error', errorMessage);
        })
        .then(() => {
          this.$data.submitPending = false;
        });
    }
  }
};
</script>

<template>
  <div class="publishing-settings-wrapper">
    <form
      v-if="!errorLoading && dataReady"
      novalidate
      @submit.prevent.stop="handleSave"
    >
      <div class="mb-3">
        <div>
          <div class="mb-2 gx-2 row">
            <div class="col-6">
              <label
                class="form-label label-required"
                for="serverAddressField"
              >Destination Server Address</label>
              <input
                id="serverAddressField"
                v-model.trim="publishServerAddress"
                class="form-control"
                :class="{ 'is-invalid': validationEnabled && !!publishServerAddressValidationErrors }"
                placeholder="example.com"
              >
            </div>
            <div class="col-2">
              <label
                class="form-label"
                for="serverPortField"
              >Port</label>
              <input
                id="serverPortField"
                v-model.trim="publishServerPort"
                class="form-control"
                :class="{ 'is-invalid': validationEnabled && !!publishServerPortValidationErrors }"
                placeholder="22"
              >
            </div>
            <div class="col-4">
              <label
                class="form-label label-required"
                for="serverUserField"
              >Username</label>
              <input
                id="serverUserField"
                v-model.trim="publishServerUser"
                class="form-control"
                :class="{ 'is-invalid': validationEnabled && !!publishServerUserValidationErrors }"
                placeholder="user"
              >
            </div>
            <input
              type="hidden"
              :class="{ 'is-invalid': validationEnabled && !!publishServerValidationErrors }"
            >
            <div class="invalid-feedback">
              {{ publishServerValidationErrors }}
            </div>
          </div>
        </div>
        <div
          style="border-radius: 5px; border: 1px solid #ccc; padding: 0.5rem; background-color: #fafafa;"
        >
          <div class="mb-2">
            <background-status
              :state="sshKeyStatusIcon"
              :title="sshKeyStatusText"
            />
          </div>
          <button
            v-if="hasSshKey"
            class="btn btn-sm btn-outline-secondary me-2"
            type="button"
            @click="$refs.keyViewerDialog.show"
          >
            View Public Key
          </button>
          <button
            v-if="hasSshKey"
            class="btn btn-sm btn-outline-danger"
            type="button"
            @click="$refs.keyRegenDialog.show"
          >
            Regenerate Key
          </button>
          <button
            v-else
            class="btn btn-sm btn-outline-secondary"
            type="button"
            @click="$refs.keyRegenDialog.show"
          >
            Generate Key
          </button>
          <key-viewer-dialog
            ref="keyViewerDialog"
            :public-key="sshPublicKey"
            :username="publishServerUser"
          />
          <key-regen-dialog
            ref="keyRegenDialog"
            :key-exists="hasSshKey"
            @key-changed="handleKeyChanged"
          />
        </div>
        <input
          type="hidden"
          :class="{ 'is-invalid': validationEnabled && !!sshKeyValidationErrors }"
        >
        <div class="invalid-feedback">
          {{ sshKeyValidationErrors }}
        </div>
        <div class="text-muted italic smalltext mt-2">
          Publishing will use SSH to send the generated website to the server. Be sure to verify that the specified
          user has privileges to write to the destination directory, and that a firewall will not block the connection.
        </div>
      </div>

      <div class="mb-3">
        <label
          class="form-label label-required"
          for="serverDirectoryField"
        >Destination Directory</label>
        <input
          id="serverDirectoryField"
          v-model.trim="publishDirectory"
          class="form-control"
          :class="{ 'is-invalid': validationEnabled && !!publishDirectoryValidationErrors }"
          placeholder="/var/www"
        >
        <div class="invalid-feedback">
          {{ publishDirectoryValidationErrors }}
        </div>
        <div class="text-muted italic smalltext mt-2">
          <strong>Warning:</strong> the contents of this directory will be entirely replaced with the published website
          files. Please be sure to use the correct directory!
        </div>
      </div>

      <div class="mb-3">
        <label
          class="form-label"
          for="jekyllConfigField"
        >Additional Jekyll Config File</label>
        <input
          id="jekyllConfigField"
          v-model.trim="publishJekyllFile"
          class="form-control"
          :class="{ 'is-invalid': validationEnabled && !!publishJekyllFileValidationErrors }"
          placeholder="_config_staging.yml"
        >
        <div class="invalid-feedback">
          {{ publishJekyllFileValidationErrors }}
        </div>
        <div class="text-muted italic smalltext mt-2">
          Publishing will use only <span class="mono">_config.yml</span> in the website source directory by default.
          To apply environment-specific configuration, you can optionally provide an additional, comma-separated,
          filenames here. Settings in these file will override settings in <span class="mono">_config.yml</span> in
          the order given. Files must be in the root of the website source directory.
        </div>
      </div>

      <button
        type="submit"
        class="btn btn-sm btn-primary me-2"
        :disabled="submitPending"
        @click="handleSave"
      >
        Save
      </button>
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary"
        :disabled="submitPending"
        @click="reset"
      >
        Reset
      </button>
    </form>
  </div>
</template>

<style lang="scss">
  .publish-settings-wrapper {
    max-width: 30em;
  }
</style>

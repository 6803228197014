<script>
import HtmlUtil from '../../shared/htmlutil.js';
export default {
  props: {
    countOnly: Boolean,
    changes: {
      type: Object,
      default: () => {}
    },
    removeText: {
      type: String,
      default: "Remove"
    },
    format: {
      type: String,
      default: "%t"
    }
  },
  computed: {
    addedChanges() {
      return ('add' in this.changes)
        ? this.changes.add
        : [];
    },
    modifiedChanges() {
      return ('modify' in this.changes)
        ? this.changes.modify
        : [];
    },
    removedChanges() {
      return ('remove' in this.changes)
        ? this.changes.remove
        : [];
    }
  },
  methods: {
    formatTitle(change) {
      return this
        .format
        .replace('%t', HtmlUtil.decodeHtmlEntities(change.title))
        .replace('%l', HtmlUtil.decodeHtmlEntities(change.link?.replace(/https?:\/\/(?:www\.)?/i, '')));
    }
  }
};
</script>

<template>
  <div class="changeList">
    <template v-if="countOnly">
      <li>
        <span v-if="addedChanges.length">
          <span class="fas fa-plus-circle text-success" />
          Add {{ addedChanges.length }}<template v-if="modifiedChanges.length || removedChanges.length">, </template>
        </span>
        <span v-if="modifiedChanges.length">
          <span class="fas fa-dot-circle text-modified" />
          Modify {{ modifiedChanges.length }}<template v-if="removedChanges.length">, </template>
        </span>
        <span v-if="removedChanges.length">
          <span class="fas fa-minus-circle text-danger" />
          {{ removeText }} {{ removedChanges.length }}
        </span>
      </li>
    </template>
    <template v-else>
      <li
        v-for="item in addedChanges"
        :key="item"
      >
        <span class="fas fa-plus-circle text-success" />
        Add
        <a
          v-if="item.link"
          :href="item.link"
        >
          {{ formatTitle(item) }}
        </a>
        <template v-else>
          {{ formatTitle(item) }}
        </template>
      </li>
      <li
        v-for="item in modifiedChanges"
        :key="item"
      >
        <span class="fas fa-dot-circle text-modified" />
        Modify
        <a
          v-if="item.link"
          :href="item.link"
        >
          {{ formatTitle(item) }}
        </a>
        <template v-else>
          {{ formatTitle(item) }}
        </template>
      </li>
      <li
        v-for="item in removedChanges"
        :key="item"
      >
        <span class="fas fa-minus-circle text-danger" />
        {{ removeText }}
        <a
          v-if="item.link"
          :href="item.link"
        >
          {{ formatTitle(item) }}
        </a>
        <template v-else>
          {{ formatTitle(item) }}
        </template>
      </li>
    </template>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.changeList {
  .fas.text-modified {
    color: $orange-400;
  }

  li {
    list-style-type: none;
  }
}
</style>

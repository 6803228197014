<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    isActive: Boolean,
    isValid: Boolean
  },
  computed: {
    icon() {
      if (!this.isActive) {
        return 'fa-circle';
      }
      else if (this.isValid) {
        return 'fa-check-circle';
      }
      return 'fa-times-circle';
    },
    color() {
      if (!this.isActive) {
        return 'text-muted';
      }
      else if (this.isValid) {
        return 'text-success';
      }
      return 'text-danger';
    }
  }
};
</script>

<template>
  <div
    class="row gx-2 validation-bullet-wrapper"
    :class="[ color ]"
  >
    <div class="col-auto">
      <div
        class="fas"
        :class="[ icon ]"
      />
    </div>
    <div class="col">
      <b>{{ title }}</b>
      <template v-if="description">
        <br><span class="desc">{{ description }}</span>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.validation-bullet-wrapper {
  .desc {
    white-space: pre;
  }
}
</style>

<script>
import DateUtil from '../../shared/dateutil.js';
import ModalDialog from '../common/modal-dialog.vue';
export default {
  components: {
    ModalDialog
  },
  props: {
    sketchData: {
      type: Object,
      default: () => {}
    },
    buttonsDisabled: {
      type: Boolean,
      default: false
    },
    isCurrent: Boolean
  },
  emits: {
    delete: null,
    edit: null,
    create: null
  },
  data() {
    return {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content')
    };
  },
  computed: {
    isQueued() {
      if (!this.sketchData) {
        return false;
      }

      return !this.isCurrent && !this.sketchData.publishScheduledAt;
    },
    headingText() {
      if (this.isCurrent) {
        return "Currently Live";
      }
      else if (this.isQueued) {
        return "Queued for Next Publish";
      }
      else {
        let dateTime = this.sketchData.publishScheduledAt;
        return 'Publishes ' +
          DateUtil.getUsDateString(dateTime, this.$data.timeZonePreference) + ' ' +
          DateUtil.getUsTimeString(dateTime, this.$data.timeZonePreference);
      }
    }
  },
  methods: {
    showDeleteModal() {
      this.$refs.deleteModal.show();
    },
    handleDelete() {
      this.$refs.deleteModal.dismiss();
      this.$emit('delete');
    }
  }
};
</script>

<template>
  <div class="sketch-entry-wrapper">
    <div
      class="row gx-0 daily-sketch-entry"
      :class="{ current: isCurrent }"
    >
      <template v-if="!!sketchData">
        <img
          :src="sketchData.imageUrl"
          alt="Sketch Preview"
          class="col-auto mr-3"
        >
        <div class="col">
          <div class="heading">
            <span
              class="fas fa-check-circle"
              :class="{ 'fa-check-circle': isCurrent, 'fa-ellipsis-h': isQueued, 'fa-clock': !isCurrent && !isQueued }"
            />
            {{ headingText }}
          </div>
          <div>
            <button
              v-if="isCurrent"
              :disabled="buttonsDisabled"
              type="button"
              class="btn btn-primary"
              @click="$emit('create')"
            >
              <span class="fas fa-plus" /> Queue New
            </button>
            <a
              class="btn btn-outline-primary"
              target="_blank"
              :href="sketchData.link"
              :class="{ 'btn-sm': !isCurrent }"
            >
              <span class="fas fa-link" /> Open Link
            </a>
            <button
              v-if="!isCurrent"
              :disabled="buttonsDisabled"
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click="$emit('edit')"
            >
              <span class="fas fa-pencil-alt" /> Edit
            </button>
            <button
              v-if="!isCurrent"
              :disabled="buttonsDisabled"
              type="button"
              class="btn btn-sm btn-outline-danger"
              @click="showDeleteModal"
            >
              <span class="fas fa-trash-alt" /> Delete
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col">
          <div class="mb-2">
            No daily sketch preview is currently set.
          </div>
          <button
            v-if="isCurrent"
            type="button"
            class="btn btn-primary"
            @click="$emit('create')"
          >
            <span class="fas fa-plus" /> Queue New
          </button>
        </div>
      </template>
    </div>
    <modal-dialog
      v-if="!isCurrent"
      ref="deleteModal"
      title="Confirm Delete"
      ok-button-text="Delete"
      is-destructive
      enable-clickaway
      @accept="handleDelete"
    >
      Are you sure you want to remove the queued daily sketch with link
      "<a
        :href="sketchData.link"
        target="_blank"
      >
        {{ sketchData.link }}
      </a>"?
    </modal-dialog>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";

.sketch-entry-wrapper {
  .daily-sketch-entry {
    padding: 1rem;
    background-color: $gray-100;
    border: 1px solid $gray-400;
    border-radius: 3px;

    img {
      height: 100px;
      margin-right: 1rem;
    }

    .btn {
      margin-right: 0.4rem;
    }

    .heading {
      color: $secondary;
      font-weight: bold;
      margin-bottom: 0.6rem;
      font-size: 1.1em;
    }

    &.current {
      img {
        height: 150px;
      }

      .heading {
        color: $green-500;
        font-size: 1.2em;
      }
    }
  }
}
</style>

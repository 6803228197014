<script>
import DateUtil from '../../shared/dateutil.js';
import HtmlUtil from '../../shared/htmlutil.js';
import ModalDialog from '../common/modal-dialog.vue';
export default {
  components: {
    ModalDialog
  },
  props: {
    fanartData: {
      type: Object,
      default: () => {}
    },
    buttonsDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    delete: null,
    edit: null
  },
  computed: {
    formattedDate() {
      if (!this.fanartData.dateReceived) {
        return null;
      }

      return DateUtil.getUsDateString(new Date(this.fanartData.dateReceived), 'UTC');
    }
  },
  methods: {
    showDeleteModal() {
      this.$refs.deleteModal.show();
    },
    handleDelete() {
      this.$refs.deleteModal.dismiss();
      this.$emit('delete');
    },
    decodeEntities(value) {
      return HtmlUtil.decodeHtmlEntities(value);
    }
  }
};
</script>

<template>
  <div class="fanart-entry-wrapper">
    <div class="fanart-entry">
      <div class="center">
        <img
          :src="fanartData.thumbUrl"
          alt="Fanart preview"
          @click="$emit('edit')"
        >
        <div class="fanart-meta">
          <div
            :class="{ unknown: !fanartData.artist }"
            class="truncate"
          >
            {{ decodeEntities(fanartData.artist) || 'Unknown artist' }}
          </div>
          <div :class="{ unknown: !formattedDate }">
            {{ formattedDate || 'Unknown date' }}
          </div>
        </div>
      </div>
      <div class="row gx-1 flex-nowrap">
        <div class="col">
          <span
            :disabled="buttonsDisabled"
            class="fas fa-pencil-alt me-2"
            title="Edit"
            @click="$emit('edit')"
          />
          <a
            v-if="fanartData.link"
            :href="decodeEntities(fanartData.link)"
            target="_blank"
            title="Open link"
          >
            <span class="fas fa-link link" />
          </a>
        </div>
        <div class="col-auto">
          <span
            :disabled="buttonsDisabled"
            class="fas fa-trash-alt"
            title="Delete"
            @click="showDeleteModal"
          />
        </div>
      </div>
    </div>
    <modal-dialog
      ref="deleteModal"
      title="Confirm Delete"
      ok-button-text="Delete"
      is-destructive
      enable-clickaway
      @accept="handleDelete"
    >
      Are you sure you want to remove this fanart?
    </modal-dialog>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.fanart-entry-wrapper {
  .fanart-entry {
    background-color: $gray-100;
    border: 1px solid $gray-400;
    border-radius: 3px;
    width: 150px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;

    .truncate {
      width: calc(150px - 2rem);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      margin-bottom: 0.3rem;
      cursor: pointer;
    }

    .fanart-meta {
      margin: 0.3rem 0;
      font-size: 0.9em;
      margin-bottom: 0.3rem;

      .unknown {
        font-style: italic;
      }
    }

    .fas {
      color: $gray-500;

      &:hover {
        cursor: pointer;
        color: $primary;
      }

      &.fa-trash-alt:hover {
        color: $danger;
      }
    }
  }
}
</style>

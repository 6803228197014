<script>
import DateUtil from '../../shared/dateutil.js';
export default {
  props: {
    run: {
      type: Object,
      default: () => {}
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    details: null,
    cancel: null
  },
  data() {
    return {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content')
    };
  },
  computed: {
    lastPublishTime() {
      const date = DateUtil.getLongUsDateString(this.run.startTime, this.timeZonePreference);
      const time = DateUtil.getUsTimeString(this.run.startTime, this.timeZonePreference);
      return `${date} ${time}`;
    },
    statusIcon() {
      if (!this.run.endTime && !this.run.cancelRequested) {
        return ['fa-sync', 'text-primary', 'animate'];
      }
      else if (!this.run.endTime) {
        return ['fa-sync', 'text-muted', 'animate'];
      }
      else if (!this.run.isSuccess && !this.run.canceled) {
        return ['fa-times-circle', 'text-danger'];
      }
      else if (!this.run.isSuccess) {
        return ['fa-minus-circle', 'text-muted'];
      }
      else {
        return ['fa-check-circle', 'text-success'];
      }
    },
    summaryText() {
      if (!this.run.endTime && !this.run.cancelRequested) {
        return 'Currently running…';
      }
      else if (!this.run.endTime) {
        return 'Canceling…';
      }
      else if (!this.run.isSuccess && !this.run.canceled) {
        return `Failed after ${this.duration}.`;
      }
      else if (!this.run.isSuccess) {
        return `Canceled after ${this.duration}.`;
      }
      else {
        return `Successfully completed after ${this.duration}.`;
      }
    },
    duration() {
      if (!this.run.endTime) {
        return 'Invalid duration';
      }

      return DateUtil.getTimeDifference(this.run.startTime, this.run.endTime);
    },
    initiatorText() {
      if (!this.run.startedBy) {
        return 'Started automatically';
      }

      return `Started by ${this.run.startedBy}`;
    }
  }
};
</script>

<template>
  <div class="last-publish-wrapper">
    Last publish run: {{ lastPublishTime }}
    <div class="mt-2 px-2">
      <div class="text-muted mb-2">
        <span
          class="fas"
          :class="statusIcon"
        />
        {{ summaryText }}<br>
        {{ initiatorText }}
      </div>
      <button
        type="button"
        class="btn btn-outline-secondary me-2"
        @click="$emit('details', run.id)"
      >
        View Details
      </button>
      <button
        v-if="!run.endTime"
        :disabled="!run.canCancel || run.cancelRequested || cancelDisabled"
        type="button"
        class="btn btn-outline-danger"
        @click="$emit('cancel', run.id)"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<style lang="scss">
  .last-publish-wrapper {
    @keyframes spin {
      from {
        transform:rotate(0deg);
      }
      to {
        transform:rotate(360deg);
      }
    }
    .animate {
      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
</style>

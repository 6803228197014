<script>
import BannerStack from '../common/banner-stack.vue';
import AuthorizedUserList from './authorized-user-list.vue';
import DangerZoneSettings from './danger-zone-settings.vue';
import PublishingSettings from './publish-settings.vue';
export default {
  components: {
    AuthorizedUserList,
    BannerStack,
    DangerZoneSettings,
    PublishingSettings
  },
  props: {
    currentUserId: {
      type: Number,
      default: null
    }
  },
  methods: {
    handleSubcomponentError(message) {
      this.$refs.banners.add({
        class: 'danger',
        dismissible: true,
        timeout: 10000,
        message: message
      });
    },
    handleSubcomponentSuccess(message, messageClass = 'success') {
      this.$refs.banners.add({
        class: messageClass,
        dismissible: true,
        message: message,
        timeout: 5000
      });
    }
  }
};
</script>

<template>
  <div class="admin-settings">
    <h1>Site Settings</h1>
    <banner-stack ref="banners" />
    <div class="mb-4">
      <h2>Authorized Users</h2>
      <authorized-user-list
        :current-user-id="currentUserId"
        @error="handleSubcomponentError"
        @success="handleSubcomponentSuccess"
      />
    </div>

    <div class="mb-4">
      <h2>Publishing Settings</h2>
      <publishing-settings
        @error="handleSubcomponentError"
        @success="handleSubcomponentSuccess"
      />
    </div>

    <div class="mb-4">
      <h2>Danger Zone</h2>
      <danger-zone-settings
        @error="handleSubcomponentError"
        @success="handleSubcomponentSuccess"
      />
    </div>
  </div>
</template>

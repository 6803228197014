import 'bootstrap';
import { createApp } from 'vue';
import DashboardContent from './components/dashboard/dashboard-content.vue';
import PublishingContent from './components/publishing/publishing-content.vue';
import ChaptersContent from './components/chapters/chapter-content.vue';
import IncentiveContent from './components/incentive/incentive-content.vue';
import DailySketchContent from './components/dailysketch/sketch-content.vue';
import FanartContent from './components/fanart/fanart-content.vue';
import PagesContent from './components/pages/pages-content.vue';
import SettingsContent from './components/settings/settings-content.vue';
import Axios from 'axios';
import 'v-calendar/dist/style.css';

const VueApp = {
  data() {
    return {
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    };
  },
  components: {
    DashboardContent,
    PublishingContent,
    ChaptersContent,
    IncentiveContent,
    DailySketchContent,
    FanartContent,
    PagesContent,
    SettingsContent
  },
  mounted() {
    Axios.defaults.headers.common = {
      'X-XSRF-TOKEN': this.$data.csrf,
      'Accept': 'application/json'
    };
  }
};

createApp(VueApp).mount('main');

<script>
import ModalDialog from '../common/modal-dialog.vue';
export default {
  components: {
    ModalDialog
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    isCurrentUser: Boolean
  },
  emits: {
    delete: null,
    promote: null,
    demote: null
  },
  computed: {
    displayName() {
      return `${this.user.name} (${this.user.email})`;
    }
  },
  methods: {
    handleDelete() {
      this.$refs.deleteModal.dismiss();
      this.$emit('delete');
    },
    handlePromote() {
      this.$refs.promoteModal.dismiss();
      this.$emit('promote');
    },
    handleDemote() {
      this.$refs.demoteModal.dismiss();
      this.$emit('demote');
    },
    handleDeleteButtonClick() {
      if (this.isCurrentUser) {
        return;
      }
      this.$refs.deleteModal.show();
    },
    handlePromotionButtonClick() {
      if (this.isCurrentUser) {
        return;
      }

      if (this.user.isAdmin) {
        this.$refs.demoteModal.show();
      }
      else {
        this.$refs.promoteModal.show();
      }
    }
  }
};
</script>

<template>
  <div class="user-entry">
    <div class="user-record">
      <div class="row gx-3 flex-nowrap">
        <div class="col text-truncate">
          <span
            class="username"
            :title="displayName"
          >
            {{ displayName }}
          </span>
        </div>
        <div class="col-auto row gx-0">
          <div class="col-auto">
            <span
              class="fas fa-shield-alt user-action adminify-user me-2"
              :class="{ active: user.isAdmin, 'user-action-disabled': isCurrentUser }"
              @click="handlePromotionButtonClick"
            />
          </div>
          <div
            v-if="!isCurrentUser"
            class="col-auto"
          >
            <span
              class="fas fa-trash-alt user-action delete-user me-2"
              @click="handleDeleteButtonClick"
            />
          </div>
        </div>
      </div>
    </div>
    <modal-dialog
      ref="deleteModal"
      title="Confirm User Deletion"
      is-destructive
      enable-clickaway
      ok-button-text="Delete User"
      @accept="handleDelete"
    >
      Are you sure you want to delete the user "{{ user.email }}"? This user will no longer be able to access this application.
    </modal-dialog>
    <modal-dialog
      ref="demoteModal"
      title="Confirm User Demotion"
      is-destructive
      enable-clickaway
      ok-button-text="Demote User"
      @accept="handleDemote"
    >
      Are you sure you want to remove admin privileges from "{{ user.email }}"?
    </modal-dialog>
    <modal-dialog
      ref="promoteModal"
      title="Confirm User Promotion"
      ok-button-text="Promote User"
      enable-clickaway
      @accept="handlePromote"
    >
      Are you sure you want to give admin privileges to "{{ user.email }}"? This user will be able to access these admin settings.
    </modal-dialog>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.user-entry {
  .user-record {
    .username {
      font-size: 0.9em;
    }

    .user-action {
      cursor: pointer;
      color: $blue-200;

      &.active {
        color: $yellow-300;
      }

      &:hover {
        color: $white;
      }

      &.delete-user:hover {
        color: $red-200;
      }

      &.user-action-disabled {
        cursor: not-allowed;

        &.active:hover {
          color: $yellow-300;
        }

        &:hover {
          color: $blue-200;
        }

        &.delete-user:hover {
          color: $blue-200;
        }
      }
    }

    margin-bottom: 0.5rem;
    padding: 0.3rem 0.5rem;
    cursor: default;
    border-radius: 3px;
    background-color: $blue-400;
    color: $white;
  }
}
</style>

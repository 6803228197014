<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default: 'waiting'
    }
  },
  computed: {
    isIdle() {
      return this.state == null;
    },
    isWaiting() {
      return this.state == 'waiting';
    },
    isFailed() {
      return this.state == 'failed';
    },
    isError() {
      return this.state == 'error';
    },
    isPartial() {
      return this.state == 'partial';
    },
    isSuccess() {
      return this.state == 'success';
    },
    icon() {
      if (this.isWaiting || this.isIdle) {
        return 'fa-sync';
      }
      else if (this.isSuccess) {
        return 'fa-check-circle';
      }
      else if (this.isPartial) {
        return 'fa-exclamation-circle';
      }
      return 'fa-times-circle';
    },
    color() {
      if (this.isWaiting) {
        return 'text-primary';
      }
      else if (this.isSuccess) {
        return 'text-success';
      }
      else if (this.isPartial) {
        return 'text-warning';
      }
      else if (this.isIdle) {
        return 'text-muted';
      }
      return 'text-danger';
    }
  }
};
</script>

<template>
  <div class="bg-status-wrapper">
    <div class="row gx-2">
      <div class="col-auto">
        <div
          class="fas"
          :class="[ icon, color, { 'animate': isWaiting } ]"
        />
      </div>
      <div class="col">
        <b>{{ title }}</b><span
          v-if="message"
          class="desc"
        >: {{ message }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .bg-status-wrapper {
    @keyframes spin {
      from {
        transform:rotate(0deg);
      }
      to {
        transform:rotate(360deg);
      }
    }
    .animate {
      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
</style>

<script>
import BackgroundStatus from '../common/background-status.vue';
import BannerStack from '../common/banner-stack.vue';
import ModalDialog from '../common/modal-dialog.vue';
import DateUtil from '../../shared/dateutil';
import Axios from 'axios';
export default {
  components: {
    BackgroundStatus,
    BannerStack,
    ModalDialog
  },
  data() {
    var data = {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content')
    };
    Object.assign(data, this.initialData());
    return data;
  },
  computed: {
    fanartMessage() {
      if (this.$data.fanartError) {
        return this.$data.fanartError;
      }
      else if (this.$data.fanartFinished) {
        return `Completed at ${DateUtil.getUsDateString(this.$data.fanartFinished, this.$data.timeZonePreference)} ${DateUtil.getUsTimeString(this.$data.fanartFinished, this.$data.timeZonePreference)}`;
      }
      else if (this.$data.fanartStarted) {
        return `${this.$data.fanartProgress}% complete`;
      }
      else {
        return null;
      }
    },
    fanartState() {
      if (this.$data.fanartError) {
        return 'error';
      }
      else if (this.$data.fanartFinished) {
        return 'success';
      }
      else if (this.$data.fanartStarted) {
        return `waiting`;
      }
      else {
        return null;
      }
    },
    pagesMessage() {
      if (this.$data.pagesError) {
        return this.$data.pagesError;
      }
      else if (this.$data.pagesFinished) {
        return `Completed at ${DateUtil.getUsDateString(this.$data.pagesFinished, this.$data.timeZonePreference)} ${DateUtil.getUsTimeString(this.$data.pagesFinished, this.$data.timeZonePreference)}`;
      }
      else if (this.$data.pagesStarted) {
        return `${this.$data.pagesProgress}% complete`;
      }
      else {
        return null;
      }
    },
    pagesState() {
      if (this.$data.pagesError) {
        return 'error';
      }
      else if (this.$data.pagesFinished) {
        return 'success';
      }
      else if (this.$data.pagesStarted) {
        return `waiting`;
      }
      else {
        return null;
      }
    },
    teasersMessage() {
      if (this.$data.teasersError) {
        return this.$data.teasersError;
      }
      else if (this.$data.teasersFinished) {
        return `Completed at ${DateUtil.getUsDateString(this.$data.teasersFinished, this.$data.timeZonePreference)} ${DateUtil.getUsTimeString(this.$data.teasersFinished, this.$data.timeZonePreference)}`;
      }
      else if (this.$data.teasersStarted) {
        return `${this.$data.teasersProgress}% complete`;
      }
      else {
        return null;
      }
    },
    teasersState() {
      if (this.$data.teasersError) {
        return 'error';
      }
      else if (this.$data.teasersFinished) {
        return 'success';
      }
      else if (this.$data.teasersStarted) {
        return `waiting`;
      }
      else {
        return null;
      }
    },
    inProgress() {
      return this.$data.fanartStarted || this.$data.pagesStarted || this.$data.teasersStarted;
    }
  },
  methods: {
    initialData() {
      return {
        fanartStarted: false,
        fanartFinished: null,
        fanartProgress: 0,
        fanartError: null,

        pagesStarted: false,
        pagesFinished: null,
        pagesProgress: 0,
        pagesError: null,

        teasersStarted: false,
        teasersFinished: null,
        teasersProgress: 0,
        teasersError: null,

        refreshTimer: null
      };
    },
    reset() {
      if (this.$data.refreshTimer) {
        window.clearInterval(this.$data.refreshTimer);
      }

      this.$refs.dialogBanners.clearAll();
      Object.assign(this.$data, this.initialData());
    },
    handleCloseButton() {
      this.$refs.regenModal.dismiss();
    },
    handleShown() {
      this.getAllStatus();
      this.$data.refreshTimer = window.setInterval(
        function () {
          this.getAllStatus();
        }.bind(this),
        2000
      );
    },
    show() {
      this.$refs.regenModal.show();
    },
    start() {
      this.startFanart();
      this.startPages();
      this.startTeasers();
    },
    cancel() {
      this.cancelFanart();
      this.cancelPages();
      this.cancelTeasers();
    },
    getAllStatus() {
      this.getFanartRegenStatus();
      this.getPagesRegenStatus();
      this.getTeasersRegenStatus();
    },
    startFanart() {
      if (this.fanartStarted) {
        return;
      }

      Axios.put('/api/fanart/regen')
        .then(() => {
          this.$data.fanartStarted = true;
          this.getFanartRegenStatus();
        })
        .catch((error) => {
          let errorMessage = "Failed to regenerate fanart thumbnails.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }
          this.$data.fanartError = 'Failed to start regeneration';

          this.$refs.dialogBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    },
    startPages() {
      if (this.pagesStarted) {
        return;
      }

      Axios.put('/api/pages/regen/page')
        .then(() => {
          this.$data.pagesStarted = true;
          this.getPagesRegenStatus();
        })
        .catch((error) => {
          let errorMessage = "Failed to regenerate page thumbnails.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }
          this.$data.pagesError = 'Failed to start page thumbnail regeneration';

          this.$refs.dialogBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    },
    startTeasers() {
      if (this.teasersStarted) {
        return;
      }

      Axios.put('/api/pages/regen/teaser')
        .then(() => {
          this.$data.teasersStarted = true;
          this.getTeasersRegenStatus();
        })
        .catch((error) => {
          let errorMessage = "Failed to regenerate page teasers.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }
          this.$data.pagesError = 'Failed to start page teaser regeneration';

          this.$refs.dialogBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    },
    cancelFanart() {
      Axios.delete('/api/fanart/regen')
        .then(() => {
          this.getFanartRegenStatus();
        })
        .catch((error) => {
          let errorMessage = "Failed to cancel fanart regeneration.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.dialogBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    },
    cancelPages() {
      Axios.delete('/api/pages/regen/page')
        .then(() => {
          this.getPagesRegenStatus();
        })
        .catch((error) => {
          let errorMessage = "Failed to cancel page regeneration.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.dialogBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    },
    cancelTeasers() {
      Axios.delete('/api/pages/regen/teaser')
        .then(() => {
          this.getTeasersRegenStatus();
        })
        .catch((error) => {
          let errorMessage = "Failed to cancel teaser regeneration.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.dialogBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    },
    getFanartRegenStatus() {
      Axios.get('/api/fanart/regen')
        .then((response) => {
          this.$data.fanartProgress = response.data.progress;
          this.$data.fanartError = null;
          this.$data.fanartFinished = null;

          if (response.data.failedJobs) {
            this.$data.fanartError = `Job failed at ${DateUtil.getUsDateString(response.data.cancelledAt, this.$data.timeZonePreference)} ${DateUtil.getUsTimeString(response.data.cancelledAt, this.$data.timeZonePreference)}`;
            this.$data.fanartStarted = false;
          }
          else if (response.data.cancelledAt) {
            this.$data.fanartError = `Operation cancelled at ${DateUtil.getUsDateString(response.data.cancelledAt, this.$data.timeZonePreference)} ${DateUtil.getUsTimeString(response.data.cancelledAt, this.$data.timeZonePreference)}`;
            this.$data.fanartStarted = false;
          }
          else if (response.data.finishedAt) {
            this.$data.fanartFinished = response.data.finishedAt;
            this.$data.fanartStarted = false;
          }
          else {
            this.$data.fanartStarted = true;
          }
        });
    },
    getPagesRegenStatus() {
      Axios.get('/api/pages/regen/page')
        .then((response) => {
          this.$data.pagesProgress = response.data.progress;
          this.$data.pagesError = null;
          this.$data.pagesFinished = null;

          if (response.data.failedJobs) {
            this.$data.pagesError = `Job failed at ${DateUtil.getUsDateString(response.data.cancelledAt, this.$data.timeZonePreference)} ${DateUtil.getUsTimeString(response.data.cancelledAt, this.$data.timeZonePreference)}`;
            this.$data.pagesStarted = false;
          }
          else if (response.data.cancelledAt) {
            this.$data.pagesError = `Operation cancelled at ${DateUtil.getUsDateString(response.data.cancelledAt, this.$data.timeZonePreference)} ${DateUtil.getUsTimeString(response.data.cancelledAt, this.$data.timeZonePreference)}`;
            this.$data.pagesStarted = false;
          }
          else if (response.data.finishedAt) {
            this.$data.pagesFinished = response.data.finishedAt;
            this.$data.pagesStarted = false;
          }
          else {
            this.$data.pagesStarted = true;
          }
        });
    },
    getTeasersRegenStatus() {
      Axios.get('/api/pages/regen/teaser')
        .then((response) => {
          this.$data.teasersProgress = response.data.progress;
          this.$data.teasersError = null;
          this.$data.teasersFinished = null;

          if (response.data.failedJobs) {
            this.$data.teasersError = `Job failed at ${DateUtil.getUsDateString(response.data.cancelledAt, this.$data.timeZonePreference)} ${DateUtil.getUsTimeString(response.data.cancelledAt, this.$data.timeZonePreference)}`;
            this.$data.teasersStarted = false;
          }
          else if (response.data.cancelledAt) {
            this.$data.teasersError = `Operation cancelled at ${DateUtil.getUsDateString(response.data.cancelledAt, this.$data.timeZonePreference)} ${DateUtil.getUsTimeString(response.data.cancelledAt, this.$data.timeZonePreference)}`;
            this.$data.teasersStarted = false;
          }
          else if (response.data.finishedAt) {
            this.$data.teasersFinished = response.data.finishedAt;
            this.$data.teasersStarted = false;
          }
          else {
            this.$data.teasersStarted = true;
          }
        });
    }
  }
};
</script>

<template>
  <div class="regen-dialog">
    <modal-dialog
      ref="regenModal"
      title="Regenerate Thumbnails"
      ok-button-text="Close"
      :show-close-button="false"
      :show-dismiss-button="false"
      size-large
      disable-animation
      @closed="reset"
      @shown="handleShown"
      @accept="handleCloseButton"
    >
      <banner-stack ref="dialogBanners" />
      <p>
        Regenerating thumbnails may take a while. Regenerated thumbnails will not be applied until a publish occurs.
        It is safe to close this dialog while regeneration occurs in the background.
      </p>

      <div class="statuses-wrapper">
        <BackgroundStatus
          title="Fanart"
          class="mb-1"
          :message="fanartMessage"
          :state="fanartState"
        />
      </div>
      <div class="statuses-wrapper">
        <BackgroundStatus
          title="Page thumbnails"
          class="mb-1"
          :message="pagesMessage"
          :state="pagesState"
        />
      </div>
      <div class="statuses-wrapper">
        <BackgroundStatus
          title="Page teasers"
          class="mb-1"
          :message="teasersMessage"
          :state="teasersState"
        />
      </div>

      <button
        v-if="!inProgress"
        type="button"
        class="btn btn-sm btn-primary mb-3 mt-3"
        @click="start"
      >
        Run Regeneration
      </button>
      <button
        v-else
        type="button"
        class="btn btn-sm btn-danger mb-3 mt-3"
        @click="cancel"
      >
        Cancel Regeneration
      </button>
    </modal-dialog>
  </div>
</template>

<script>
import { Alert } from 'bootstrap';
export default {
  props: {
    alertClass: {
      type: String,
      default: ''
    },
    dismissible: Boolean,
    timeout: {
      type: Number,
      default: 0
    }
  },
  emits: {
    closed: null
  },
  mounted() {
    if (this.timeout) {
      setTimeout(() => Alert.getOrCreateInstance(this.$refs.banner).close(), this.timeout);
    }
  },
  methods: {
    emitClosed() {
      this.$emit('closed');
    }
  }
};
</script>

<template>
  <div
    ref="banner"
    :class="[ `alert-${alertClass}`, dismissible ? 'alert-dismissible' : '' ]"
    class="alert show fade"
    role="alert"
    v-on="{ 'closed.bs.alert': emitClosed }"
  >
    <slot />
    <button
      v-if="dismissible"
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    />
  </div>
</template>

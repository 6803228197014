<script>
import BannerStack from '../common/banner-stack.vue';
import CroppableImageUpload from '../common/croppable-image-upload.vue';
import ModalDialog from '../common/modal-dialog.vue';
import ValidationBullet from '../common/validation-bullet.vue';
import DateUtil from '../../shared/dateutil.js';
import Axios from 'axios';
import { DatePicker } from 'v-calendar';
import MarkdownToolbar from 'quill-markdown-toolbar';
import HtmlUtil from '../../shared/htmlutil.js';
import ObjectUtil from '../../shared/objectutil.js';
import { QuillEditor } from '@vueup/vue-quill';
import { Tab } from 'bootstrap';
import Tagify from '@yaireo/tagify';
export default {
  components: {
    ModalDialog,
    DatePicker,
    CroppableImageUpload,
    ValidationBullet,
    QuillEditor,
    BannerStack
  },
  props: {
    pages: {
      type: Array,
      default: () => []
    },
    chapters: {
      type: Array,
      default: () => []
    },
    initialPageData: {
      type: Object,
      default: () => {}
    }
  },
  emits: {
    upload: null
  },
  data() {
    var data = {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content'),
      teaserCropperKey: Math.round(Math.random() * 1000),
      comicCropperKey: Math.round(Math.random() * 1000),
      idSuffix: Math.round(Math.random() * 1000)
    };
    Object.assign(data, this.initialData());
    return data;
  },
  computed: {
    isPositionedAfterPage() {
      return this.$data.pagePositionType === 'afterPage';
    },
    isPublished() {
      return this.isEditing && !!this.initialPageData?.publishedAt;
    },
    isScheduled() {
      return this.$data.pageAvailableType === 'schedule';
    },
    scheduleTzString() {
      if (!this.isScheduled) {
        return null;
      }

      return DateUtil.getTimeZoneShortName(this.$data.pageScheduleDate, this.$data.timeZonePreference);
    },
    isComic() {
      return this.$data.pageType === 'comic';
    },
    isFiller() {
      return this.$data.pageType === 'filler';
    },
    isHtml() {
      return this.$data.pageType === 'html';
    },
    isEditing() {
      return !!this.initialPageData;
    },
    nextPageNumber() {
      if (!this.pages.length) {
        return 1;
      }

      return Math.max.apply(Math, this.pages.map((o) => o.pageNumber)) + 1;
    },
    initialImageCropData() {
      // This allows the cropper to retain an edited crop when changing modal tabs.
      if (this.$data.comicImageCropData) {
        return {
          'x': this.$data.comicImageCropData.x,
          'y': this.$data.comicImageCropData.y,
          'width': this.$data.comicImageCropData.width,
          'height': this.$data.comicImageCropData.height
        };
      }

      if (!this.initialPageData?.imageCrop) {
        return null;
      }

      return {
        'x': this.initialPageData.imageCrop.x,
        'y': this.initialPageData.imageCrop.y,
        'width': this.initialPageData.imageCrop.width,
        'height': this.initialPageData.imageCrop.height
      };
    },
    initialTeaserCropData() {
      // This allows the cropper to retain an edited crop when changing modal tabs.
      if (this.$data.teaserImageCropData) {
        return {
          'x': this.$data.teaserImageCropData.x,
          'y': this.$data.teaserImageCropData.y,
          'width': this.$data.teaserImageCropData.width,
          'height': this.$data.teaserImageCropData.height
        };
      }

      if (!this.initialPageData?.teaserCrop) {
        return null;
      }

      return {
        'x': this.initialPageData.teaserCrop.x,
        'y': this.initialPageData.teaserCrop.y,
        'width': this.initialPageData.teaserCrop.width,
        'height': this.initialPageData.teaserCrop.height
      };
    },
    scheduleMinDate() {
      if (this.isEditing && Date.parse(this.initialPageData?.publishScheduledAt) < new Date()) {
        return Date.parse(this.initialPageData?.publishScheduledAt);
      }

      return new Date();
    },
    tagsJson() {
      if (!this.$data.pageTags || !this.$data.pageTags.length) {
        return null;
      }

      let tagsArray = this.$data.pageTags.map(tag => tag.value || tag);
      return JSON.stringify(tagsArray);
    },
    modalAcceptText() {
      if (this.isEditing) {
        return "Save";
      }
      return "Queue for Publishing";
    },
    pageTypeRadioValidationErrors() {
      if (
        this.$data.pageType != 'comic' &&
        this.$data.pageType != 'filler' &&
        this.$data.pageType != 'html'
      ) {
        return 'Required';
      }
      return null;
    },
    pageNumberValidationErrors() {
      if (!this.isComic) {
        return null;
      }
      else if (!this.$data.pageNumber) {
        return 'Required';
      }
      else if (!/^\d+$/.test(this.$data.pageNumber)) {
        return 'Must be a whole number';
      }
      else if (
        this.pages.find(pg => pg.id != this.initialPageData?.id && pg.pageNumber == this.$data.pageNumber)
      ) {
        return 'This page number is already in use';
      }

      return null;
    },
    pageChapterValidationErrors() {
      if (!this.$data.pageChapter) {
        return null;
      }

      if (!this.chapters.find(ch => ch.id == this.$data.pageChapter)) {
        return 'Invalid chapter selected';
      }

      return null;
    },
    pageDisplayDateValidationErrors() {
      if (!this.$data.pageDisplayDate) {
        return 'Required';
      }

      return null;
    },
    pageLinkValidationErrors() {
      let linkRegex = new RegExp('^[hH][tT]{2}[pP][sS]?:\\/\\/.+$');
      if (!this.$data.pageLink) {
        return null;
      }
      else if (!this.isComic && !this.isFiller) {
        return null;
      }
      else if (!linkRegex.test(this.$data.pageLink)) {
        return 'Must be a valid URL';
      }

      return null;
    },
    pageSlugValidationErrors() {
      let slugRegex = new RegExp('^[A-Za-z0-9_-]+$');
      if (!this.isFiller && !this.isHtml) {
        return null;
      }
      else if (!this.$data.pageSlug) {
        return 'Required';
      }
      else if (!slugRegex.test(this.$data.pageSlug)) {
        return 'Must contain only numbers, letters, _, and -';
      }
      else if (
        this.pages.find(pg => pg.id != this.initialPageData?.id && pg.slug == this.$data.pageSlug)
      ) {
        return 'This slug is already in use';
      }

      return null;
    },
    pagePositionRadioValidationErrors() {
      if (
        this.$data.pagePositionType != 'unchanged' &&
        this.$data.pagePositionType != 'latest' &&
        this.$data.pagePositionType != 'afterPage' &&
        this.$data.pagePositionType != 'start'
      ) {
        return 'An option must be selected';
      }
      return null;
    },
    pagePositionAfterPageValidationErrors() {
      if (!this.isPositionedAfterPage) {
        return null;
      }
      else if (
        this.$data.pagePositionAfterPage != 0 &&
        !this.$data.pagePositionAfterPage
      ) {
        return 'A page number must be specified';
      }

      if (!this.pages.find(pg => pg.id == this.$data.pagePositionAfterPage)) {
        return 'Invalid page selected';
      }

      return null;
    },
    pagePositionValidationErrors() {
      let radioError = this.pagePositionRadioValidationErrors;
      let pageError = this.pagePositionAfterPageValidationErrors;

      if (!radioError && !pageError) {
        return null;
      }

      let errors = [];
      if (radioError) {
        errors.push(radioError);
      }
      if (pageError) {
        errors.push(pageError);
      }

      return errors.join('\n');
    },
    pageAvailableTypeRadioValidationErrors() {
      if (
        this.$data.pageAvailableType != 'publish' &&
        this.$data.pageAvailableType != 'schedule'
      ) {
        return 'Required';
      }
      return null;
    },
    pageScheduleDateValidationErrors() {
      if (!this.isScheduled) {
        return null;
      }

      if (!this.$data.pageScheduleDate) {
        return 'Required';
      }
      else if (this.$data.pageScheduleDate <= new Date()) {
        return 'Scheduled time must be in the future';
      }

      return null;
    },
    isComicValidDimensions() {
      if (!this.$data.comicImageWidth) {
        return false;
      }

      return this.$data.comicImageWidth <= this.$data.comicMaxWidth;
    },
    isComicValidType() {
      if (!this.$data.comicImageFile) {
        return false;
      }

      let type = this.$data.comicImageFile.type;
      let allowedTypes = [
        'image/png',
        'image/jpeg',
        'image/gif'
      ];

      if (!allowedTypes.find(el => el.toLowerCase() == type)) {
        return false;
      }

      return true;
    },
    teaserDimensionText() {
      if (
        !this.$data.teaserImageFile ||
        !this.$data.teaserCropWidth ||
        !this.$data.teaserCropHeight
      ) {
        return '';
      }

      let roundedWidth = Math.round(this.$data.teaserCropWidth);
      let roundedHeight = Math.round(this.$data.teaserCropHeight);

      return `${roundedWidth} x ${roundedHeight}`;
    },
    isTeaserValidDimensions() {
      if (!this.$data.teaserImageWidth || !this.$data.teaserImageHeight) {
        return false;
      }

      return this.$data.teaserImageWidth >= this.$data.teaserMinWidth &&
        this.$data.teaserImageHeight >= this.$data.teaserMinHeight;
    },
    isTeaserValidType() {
      if (!this.$data.teaserImageFile) {
        return false;
      }

      let type = this.$data.teaserImageFile.type;
      let allowedTypes = [
        'image/png',
        'image/jpeg'
      ];

      if (!allowedTypes.find(el => el.toLowerCase() == type)) {
        return false;
      }

      return true;
    },
    htmlContentValidationErrors() {
      if (!this.isHtml) {
        return null;
      }

      if (!this.htmlContent) {
        return "Required";
      }

      return null;
    },
    htmlHeadValidationErrors() {
      if (!this.isHtml) {
        return null;
      }

      return null;
    },
    footerValidationErrors() {
      // For future validation.
      return null;
    },
    headerValidationErrors() {
      // For future validation.
      return null;
    },
    isInfoTabValid() {
      return !this.pageTypeRadioValidationErrors &&
        !this.pageNumberValidationErrors &&
        !this.pageDisplayDateValidationErrors &&
        !this.pageChapterValidationErrors &&
        !this.pageLinkValidationErrors &&
        !this.pageSlugValidationErrors &&
        !this.pagePositionValidationErrors &&
        !this.pageAvailableTypeRadioValidationErrors &&
        !this.pageScheduleDateValidationErrors;
    },
    isImageTabValid() {
      if (!this.isComic && !this.isFiller) {
        return true;
      }

      if (this.isEditing && !this.comicImageFile) {
        return true;
      }

      return !!this.comicImageFile &&
        this.isComicValidDimensions &&
        this.isComicValidType;
    },
    isTeaserTabValid() {
      if (!this.teaserImageFile) {
        return true;
      }

      return this.isTeaserValidDimensions &&
        this.isTeaserValidType;
    },
    isHtmlTabValid() {
      if (!this.isHtml) {
        return true;
      }

      return !this.htmlContentValidationErrors &&
        !this.htmlHeadValidationErrors;
    },
    isHeaderTabValid() {
      // For future validation.
      return true;
    },
    isFormValid() {
      // Don't make the form ugly until a submission attempt has been made.
      if (!this.$data.validationEnabled) {
        return true;
      }

      return this.isInfoTabValid &&
        this.isImageTabValid &&
        this.isTeaserTabValid &&
        this.isHtmlTabValid;
    }
  },
  watch: {
    comicImageFile(newFile, oldFile) {
      if (newFile === oldFile) {
        return;
      }

      URL.revokeObjectURL(this.$data.comicImageFileUrl);

      if (newFile) {
        this.$data.comicImageFileUrl = URL.createObjectURL(newFile);
      }
    },
    teaserImageFile(newFile, oldFile) {
      if (newFile === oldFile) {
        return;
      }

      URL.revokeObjectURL(this.$data.teaserImageFileUrl);

      if (newFile) {
        this.$data.teaserImageFileUrl = URL.createObjectURL(newFile);
      }
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  mounted() {
    let tagify = new Tagify(this.$refs.tagsInput);
    tagify.on('remove', () => this.$data.pageTags = tagify.value);
    tagify.on('add', () => this.$data.pageTags = tagify.value);

    if (this.initialPageData?.tags) {
      tagify.addTags(this.initialPageData.tags);
    }

    this.$data.tagify = tagify;
  },
  updated() {
    if (!this.isEditing) {
      this.$data.pageNumber = this.nextPageNumber;
      this.$data.initialState.pageNumber = this.$data.pageNumber;
    }
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  methods: {
    beforeWindowUnload(e) {
      if (ObjectUtil.commonPropertiesMismatch(this.$data.initialState, this.$data)) {
        e.preventDefault();
        return e.returnValue = 'There are unsaved changes. Are you sure you want to leave?';
      }
    },
    initialData() {
      var tomorrowNoon = new Date();
      tomorrowNoon.setDate(tomorrowNoon.getDate() + 1);
      tomorrowNoon.setHours(12, 0, 0, 0);

      var todayMidnight = new Date();
      todayMidnight.setHours(0, 0, 0, 0);

      let initialDisplayDate = todayMidnight;
      if (this.initialPageData?.displayDate) {
        initialDisplayDate = new Date(this.initialPageData.displayDate);
      }

      let initialScheduleDate = tomorrowNoon;
      let initialAvailableRadioSelection = 'publish';
      if (this.initialPageData?.publishScheduledAt) {
        initialScheduleDate = new Date(this.initialPageData.publishScheduledAt);
        initialAvailableRadioSelection = 'schedule';
      }

      let data = {
        validationEnabled: false,
        pageType: this.initialPageData?.type || 'comic',
        pageTitle: this.initialPageData?.title || null,
        pageCaption: this.initialPageData?.caption || null,
        pagePositionType: this.initialPageData ? 'unchanged' : 'latest',
        pagePositionAfterPage: '',
        pageDisplayDate: initialDisplayDate,
        pageNumber: this.initialPageData?.pageNumber || this.nextPageNumber,
        pageTags: this.initialPageData?.tags || null,
        pageLink: this.initialPageData?.clickLink || null,
        pageSlug: this.initialPageData?.slug || null,
        pageAvailableType: initialAvailableRadioSelection,
        pageScheduleDate: initialScheduleDate,
        pageChapter: this.initialPageData?.chapterId || '',
        comicImageFile: null,
        comicImageFileUrl: null,
        comicImageCropData: null,
        comicImageWidth: null,
        comicCropping: false,
        comicMaxWidth: 825,
        comicInitialImage: this.initialPageData?.imageUrl || null,
        htmlContent: this.initialPageData?.htmlContent || null,
        htmlHead: this.initialPageData?.htmlHeadContent || null,
        teaserImageFile: null,
        teaserImageFileUrl: null,
        teaserImageCropData: null,
        teaserImageHeight: null,
        teaserImageWidth: null,
        teaserCropping: false,
        teaserMinWidth: 1000,
        teaserMinHeight: 500,
        teaserCropWidth: null,
        teaserCropHeight: null,
        teaserInitialImage: this.initialPageData?.teaserUrl || null,
        headerHtml: this.initialPageData?.headerHtml || null,
        footerHtml: this.initialPageData?.footerHtml || null,
        transcript: this.initialPageData?.transcript || null,
        submitPending: false,
        quillToolbarOptions: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            ['markdown'],
            ['clean']
          ],
          handlers: {
            'markdown': function () {}
          }
        },
        quillModules: [
          {
            name: 'markdown-toolbar',
            module: MarkdownToolbar,
            options: {}
          }
        ]
      };

      data.initialState = {
        pageType: data.pageType,
        pageTitle: data.pageTitle,
        pageCaption: data.pageCaption,
        pagePositionType: data.pagePositionType,
        pagePositionAfterPage: data.pagePositionAfterPage,
        pageDisplayDate: data.pageDisplayDate,
        pageNumber: data.pageNumber,
        pageTags: data.pageTags,
        pageLink: data.pageLink,
        pageSlug: data.pageSlug,
        pageAvailableType: data.pageAvailableType,
        pageScheduleDate: data.pageScheduleDate,
        pageChapter: data.pageChapter,
        comicImageFile: data.comicImageFile,
        htmlContent: data.htmlContent,
        htmlHead: data.htmlHead,
        teaserImageFile: data.teaserImageFile,
        headerHtml: data.headerHtml,
        footerHtml: data.footerHtml,
        transcript: data.transcript
      };

      return data;
    },
    reset() {
      this.$refs.dialogBanners.clearAll();
      this.$refs.comicPreviewImg.reset();
      this.$refs.teaserPreviewImg.reset();
      Tab.getOrCreateInstance(this.$refs.defaultNav).show();
      this.$data.tagify.removeAllTags();
      if (this.initialPageData?.tags) {
        this.$data.tagify.addTags(this.initialPageData.tags);
      }
      Object.assign(this.$data, this.initialData());
      this.$refs.transcriptEditor.setHTML(this.$data.transcript);
    },
    roundDecimal(number) {
      return Math.round(number * 1000) / 1000;
    },
    getPageDropdownTitle(page) {
      let pageTitle = page.title || 'Untitled page';
      if (page.type == 'comic') {
        return `[${page.pageNumber}] ${pageTitle}`;
      }
      else {
        return `[Filler] ${pageTitle}`;
      }
    },
    showModal() {
      this.$refs.uploadModal.show();
    },
    handleComicFileSelect(file) {
      this.$data.comicImageFile = file;
    },
    handleComicImageLoaded(imageHeight, imageWidth) {
      this.$data.comicImageWidth = imageWidth;
    },
    handleComicImageCropped(cropData) {
      this.$data.comicImageCropData = cropData;
    },
    handleTeaserFileSelect(file) {
      this.$data.teaserImageFile = file;
    },
    handleTeaserImageLoaded(imageHeight, imageWidth) {
      this.$data.teaserImageHeight = imageHeight;
      this.$data.teaserImageWidth = imageWidth;
    },
    handleTeaserImageCropped(cropData) {
      this.$data.teaserImageCropData = cropData;
    },
    handleTeaserImageCropChange(cropData) {
      this.$data.teaserCropWidth = cropData.width;
      this.$data.teaserCropHeight = cropData.height;
    },
    handleTeaserReset() {
      this.$data.teaserImageFile = null;
      this.$data.teaserImageCropData = null;
      this.$data.teaserImageHeight = null;
      this.$data.teaserImageWidth = null;
      this.$data.teaserCropping = false;
      this.$data.teaserCropWidth = null;
      this.$data.teaserCropHeight = null;
    },
    handleImageTabDisplayed() {
      this.$data.comicCropperKey = Math.random();
    },
    handleTeaserTabDisplayed() {
      this.$data.teaserCropperKey = Math.random();
    },
    handleTeaserDelete() {
      this.$data.teaserInitialImage = null;
      this.$refs.teaserPreviewImg.reset();
    },
    submitUpload() {
      this.$data.validationEnabled = true;

      if (!this.isFormValid) {
        return;
      }

      this.$data.submitPending = true;
      this.$refs.dialogBanners.clearType('danger');
      let formData = new FormData();

      if ((this.isComic || this.isFiller) && !!this.$data.comicImageFile) {
        formData.append('image', this.$data.comicImageFile);
      }

      if (this.$data.teaserImageFile) {
        formData.append('teaser', this.$data.teaserImageFile);
      }

      formData.append('type', this.$data.pageType);
      formData.append('chapterId', this.$data.pageChapter || '');
      formData.append('displayDate', this.$data.pageDisplayDate.toISOString().substring(0, 10));

      if (this.$data.pageTitle) {
        formData.append('title', HtmlUtil.decodeHtmlEntities(this.$data.pageTitle) || '');
      }

      if (this.tagsJson) {
        formData.append('tags', this.tagsJson || '');
      }

      if (this.isComic) {
        formData.append('pageNumber', this.$data.pageNumber);
      }

      if (this.$data.pageCaption) {
        formData.append('caption', HtmlUtil.decodeHtmlEntities(this.$data.pageCaption) || '');
      }

      if (this.$data.pageLink) {
        formData.append('clickLink', HtmlUtil.decodeHtmlEntities(this.$data.pageLink) || '');
      }

      if (this.$data.pageSlug) {
        formData.append('slug', HtmlUtil.decodeHtmlEntities(this.$data.pageSlug) || '');
      }

      if (this.$data.transcript) {
        formData.append('transcript', this.$data.transcript);
      }

      if (this.isComic || this.isFiller) {
        formData.append('headerHtml', this.$data.headerHtml || '');
        formData.append('footerHtml', this.$data.footerHtml || '');
      }

      if (this.isHtml) {
        formData.append('htmlContent', this.$data.htmlContent || '');
        formData.append('htmlHeadContent', this.$data.htmlHead || '');
      }

      if (this.isScheduled) {
        formData.append('scheduleDate', this.$data.pageScheduleDate.toISOString());
      }

      let orderAfterPageId = null;
      if (this.$data.pagePositionType == 'start') {
        orderAfterPageId = -1;
      }
      else if (this.$data.pagePositionType == 'afterPage') {
        orderAfterPageId = this.$data.pagePositionAfterPage;
      }
      formData.append('orderAfterId', orderAfterPageId || '');

      if (this.$data.comicImageCropData) {
        formData.append('imageCropX', this.roundDecimal(this.$data.comicImageCropData.x));
        formData.append('imageCropY', this.roundDecimal(this.$data.comicImageCropData.y));
        formData.append('imageCropWidth', this.$data.comicImageCropData.width);
        formData.append('imageCropHeight', this.$data.comicImageCropData.height);
      }

      if (this.$data.teaserImageCropData) {
        formData.append('teaserCropX', this.roundDecimal(this.$data.teaserImageCropData.x));
        formData.append('teaserCropY', this.roundDecimal(this.$data.teaserImageCropData.y));
        formData.append('teaserCropWidth', this.$data.teaserImageCropData.width);
        formData.append('teaserCropHeight', this.$data.teaserImageCropData.height);
      }

      let route = this.isEditing
        ? `/api/pages/${this.initialPageData.id}`
        : '/api/pages';

      Axios.post(route, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          this.$emit('upload');
          this.$refs.uploadModal.dismiss();
        })
        .catch((error) => {
          let errorMessage = "Failed to save page. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.dialogBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        })
        .then(() => {
          this.$data.submitPending = false;
        });
    },
    decodeEntities(value) {
      return HtmlUtil.decodeHtmlEntities(value);
    }
  }
};
</script>

<template>
  <modal-dialog
    ref="uploadModal"
    title="Add page"
    waiting-text="Saving"
    class="page-upload"
    size-large
    :ok-button-text="modalAcceptText"
    :ok-button-disabled="comicCropping || teaserCropping || (validationEnabled && !isFormValid)"
    :is-waiting="submitPending"
    @closed="reset"
    @accept="submitUpload"
  >
    <template #header>
      <ul
        class="nav nav-pills"
        role="tablist"
      >
        <li class="nav-item">
          <button
            ref="defaultNav"
            class="nav-link active"
            type="button"
            role="tab"
            :data-bs-toggle="(!teaserCropping && !comicCropping) ? 'tab' : 'none'"
            :data-bs-target="`#infoTabContent-${idSuffix}`"
          >
            Info<span
              v-if="validationEnabled && !isInfoTabValid"
              class="tab-validation fas fa-exclamation-circle"
            />
          </button>
        </li>
        <li class="nav-item">
          <button
            class="nav-link"
            :data-bs-toggle="(!teaserCropping && !comicCropping) ? 'tab' : 'none'"
            :data-bs-target="`#imagesTabContent-${idSuffix}`"
            type="button"
            role="tab"
            :class="{ 'disabled': !isComic && !isFiller, 'hidden': !isComic && !isFiller }"
            v-on="{ 'shown.bs.tab': handleImageTabDisplayed }"
          >
            Image<span
              v-if="validationEnabled && !isImageTabValid"
              class="tab-validation fas fa-exclamation-circle"
            />
          </button>
        </li>
        <li class="nav-item">
          <button
            class="nav-link"
            :data-bs-toggle="(!teaserCropping && !comicCropping) ? 'tab' : 'none'"
            :data-bs-target="`#contentTabContent-${idSuffix}`"
            type="button"
            role="tab"
            :class="{ 'disabled': !isHtml, 'hidden': !isHtml }"
          >
            HTML Content<span
              v-if="validationEnabled && !isHtmlTabValid"
              class="tab-validation fas fa-exclamation-circle"
            />
          </button>
        </li>
        <li class="nav-item">
          <button
            class="nav-link"
            :data-bs-toggle="(!teaserCropping && !comicCropping) ? 'tab' : 'none'"
            :data-bs-target="`#teaserTabContent-${idSuffix}`"
            type="button"
            role="tab"
            v-on="{ 'shown.bs.tab': handleTeaserTabDisplayed }"
          >
            Teaser Image<span
              v-if="validationEnabled && !isTeaserTabValid"
              class="tab-validation fas fa-exclamation-circle"
            />
          </button>
        </li>
        <li class="nav-item">
          <button
            class="nav-link"
            :data-bs-toggle="(!teaserCropping && !comicCropping) ? 'tab' : 'none'"
            :data-bs-target="`#transcriptTabContent-${idSuffix}`"
            type="button"
            role="tab"
          >
            Transcript
          </button>
        </li>
        <li class="nav-item">
          <button
            class="nav-link"
            :data-bs-toggle="(!teaserCropping && !comicCropping) ? 'tab' : 'none'"
            :data-bs-target="`#headerTabContent-${idSuffix}`"
            type="button"
            role="tab"
            :class="{ 'disabled': !isComic && !isFiller, 'hidden': !isComic && !isFiller }"
          >
            Header/Footer<span
              v-if="validationEnabled && !isHeaderTabValid"
              class="tab-validation fas fa-exclamation-circle"
            />
          </button>
        </li>
      </ul>
    </template>
    <template #default>
      <banner-stack ref="dialogBanners" />
      <div class="tab-content">
        <div
          :id="`infoTabContent-${idSuffix}`"
          class="tab-pane active"
        >
          <form
            novalidate
            @submit.prevent.stop="submitUpload"
          >
            <div class="row gx-3 mb-3">
              <div class="col-6">
                <div class="mb-2">
                  <div class="form-text label-required">
                    <b>Page type</b>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      :id="`pageTypeComic-${idSuffix}`"
                      v-model="pageType"
                      class="form-check-input"
                      name="pageTypeRadio"
                      type="radio"
                      value="comic"
                      :class="{ 'is-invalid': validationEnabled && !!pageTypeRadioValidationErrors }"
                      :disabled="isPublished"
                    >
                    <label
                      class="form-check-label"
                      :for="`pageTypeComic-${idSuffix}`"
                    >
                      Comic
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      :id="`pageTypeFiller-${idSuffix}`"
                      v-model="pageType"
                      class="form-check-input"
                      name="pageTypeRadio"
                      type="radio"
                      value="filler"
                      :class="{ 'is-invalid': validationEnabled && !!pageTypeRadioValidationErrors }"
                      :disabled="isPublished"
                    >
                    <label
                      class="form-check-label"
                      :for="`pageTypeFiller-${idSuffix}`"
                    >
                      Comic Filler
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      :id="`pageTypeHtml-${idSuffix}`"
                      v-model="pageType"
                      class="form-check-input"
                      name="pageTypeRadio"
                      type="radio"
                      value="html"
                      :class="{ 'is-invalid': validationEnabled && !!pageTypeRadioValidationErrors }"
                      :disabled="isPublished"
                    >
                    <label
                      class="form-check-label"
                      :for="`pageTypeHtml-${idSuffix}`"
                    >
                      HTML Filler
                    </label>
                  </div>
                  <div class="invalid-feedback">
                    {{ pageTypeRadioValidationErrors }}
                  </div>
                </div>
                <div class="mb-2">
                  <label
                    class="form-label"
                    :for="`pageTitle-${idSuffix}`"
                  >
                    Title
                  </label>
                  <input
                    :id="`pageTitle-${idSuffix}`"
                    :value="decodeEntities(pageTitle)"
                    class="form-control"
                    type="text"
                    @input="pageTitle = $event.target.value"
                  >
                </div>
                <div class="mb-2">
                  <label
                    class="form-label"
                    :for="`pageChapter-${idSuffix}`"
                  >
                    Chapter
                  </label>
                  <select
                    :id="`pageChapter-${idSuffix}`"
                    v-model="pageChapter"
                    class="form-select"
                    :class="{ 'is-invalid': validationEnabled && !!pageChapterValidationErrors }"
                  >
                    <option value="">
                      (None)
                    </option>
                    <option
                      v-for="chapter in chapters"
                      :key="chapter"
                      :value="chapter.id"
                    >
                      {{ chapter.title || 'Untitled chapter' }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    {{ pageChapterValidationErrors }}
                  </div>
                </div>
                <div
                  v-if="isComic"
                  class="mb-2"
                >
                  <label
                    class="form-label"
                    :class="{ 'label-required': isComic }"
                    :for="`pageNumber-${idSuffix}`"
                  >
                    Page Number
                  </label>
                  <input
                    :id="`pageNumber-${idSuffix}`"
                    v-model="pageNumber"
                    :disabled="!isComic"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': validationEnabled && !!pageNumberValidationErrors }"
                  >
                  <div class="invalid-feedback">
                    {{ pageNumberValidationErrors }}
                  </div>
                </div>
                <div
                  v-if="isFiller || isHtml"
                  class="mb-2"
                >
                  <label
                    class="form-label"
                    :class="{ 'label-required': isFiller || isHtml }"
                    :for="`pageSlug-${idSuffix}`"
                  >URL (Slug)</label>
                  <div class="input-group has-validation">
                    <span class="input-group-text">/comics/</span>
                    <input
                      :id="`pageSlug-${idSuffix}`"
                      :value="decodeEntities(pageSlug)"
                      type="text"
                      class="form-control"
                      :disabled="(!isFiller && !isHtml) || isPublished"
                      :class="{ 'is-invalid': validationEnabled && !!pageSlugValidationErrors }"
                      @input="pageSlug = $event.target.value"
                    >
                    <div class="invalid-feedback">
                      {{ pageSlugValidationErrors }}
                    </div>
                  </div>
                </div>
                <div class="mb-2">
                  <label
                    class="form-label"
                    :for="`pageCaption-${idSuffix}`"
                  >
                    Caption
                  </label>
                  <textarea
                    :id="`pageCaption-${idSuffix}`"
                    :value="decodeEntities(pageCaption)"
                    class="form-control noresize nonewline"
                    rows="4"
                    :disabled="!isComic && !isFiller"
                    @input="pageCaption = $event.target.value"
                  />
                </div>
                <div class="mb-2">
                  <label
                    class="form-label"
                    :for="`pageLink-${idSuffix}`"
                  >
                    Click Link
                  </label>
                  <input
                    :id="`pageLink-${idSuffix}`"
                    :value="decodeEntities(pageLink)"
                    type="text"
                    class="form-control"
                    placeholder="https://www.twokindscomic.com/..."
                    :disabled="!isComic && !isFiller"
                    :class="{ 'is-invalid': validationEnabled && !!pageLinkValidationErrors }"
                    @input="pageLink = $event.target.value"
                  >
                  <div class="invalid-feedback">
                    {{ pageLinkValidationErrors }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-2">
                  <div class="form-text label-required">
                    <b>Available</b>
                  </div>
                  <div class="form-check form-check-inline mb-2">
                    <input
                      :id="`pagePublishQueue-${idSuffix}`"
                      v-model="pageAvailableType"
                      class="form-check-input"
                      name="pageAvailableTime"
                      type="radio"
                      value="publish"
                      :disabled="isPublished"
                      :class="{ 'is-invalid': validationEnabled && !!pageAvailableTypeRadioValidationErrors }"
                    >
                    <label
                      class="form-check-label"
                      :for="`pagePublishQueue-${idSuffix}`"
                    >
                      On next publish
                    </label>
                  </div>
                  <div class="form-check form-check-inline mb-2">
                    <input
                      :id="`pagePublishSchedule-${idSuffix}`"
                      v-model="pageAvailableType"
                      class="form-check-input"
                      name="pageAvailableTime"
                      type="radio"
                      value="schedule"
                      :disabled="isPublished"
                      :class="{ 'is-invalid': validationEnabled && !!pageAvailableTypeRadioValidationErrors }"
                    >
                    <label
                      class="form-check-label"
                      :for="`pagePublishSchedule-${idSuffix}`"
                    >
                      Schedule&hellip;
                    </label>
                  </div>
                  <div class="invalid-feedback">
                    {{ pageAvailableTypeRadioValidationErrors }}
                  </div>
                  <date-picker
                    v-if="isScheduled"
                    v-model="pageScheduleDate"
                    mode="dateTime"
                    is-required
                    :masks="{ input: 'MM/DD/YYYY hh:mm A' }"
                    :min-date="scheduleMinDate"
                    :disabled="!isScheduled || isPublished"
                    :timezone="timeZonePreference"
                  >
                    <template #default="{ inputValue, showPopover }">
                      <div class="input-group mb-2 has-validation">
                        <span class="input-group-text">
                          <span
                            class="fas fa-calendar-alt center"
                            style="width: 1rem;"
                          />
                        </span>
                        <input
                          ref="publishDate"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': validationEnabled && !!pageScheduleDateValidationErrors }"
                          :disabled="!isScheduled || isPublished"
                          :value="inputValue"
                          @click="showPopover()"
                          @focus="showPopover()"
                        >
                        <div class="invalid-feedback">
                          {{ pageScheduleDateValidationErrors }}
                        </div>
                      </div>
                      <div class="smalltext text-muted italic">
                        Time zone is {{ scheduleTzString }}.
                      </div>
                    </template>
                  </date-picker>
                </div>
                <div class="mb-2">
                  <div
                    class="form-text label-required"
                    :class="{ 'is-invalid': validationEnabled && !!pagePositionValidationErrors }"
                  >
                    <b>Position at</b>
                  </div>
                  <div
                    v-if="isEditing"
                    class="form-check"
                  >
                    <input
                      :id="`pagePositionUnchanged-${idSuffix}`"
                      v-model="pagePositionType"
                      class="form-check-input"
                      name="pagePosRadio"
                      type="radio"
                      value="unchanged"
                      :class="{ 'is-invalid': validationEnabled && !!pagePositionRadioValidationErrors }"
                    >
                    <label
                      class="form-check-label"
                      :for="`pagePositionUnchanged-${idSuffix}`"
                    >
                      Unchanged
                    </label>
                  </div>
                  <div
                    v-if="!isPublished"
                    class="form-check"
                  >
                    <input
                      :id="`pagePositionLatest-${idSuffix}`"
                      v-model="pagePositionType"
                      class="form-check-input"
                      name="pagePosRadio"
                      type="radio"
                      value="latest"
                      :class="{ 'is-invalid': validationEnabled && !!pagePositionRadioValidationErrors }"
                    >
                    <label
                      class="form-check-label"
                      :for="`pagePositionLatest-${idSuffix}`"
                    >
                      Latest
                    </label>
                  </div>
                  <div
                    v-if="pages.length"
                    class="form-check"
                  >
                    <div class="row gx-3 align-items-center">
                      <div class="col-auto">
                        <input
                          :id="`pagePositionAfterPage-${idSuffix}`"
                          v-model="pagePositionType"
                          class="form-check-input"
                          name="pagePosRadio"
                          type="radio"
                          value="afterPage"
                          :class="{ 'is-invalid': validationEnabled && !!pagePositionRadioValidationErrors }"
                        >
                        <label
                          class="form-check-label"
                          :for="`pagePositionAfterPage-${idSuffix}`"
                        >
                          After page:
                        </label>
                      </div>
                      <div class="col">
                        <select
                          v-model="pagePositionAfterPage"
                          :disabled="!isPositionedAfterPage"
                          class="form-select form-select-sm"
                          :class="{ 'is-invalid': validationEnabled && !!pagePositionAfterPageValidationErrors }"
                        >
                          <option
                            disabled
                            value=""
                          >
                            Select page
                          </option>
                          <option
                            v-for="page in pages"
                            :key="page"
                            :value="page.id"
                          >
                            {{ getPageDropdownTitle(page) }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="pages.length"
                    class="form-check"
                  >
                    <input
                      :id="`pagePositionStart-${idSuffix}`"
                      v-model="pagePositionType"
                      class="form-check-input"
                      name="pagePosRadio"
                      type="radio"
                      value="start"
                      :class="{ 'is-invalid': validationEnabled && !!pagePositionRadioValidationErrors }"
                    >
                    <label
                      class="form-check-label"
                      :for="`pagePositionStart-${idSuffix}`"
                    >
                      Beginning
                    </label>
                  </div>
                  <div class="invalid-feedback">
                    {{ pagePositionValidationErrors }}
                  </div>
                </div>
                <div class="mb-2">
                  <label
                    class="form-label"
                    for="pageTags"
                  >
                    Tags
                  </label>
                  <textarea
                    id="pageTags"
                    ref="tagsInput"
                    class="form-control"
                  />
                </div>
                <div class="mb-2">
                  <label
                    class="form-label label-required"
                    :for="`displayDate-${idSuffix}`"
                  >
                    Display Date
                  </label>
                  <date-picker
                    v-model="pageDisplayDate"
                    mode="date"
                    is-required
                    :masks="{ input: 'MM/DD/YYYY' }"
                    timezone="UTC"
                  >
                    <template #default="{ inputValue, showPopover }">
                      <div class="input-group mb-2 has-validation">
                        <span class="input-group-text">
                          <span
                            class="fas fa-calendar-alt center"
                            style="width: 1rem;"
                          />
                        </span>
                        <input
                          :id="`displayDate-${idSuffix}`"
                          ref="displayDate"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': validationEnabled && !!pageDisplayDateValidationErrors }"
                          :value="inputValue"
                          @click="showPopover()"
                          @focus="showPopover()"
                        >
                        <div class="invalid-feedback">
                          {{ pageDisplayDateValidationErrors }}
                        </div>
                      </div>
                    </template>
                  </date-picker>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          :id="`imagesTabContent-${idSuffix}`"
          class="tab-pane"
        >
          <div class="row gx-2 align-items-center">
            <div class="col-7">
              <croppable-image-upload
                ref="comicPreviewImg"
                :key="comicCropperKey"
                :validation-enabled="validationEnabled"
                :crop-aspect="0.77"
                :crop-preview-target="$refs.comicThumbPreview"
                :preview-height="450"
                :preview-width="345"
                :crop-auto-crop-area="1.0"
                :initial-image="comicImageFileUrl || comicInitialImage"
                :initial-crop-data="initialImageCropData"
                file-type-accept="image/png,image/jpeg,image/gif"
                @select="handleComicFileSelect"
                @image-loaded="handleComicImageLoaded"
                @cropped="handleComicImageCropped"
                @crop-start="comicCropping = true"
                @crop-end="comicCropping = false"
              />
            </div>
            <div class="col">
              <div class="mb-4 center thumbnail-area">
                <div class="mb-1">
                  Thumbnail Preview
                </div>
                <div
                  ref="comicThumbPreview"
                  class="thumbnail-preview image-preview mb-4"
                />
                <button
                  type="button"
                  class="btn btn-outline-secondary mb-2"
                  @click="$refs.comicPreviewImg.triggerUploadDialog()"
                >
                  <span class="fas fa-file-upload" /> Select Image
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :disabled="comicCropping || (!comicImageFile && !initialPageData?.imageUrl)"
                  @click="$refs.comicPreviewImg.changeThumbnail()"
                >
                  <span class="fas fa-crop-alt" /> Change Thumbnail
                </button>
              </div>
              <div
                class="mx-auto"
                style="width: 50%;"
              >
                <validation-bullet
                  title="Image set"
                  :is-valid="!!comicInitialImage || !!comicImageFile"
                  :is-active="validationEnabled || !!comicImageFile || !!comicInitialImage"
                  class="mb-3"
                />
                <validation-bullet
                  title="Dimensions"
                  :description="`Max width ${comicMaxWidth}px`"
                  :is-valid="(!!comicInitialImage && !comicImageFile) || isComicValidDimensions"
                  :is-active="validationEnabled || !!comicImageFile || !!comicInitialImage"
                  class="mb-3"
                />
                <validation-bullet
                  title="Format"
                  description="png, jpg, gif"
                  :is-valid="(!!comicInitialImage && !comicImageFile) || isComicValidType"
                  :is-active="validationEnabled || !!comicImageFile || !!comicInitialImage"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          :id="`transcriptTabContent-${idSuffix}`"
          class="tab-pane"
        >
          <form
            class="mb-2"
            novalidate
            @submit.prevent.stop="submitUpload"
          >
            <div>
              <label class="form-label">Page Transcript</label>
              <QuillEditor
                ref="transcriptEditor"
                v-model:content="transcript"
                :modules="quillModules"
                :toolbar="quillToolbarOptions"
                content-type="html"
                theme="snow"
              />
            </div>
          </form>
          <div class="smalltext text-muted mb-3">
            This editor also supports the Markdown format for transcripts.
            Once you've finished writing the transcript as Markdown, select all of the text and press the Markdown
            button to convert it. However, HTML entities representing special characters in the old-style transcripts
            must be manually changed to the actual characters.
          </div>
          <div
            id="guideAccordion"
            class="accordion"
          >
            <div class="accordion-item">
              <div
                id="guideHeader"
                class="accordion-header"
              >
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#guideCollapse"
                  aria-expanded="true"
                  aria-controls="guideCollapse"
                >
                  Transcript Guide
                </button>
              </div>
              <div
                id="guideCollapse"
                class="accordion-collapse collapse"
              >
                <div class="accordion-body smalltext">
                  For the sake of consistency, transcripts should follow a specific format:
                  <ul>
                    <li>There should be an empty line between characters. This serves as a paragraph boundary.</li>
                    <li>
                      Each character should be introduced by their name followed by a colon in bold. Example:
                      "<b>Keith:</b> Some line"
                    </li>
                    <li>
                      Special dialogue details such as language and tone should be specified after the character's name.
                      Example: "<b>Flora:</b> (whispering) Some line"
                    </li>
                    <li>Each visually separated speech bubble should get its own line.</li>
                    <li>Include the same formatting in the transcript as in the comic dialogue.</li>
                    <li>Special characters can be used directly instead of HTML entities.</li>
                    <li>Onomatopoeia should be included on its own line.</li>
                    <li>Narration boxes should be represented as a character named "Narrator".</li>
                    <li>
                      Written text such as signs, notes, and carvings should be included, but wrapped in square brackets.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :id="`teaserTabContent-${idSuffix}`"
          class="tab-pane"
        >
          Teaser Image
          <div class="mb-2 smalltext text-muted">
            The teaser image will display in previews on Twitter, Facebook, and other social media platforms.
            This is not required. If a teaser is not uploaded, the uploaded comic or filler will be used.
            For HTML filler pages, the default is no image.
          </div>
          <div class="mb-4 center">
            <div class="mb-3">
              <croppable-image-upload
                ref="teaserPreviewImg"
                :key="teaserCropperKey"
                :validation-enabled="validationEnabled"
                :crop-auto-crop-area="1"
                :crop-aspect="2"
                :crop-preview-target="$refs.teaserCropPreview"
                :preview-height="300"
                :min-crop-height="teaserMinHeight"
                :min-crop-width="teaserMinWidth"
                :initial-image="teaserImageFileUrl || teaserInitialImage"
                :initial-crop-data="initialTeaserCropData"
                file-type-accept="image/png,image/jpeg"
                @select="handleTeaserFileSelect"
                @image-loaded="handleTeaserImageLoaded"
                @cropped="handleTeaserImageCropped"
                @crop-change="handleTeaserImageCropChange"
                @crop-start="teaserCropping = true"
                @crop-end="teaserCropping = false"
                @reset="handleTeaserReset"
              />
            </div>
            <button
              type="button"
              class="btn btn-outline-secondary me-2"
              @click="$refs.teaserPreviewImg.triggerUploadDialog()"
            >
              <span class="fas fa-file-upload" /> Select Image
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary me-2"
              :disabled="teaserCropping || (!teaserImageFile && !teaserInitialImage)"
              @click="$refs.teaserPreviewImg.changeThumbnail()"
            >
              <span class="fas fa-crop-alt" /> Change Thumbnail
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              :disabled="teaserCropping || (!teaserImageFile && !teaserInitialImage)"
              @click="handleTeaserDelete"
            >
              <span class="fas fa-trash-alt" /> Delete
            </button>
          </div>
          <div class="row gx-3 align-items-center">
            <div class="col">
              <div class="mb-4">
                Cropped Preview
                <div class="text-muted smalltext mb-2">
                  This preview is a rough estimate of how the teaser will appear on social media.
                  Facebook displays up to 1200x628 pixels and Twitter displays up to 1500x500 pixels.
                </div>
              </div>
              <div
                class="mx-auto"
                style="width: 65%;"
              >
                <validation-bullet
                  title="Dimensions"
                  :description="`Min width ${teaserMinWidth}px\nMin height ${teaserMinHeight}px`"
                  :is-valid="(!!teaserInitialImage && !teaserImageFile) || isTeaserValidDimensions"
                  :is-active="!!teaserInitialImage || !!teaserImageFile"
                  class="mb-3"
                />
                <validation-bullet
                  title="Format"
                  description="png, jpg"
                  :is-valid="(!!teaserInitialImage && !teaserImageFile) || isTeaserValidType"
                  :is-active="!!teaserInitialImage || !!teaserImageFile"
                />
              </div>
            </div>
            <div class="col center">
              <div class="thumbnail-area">
                <div
                  ref="teaserCropPreview"
                  class="thumbnail-preview teaser-preview"
                />
              </div>
              <div>{{ teaserDimensionText }}</div>
            </div>
          </div>
        </div>
        <div
          :id="`headerTabContent-${idSuffix}`"
          class="tab-pane"
        >
          <form
            novalidate
            @submit.prevent.stop="submitUpload"
          >
            <div class="mb-3">
              <div class="form-label mb-0">
                Page Header
              </div>
              <div class="smalltext text-muted mb-2">
                HTML entered into this field will display above the comic page image.
              </div>
              <textarea
                v-model="headerHtml"
                class="form-control noresize mono"
                rows="5"
                :class="{ 'is-invalid': validationEnabled && !!headerValidationErrors }"
              />
              <div class="invalid-feedback">
                {{ headerValidationErrors }}
              </div>
            </div>
            <div>
              <div class="form-label mb-0">
                Page Footer
              </div>
              <div class="smalltext text-muted mb-2">
                HTML entered into this field will display below the comic page image.
              </div>
              <textarea
                v-model="footerHtml"
                class="form-control noresize mono"
                rows="5"
                :class="{ 'is-invalid': validationEnabled && !!footerValidationErrors }"
              />
              <div class="invalid-feedback">
                {{ footerValidationErrors }}
              </div>
            </div>
          </form>
        </div>
        <div
          :id="`contentTabContent-${idSuffix}`"
          class="tab-pane"
        >
          <form
            novalidate
            @submit.prevent.stop="submitUpload"
          >
            <div class="mb-3">
              <div class="form-label mb-0 label-required">
                HTML Content
              </div>
              <div class="smalltext text-muted mb-2">
                HTML entered into this field will be displayed in the comic area.
              </div>
              <textarea
                v-model="htmlContent"
                class="form-control noresize mono"
                rows="15"
                :class="{ 'is-invalid': validationEnabled && !!htmlContentValidationErrors }"
              />
              <div class="invalid-feedback">
                {{ htmlContentValidationErrors }}
              </div>
            </div>
            <div>
              <div class="form-label mb-0">
                Additional <span class="mono">&lt;head&gt;</span> Content
              </div>
              <div class="smalltext text-muted mb-2">
                HTML entered into this field will be added to the <span class="mono">&lt;head&gt;</span> block on the page. Use this to add
                any additional CSS or JavaScript that's needed to display the page.
              </div>
              <textarea
                v-model="htmlHead"
                class="form-control noresize mono"
                rows="5"
                :class="{ 'is-invalid': validationEnabled && !!htmlHeadValidationErrors }"
              />
              <div class="invalid-feedback">
                {{ htmlHeadValidationErrors }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>
  </modal-dialog>
</template>

<style lang="scss">
@import "resources/sass/_bs";
@import "@yaireo/tagify/src/tagify";
@import '@vueup/vue-quill/dist/vue-quill.snow.css';
.page-upload {
  .nav-pills {
    .nav-link {
      color: $black;

      &[data-bs-toggle="none"]:not(.active) {
        color: $gray-500;
        cursor: default;
      }

      .tab-validation {
        color: $danger;
        margin-left: 0.3rem;
      }

      &.active .tab-validation {
        color: $white;
      }
    }
  }

  .form-control.tagify {
    padding: 0;
    min-height: 7rem;
    overflow: auto;
    max-width: 100%;
  }

  .ql-editor {
    height: 28em;

    p {
      font-size: 1.2em;
      margin-bottom: 0.35em;
    }
  }

  .thumbnail-area {
    .btn {
      width: 12em;
    }

    .thumbnail-preview {
      border: 1px solid #aaa;
      margin: auto;
      background-color: #ccc;
      overflow: hidden;
    }

    /*
     * HACK: The cropper keeps changing the size of the preview box, so mark it
     * with !important.
     */

    .thumbnail-preview.image-preview {
      width: 100px !important;
      height: 130px !important;
    }

    .thumbnail-preview.teaser-preview {
      width: 450px !important;
      height: 225px !important;
    }
  }
}
</style>

<script>
import BannerStack from '../common/banner-stack.vue';
import LastPublishSummary from './last-publish-summary.vue';
import PublishDetails from './publish-details.vue';
import PreviousPublishesSummary from './previous-publishes-summary.vue';
import Axios from 'axios';
import { nextTick } from 'vue';
export default {
  components: {
    BannerStack,
    LastPublishSummary,
    PreviousPublishesSummary,
    PublishDetails
  },
  emits: {
    publishingStateChanged: null
  },
  data() {
    return {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content'),
      errorLoading: false,
      firstLoad: true,
      publishRuns: [],
      detailsId: null,
      cancelRequested: true
    };
  },
  computed: {
    lastRun() {
      if (!this.$data.publishRuns.length) {
        return null;
      }

      return this.$data.publishRuns[0];
    },
    publishIsRunning() {
      if (this.lastRun == null) {
        return null;
      }

      return !this.lastRun.endTime;
    },
    previousRuns() {
      if (this.$data.publishRuns.length < 2) {
        return null;
      }

      return this.$data.publishRuns.slice(1);
    }
  },
  created() {
    this.loadPublishRuns();
    setInterval(
      function () {
        if (!this.$data.errorLoading) {
          this.loadPublishRuns();
        }
      }.bind(this),
      5000
    );
  },
  methods: {
    reload() {
      this.loadPublishRuns();
    },
    loadPublishRuns() {
      Axios.get('/api/publish/runs')
        .then((response) => {
          this.$data.publishRuns = response.data;
          this.$refs.summaryBanners.clearType('danger');
          this.$data.firstLoad = false;

          this.$emit('publishingStateChanged', this.publishIsRunning);
        })
        .catch((error) => {
          if (this.$data.firstLoad) {
            this.$data.errorLoading = true;
            let errorMessage = "Failed to load publishing summary. Please try reloading the page or try again later.";
            if (error.response && error.response.data.message) {
              errorMessage += ` Message: ${error.response.data.message}`;
            }

            this.$refs.summaryBanners.add({
              class: 'danger',
              dismissible: false,
              message: errorMessage
            });
          }
        });
    },
    async showPublishDetails(id) {
      this.$data.detailsId = id;
      await nextTick();
      this.$refs.publishDetailsDialog.show();
    },
    handleDetailsClosed() {
      this.$data.detailsId = null;
    },
    handleCancel() {
      this.$data.cancelRequested = true;
      Axios.delete('/api/publish/runs/' + this.lastRun.id)
        .then(() => {
          this.loadPublishRuns();
          this.$data.cancelRequested = false;
        })
        .catch((error) => {
          let errorMessage = "Failed to cancel publish.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.summaryBanners.add({
            class: 'danger',
            dismissible: true,
            message: errorMessage,
            timeout: 5000
          });
          this.$data.cancelRequested = false;
        });
    },
    handleDetailDataLoad(data) {
      if (data.id == this.lastRun.id) {
        this.$data.publishRuns[0] = data;
      }
    }
  }
};
</script>

<template>
  <banner-stack ref="summaryBanners" />
  <div
    v-if="!errorLoading"
    class="publish-summary-content"
  >
    <publish-details
      ref="publishDetailsDialog"
      :key="detailsId"
      :publish-id="detailsId"
      :cancel-disabled="cancelRequested"
      @closed="handleDetailsClosed"
      @data-loaded="handleDetailDataLoad"
    />
    <h2 class="mb-2">
      Publishing Summary
    </h2>

    <div
      v-if="!!lastRun"
      class="mb-4"
    >
      <last-publish-summary
        :run="lastRun"
        @details="showPublishDetails"
        @cancel="handleCancel"
      />
    </div>
    <div
      v-else
      class="italic mb-4"
    >
      No publishes have been run yet.
    </div>

    <div v-if="!!previousRuns">
      <h3>Previous Publishes</h3>
      <previous-publishes-summary
        :runs="previousRuns"
        @details="showPublishDetails"
      />
    </div>
  </div>
</template>

<script>
import BannerAlert from './banner-alert.vue';
export default {
  components: {
    BannerAlert
  },
  data() {
    return {
      alerts: []
    };
  },
  methods: {
    add(alertData) {
      this.$data.alerts.push(alertData);
    },
    clear(index) {
      this.$data.alerts.splice(index, 0);
    },
    clearType(classType) {
      this.$data.alerts = this.$data.alerts.filter(x => x.class != classType);
    },
    clearAll() {
      this.$data.alerts = [];
    }
  }
};
</script>

<template>
  <banner-alert
    v-for="(alert, index) in alerts"
    :key="index"
    :alert-class="alert.class"
    :dismissible="alert.dismissible == null ? true : alert.dismissible"
    :timeout="alert.timeout"
    @closed="clear(index)"
  >
    {{ alert.message }}
  </banner-alert>
</template>

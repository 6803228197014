<script>
import ModalDialog from '../common/modal-dialog.vue';
import RegenDialog from './regen-dialog.vue';
import Axios from 'axios';
export default {
  components: {
    ModalDialog,
    RegenDialog
  },
  emits: {
    error: null,
    success: null
  },
  methods: {
    handleInvalidateSessions() {
      this.$refs.invalidateSessionModal.dismiss();
      Axios.delete(`/api/users/session`)
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          let errorMessage = "Failed to clear user sessions. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$emit('error', errorMessage);
        });
    },
    handleRegenerateThumbnails() {
      this.$refs.regenModal.show();
    },
    handleDeleteOrphans() {
      this.$refs.orphanModal.dismiss();
      Axios.delete(`/api/files/orphans`)
        .then((response) => {
          let message = '';
          let messageClass = 'success';
          const total = response.data.total;
          const failures = response.data.failures;
          const successes = total - failures;

          if (total == 0) {
            message = 'No orphaned files were found.';
          }
          else if (response.data.success) {
            message = `${successes} orphaned files were found and removed.`;
          }
          else {
            message = `Some orphaned files could not be removed. Successes: ${successes}, failures: ${failures}.`;
            messageClass = 'warning';
          }

          this.$emit('success', message, messageClass);
        })
        .catch((error) => {
          let errorMessage = "Failed to remove orphaned files. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$emit('error', errorMessage);
        });
    },
  }
};
</script>

<template>
  <div class="danger-zone-settings">
    <modal-dialog
      ref="invalidateSessionModal"
      title="Are you sure?"
      ok-button-text="Invalidate Sessions"
      is-destructive
      enable-clickaway
      @accept="handleInvalidateSessions"
    >
      Are you sure you want to invalidate all login sessions? This will log all users out.
    </modal-dialog>
    <div class="danger-zone">
      <button
        type="button"
        class="btn btn-sm btn-outline-danger mb-3"
        @click="$refs.invalidateSessionModal.show"
      >
        <span class="fas fa-user-times" /> Invalidate All Sessions
      </button><br>
      <regen-dialog ref="regenModal" />
      <button
        type="button"
        class="btn btn-sm btn-outline-danger mb-3"
        @click="handleRegenerateThumbnails"
      >
        <span class="fas fas-alternate fa-images" /> Regenerate Thumbnails
      </button><br>
      <modal-dialog
        ref="orphanModal"
        title="Are you sure?"
        is-destructive
        enable-clickaway
        ok-button-text="Delete"
        @accept="handleDeleteOrphans"
      >
        Are you sure you want to remove orphaned files? This will impact only files that are not attached to
        items in the databse.
      </modal-dialog>
      <button
        type="button"
        class="btn btn-sm btn-outline-danger mb-3"
        @click="$refs.orphanModal.show"
      >
        <span class="fas fa-folder-minus" /> Remove Orphaned Files
      </button>
    </div>
  </div>
</template>

<style lang="scss">
.danger-zone-settings {
  .danger-zone .btn-outline-danger {
    width: 15rem;
  }
}
</style>

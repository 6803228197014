<script>
import BannerStack from '../common/banner-stack.vue';
import ModalDialog from '../common/modal-dialog.vue';
import PublishUnscheduled from './publish-unscheduled.vue';
import Axios from 'axios';
import Convert from 'ansi-to-html';
import DateUtil from '../../shared/dateutil.js';
import { nextTick } from 'vue';
import { Tab } from 'bootstrap';
export default {
  components: {
    BannerStack,
    ModalDialog,
    PublishUnscheduled
  },
  props: {
    publishId: {
      type: Number,
      default: null
    }
  },
  emits: {
    closed: null,
    dataLoaded: null
  },
  data() {
    return {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content'),
      publishInfo: null,
      firstLoad: true,
      reloadTimer: null,
      cancelRequested: false
    };
  },
  computed: {
    publishItems() {
      let items = this.publishInfo.items;
      return {
        'incentive': this.splitToChangeTypes(items.filter(x => x.itemType == 'incentive')),
        'dailySketch': this.splitToChangeTypes(items.filter(x => x.itemType == 'sketch')),
        'chapter': this.splitToChangeTypes(items.filter(x => x.itemType == 'chapter')),
        'fanart': this.splitToChangeTypes(items.filter(x => x.itemType == 'fanart')),
        'comic': this.splitToChangeTypes(items.filter(x => x.itemType == 'page'))
      };
    },
    isRunning() {
      return !this.publishInfo?.endTime;
    },
    startTime() {
      return this.formatDate(this.publishInfo.startTime);
    },
    endTime() {
      return this.formatDate(this.publishInfo.endTime);
    },
    summaryStatusIcon() {
      if (this.isRunning && !this.publishInfo.cancelRequested) {
        return ['fa-sync', 'text-primary', 'animate'];
      }
      else if (this.isRunning) {
        return ['fa-sync', 'text-muted', 'animate'];
      }
      else if (!this.publishInfo.isSuccess && !this.publishInfo.canceled) {
        return ['fa-times-circle', 'text-danger'];
      }
      else if (!this.publishInfo.isSuccess) {
        return ['fa-minus-circle', 'text-muted'];
      }
      else {
        return ['fa-check-circle', 'text-success'];
      }
    },
    summaryText() {
      if (this.isRunning && !this.publishInfo.cancelRequested) {
        return 'Currently running…';
      }
      else if (this.isRunning) {
        return 'Canceling…';
      }
      else if (!this.publishInfo.isSuccess && !this.publishInfo.canceled) {
        return `Failed`;
      }
      else if (!this.publishInfo.isSuccess) {
        return `Canceled`;
      }
      else {
        return `Succeeded`;
      }
    },
    summaryDuration() {
      let endTime = this.publishInfo?.endTime;
      return DateUtil.getTimeDifference(this.publishInfo.startTime, endTime);
    },
    summaryInitiatorText() {
      if (!this.publishInfo.startedBy) {
        return 'Started automatically';
      }

      return `Started by ${this.publishInfo.startedBy}`;
    },
    publishLog() {
      const convert = new Convert({
        colors: {
          0: '#292C34',
          1: '#D27278',
          2: '#A1C181',
          3: '#DFC185',
          4: '#74ADEA',
          5: '#BC7BD8',
          6: '#70B4C0',
          7: '#DDDFE4'
        }
      });
      const logWithBreaks = this.publishInfo.log.replace(/\r?\n/, '<br>\n');
      const logWithColor = convert.toHtml(logWithBreaks);
      return logWithColor;
    }
  },
  created() {
    this.loadPublishInfo();
    this.$data.reloadTimer = setInterval(
      function () {
        this.loadPublishInfo();
      }.bind(this),
      1000
    );
  },
  beforeUnmount() {
    if (this.$data.reloadTimer) {
      clearInterval(this.$data.reloadTimer);
    }
  },
  methods: {
    splitToChangeTypes(changeItems) {
      return {
        'add': changeItems.filter(x => x.changeType == 'add'),
        'modify': changeItems.filter(x => x.changeType == 'modify'),
        'remove': changeItems.filter(x => x.changeType == 'delete')
      };
    },
    formatDate(date) {
      const formattedDate = DateUtil.getUsDateString(date, this.timeZonePreference, true);
      const formattedTime = DateUtil.getUsTimeString(date, this.timeZonePreference, true);
      return `${formattedDate} at ${formattedTime}`;
    },
    resetLogScroll() {
      const logContainer = document.querySelector('.logs-tab');

      if (!logContainer) {
        return;
      }

      // Give some time for the browser to set the scroll where it thinks it should.
      setTimeout(
        function () {
          logContainer.scrollTop = 0;
        },
        50
      );
    },
    shouldLogScrollToBottom() {
      const logContainer = document.querySelector('.logs-tab');

      if (!logContainer) {
        return false;
      }

      return logContainer.scrollHeight - logContainer.scrollTop - logContainer.clientHeight < 1 &&
        this.isRunning;
    },
    scrollLogsToBottom() {
      const logContainer = document.querySelector('.logs-tab');

      if (!logContainer) {
        return;
      }

      logContainer.scrollTop = logContainer.scrollHeight - logContainer.clientHeight;
    },
    async show() {
      this.$refs.modal.show();
    },
    handleClose() {
      var tab = new Tab(this.$refs.defaultTab);
      tab.show();
      this.$refs.modal.dismiss();
    },
    loadPublishInfo() {
      if (!this.publishId && this.publishId !== 0) {
        this.$data.publishInfo = null;
        return;
      }

      if (!this.$data.firstLoad && !this.isRunning) {
        return;
      }

      Axios.get(`/api/publish/runs/${this.publishId}`)
        .then(async (response) => {
          let shouldScrollDown = this.shouldLogScrollToBottom();
          this.$data.publishInfo = response.data;
          this.$refs.publishDetailBanners.clearType('danger');

          await nextTick();
          if (this.$data.firstLoad) {
            this.resetLogScroll();
          }
          else if (shouldScrollDown) {
            this.scrollLogsToBottom();
          }

          this.$data.firstLoad = false;
          this.$emit('dataLoaded', this.$data.publishInfo);
        })
        .catch((error) => {
          if (this.$data.firstLoad) {
            this.$data.errorLoading = true;
            let errorMessage = "Failed to load publishing details. Please try again later.";
            if (error.response && error.response.data.message) {
              errorMessage += ` Message: ${error.response.data.message}`;
            }

            this.$refs.publishDetailBanners.clearType('danger');
            this.$refs.publishDetailBanners.add({
              class: 'danger',
              message: errorMessage
            });
          }
        });
    },
    handleCancel() {
      this.$data.cancelRequested = true;
      Axios.delete('/api/publish/runs/' + this.publishInfo.id)
        .then(() => {
          this.$data.cancelRequested = false;
        })
        .catch((error) => {
          let errorMessage = "Failed to cancel publish.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.publishDetailBanners.add({
            class: 'danger',
            dismissible: true,
            message: errorMessage,
            timeout: 5000
          });
          this.$data.cancelRequested = false;
        });
    }
  }
};
</script>

<template>
  <div class="publish-details-wrapper">
    <modal-dialog
      ref="modal"
      :show-dismiss-button="false"
      :enable-clickaway="true"
      title="Publish Details"
      ok-button-text="Close"
      size-large
      @accept="handleClose"
      @closed="$emit('closed')"
    >
      <banner-stack ref="publishDetailBanners" />
      <div v-if="publishInfo">
        <div class="mb-3">
          <div class="summary-heading">
            <span
              class="fas"
              :class="summaryStatusIcon"
            /> <b>{{ summaryText }}</b>
          </div>
          {{ summaryInitiatorText }} on {{ startTime }}<br>
          <template v-if="isRunning">
            Running for {{ summaryDuration }}
          </template>
          <template v-else>
            Ran for <span :title="`Completed on ${endTime}`">{{ summaryDuration }}</span>
          </template>

          <div>
            <button
              v-if="isRunning"
              :disabled="cancelRequested || !publishInfo.canCancel || publishInfo.cancelRequested"
              type="button"
              class="btn btn-danger mt-2"
              @click="handleCancel"
            >
              Cancel
            </button>
          </div>
        </div>

        <ul
          class="nav nav-pills nav-justified"
          role="tablist"
        >
          <li class="nav-item">
            <button
              ref="defaultTab"
              class="nav-link active"
              type="button"
              role="tab"
              data-bs-toggle="tab"
              data-bs-target="#itemsTab"
            >
              Changes
            </button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link"
              type="button"
              role="tab"
              data-bs-toggle="tab"
              data-bs-target="#logsTab"
            >
              Log
            </button>
          </li>
        </ul>
        <div class="details-pane tab-content">
          <div
            id="itemsTab"
            class="tab-pane items-tab active"
            role="tabpanel"
          >
            <publish-unscheduled
              v-if="!!publishInfo.items.length"
              :changes="publishItems"
              disable-links
            />
            <div
              v-else
              class="italic center pt-3"
            >
              No changes are associated with this publish run.
            </div>
          </div>
          <div
            id="logsTab"
            class="tab-pane logs-tab"
            role="tabpanel"
          >
            <div
              class="log"
              v-html="publishLog"
            />
          </div>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<style lang="scss">
  @import "resources/sass/_bs";
  .publish-details-wrapper {
    @keyframes spin {
      from {
        transform:rotate(0deg);
      }
      to {
        transform:rotate(360deg);
      }
    }
    .animate {
      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .summary-heading {
      font-size: 1.4em;
    }

    [title] {
      text-decoration: underline;
      text-decoration-style: dotted;
    }

    ul.nav {
      border: 1px solid $gray-400;
      border-bottom: none;
      border-radius: 5px 5px 0 0;
      padding: 0.5rem;
      margin-bottom: 0;
    }

    button.nav-link {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
    }

    .details-pane {
      border: 1px solid $gray-400;

      .tab-pane {
        height: 25rem;
        padding: 0.5rem;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .items-tab {
        background-color: $gray-100;
        padding: 1rem;
      }

      .logs-tab {
        background-color: #292C34;
        color: #DDDFE4;

        .log {
          font-family: 'Andale Mono', 'Courier New', Courier, monospace;
          white-space: pre-wrap;
          font-size: 0.8em;
        }
      }
    }
  }
</style>

<script>
import ModalDialog from '../common/modal-dialog.vue';

export default {
  components: {
    ModalDialog
  },
  props: {
    username: {
      type: String,
      default: null
    },
    publicKey: {
      type: String,
      default: null
    }
  },
  computed: {
    isUserSet() {
      return !!this.username && this.username.trim() !== '';
    },
    userDir() {
      return this.isUserSet ? this.username.trim() : '[user]';
    },
    isKeySet() {
      return !!this.publicKey && this.publicKey.trim() !== '';
    }
  },
  methods: {
    show() {
      this.$refs.viewerDialog.show();
    },
    dismiss() {
      this.$refs.viewerDialog.dismiss();
    },
    copyKey() {
      navigator
        .clipboard
        .writeText(this.publicKey)
        .catch(
          function (err) {
            console.log('Failed to copy text to clipboard', err);
          }
        );
    }
  }
};
</script>

<template>
  <div class="key-viewer-dialog-wrapper">
    <modal-dialog
      ref="viewerDialog"
      title="View Public Key"
      :show-dismiss-button="false"
      ok-button-text="Close"
      size-large
      @accept="dismiss"
    >
      <template v-if="isKeySet">
        <p>
          The SSH public key is shown below. To enable publishing, log into the destination server and add the key
          to the bottom of the <span class="mono">/home/{{ userDir }}/.ssh/authorized_keys</span>
          file<span v-if="!isUserSet">, replacing <span class="mono">[user]</span> with the actual user that owns the
            destination directory</span>. If the file does not already exist, create it.
        </p>
        <div
          class="key-viewer mb-3"
          @focus="$event.target.select()"
        >
          {{ publicKey }}
        </div>

        <button
          type="button"
          class="btn btn-secondary"
          @click="copyKey"
        >
          <span class="fas fa-clipboard" /> Copy to Clipboard
        </button>
      </template>
      <p
        v-else
        class="italic"
      >
        No public key has been generated yet.
      </p>
    </modal-dialog>
  </div>
</template>

<style lang="scss">
  @import "resources/sass/_bs";
  .key-viewer-dialog-wrapper {
    .key-viewer {
      font-family: monospace;
      border: 1px solid $gray-400;
      border-radius: 5px;
      background-color: $gray-100;
      padding: 0.5rem;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
</style>

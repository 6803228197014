<script>
import ChangeList from './change-list.vue';
export default {
  components: {
    ChangeList
  },
  props: {
    changes: {
      type: Object,
      required: true
    },
    renderContainer: {
      type: Boolean,
      default: true
    },
    disableLinks: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    incentiveChanges() {
      return ('incentive' in this.changes)
        ? this.changes.incentive
        : null;
    },
    dailySketchChanges() {
      return ('dailySketch' in this.changes)
        ? this.changes.dailySketch
        : null;
    },
    chapterChanges() {
      return ('chapter' in this.changes)
        ? this.changes.chapter
        : null;
    },
    fanartChanges() {
      return ('fanart' in this.changes)
        ? this.changes.fanart
        : null;
    },
    comicChanges() {
      return ('comic' in this.changes)
        ? this.changes.comic
        : null;
    }
  }
};
</script>

<template>
  <div
    :class="{ 'publish-wrapper': renderContainer }"
    class="unscheduled-publish-block"
  >
    <ul class="change-sections">
      <li
        v-if="!!incentiveChanges.add.length || !!incentiveChanges.modify.length || !!incentiveChanges.remove.length"
        class="change-section"
      >
        <div class="dash-heading">
          Incentive
        </div>
        <change-list
          :changes="incentiveChanges"
          format="incentive image"
        />
      </li>
      <li
        v-if="!!dailySketchChanges.add.length || !!dailySketchChanges.modify.length || !!dailySketchChanges.remove.length"
        class="change-section"
      >
        <div class="dash-heading">
          Daily Sketch Links
        </div>
        <change-list
          :changes="dailySketchChanges"
          :count-only="disableLinks"
          format="%l"
        />
      </li>
      <li
        v-if="!!chapterChanges.add.length || !!chapterChanges.modify.length || !!chapterChanges.remove.length"
        class="change-section"
      >
        <div class="dash-heading">
          Chapters
        </div>
        <change-list
          :changes="chapterChanges"
          format="chapter &quot;%t&quot;"
        />
      </li>
      <li
        v-if="!!fanartChanges.add.length || !!fanartChanges.modify.length || !!fanartChanges.remove.length"
        class="change-section"
      >
        <div class="dash-heading">
          Fanart
        </div>
        <change-list
          count-only
          :changes="fanartChanges"
        />
      </li>
      <li
        v-if="!!comicChanges.add.length || !!comicChanges.modify.length || !!comicChanges.remove.length"
        class="change-section"
      >
        <div class="dash-heading">
          Pages
        </div>
        <change-list
          :changes="comicChanges"
          format="page &quot;%t&quot;"
        />
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.unscheduled-publish-block {
  .publish-wrapper {
    border-radius: 4px;
    background-color: $gray-100;
    border: 1px solid $gray-400;
    padding: 1rem;
    max-width: 45rem;
    margin: 0 0.5rem;
  }

  .dash-heading {
    font-weight: bold;
    margin-bottom: 0.4rem;
  }

  ul.change-sections {
    padding-left: 0;
    margin-bottom: 0;
  }

  .change-section {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray-400;
    list-style-type: none;
    max-width: 45rem;
  }

  .change-section:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
</style>

<script>
import NewUserDialog from './new-user-dialog.vue';
import UserEntry from './user-entry.vue';
import Axios from 'axios';
export default {
  components: {
    UserEntry,
    NewUserDialog
  },
  props: {
    currentUserId: {
      type: Number,
      default: null
    }
  },
  emits: {
    error: null,
    success: null
  },
  data() {
    return {
      errorLoading: false,
      users: []
    };
  },
  created() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      Axios.get('/api/users')
        .then((response) => {
          this.$data.users = response.data;
        })
        .catch((error) => {
          console.error(error);

          this.$data.errorLoading = true;
          let errorMessage = "Failed to load user list. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$emit('error', errorMessage);
        });
    },
    isCurrentUser(user) {
      return user.id == this.currentUserId;
    },
    handleUserDelete(index) {
      let userId = this.$data.users[index].id;
      Axios.delete(`/api/user/${userId}`)
        .then(() => {
          this.$data.users.splice(index, 1);
          this.$emit('success', 'User deleted.');
        })
        .catch((error) => {
          let errorMessage = "Failed to delete user. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$emit('error', errorMessage);
        });
    },
    handleUserPromote(index) {
      this.setUserAdmin(index, true);
    },
    handleUserDemote(index) {
      this.setUserAdmin(index, false);
    },
    setUserAdmin(index, isAdmin) {
      let userId = this.$data.users[index].id;
      Axios.put(`/api/user/${userId}/admin?value=${isAdmin}`)
        .then(() => {
          this.$data.users[index].isAdmin = isAdmin;
          this.$emit('success', 'User role updated.');
        })
        .catch((error) => {
          let errorMessage = "Failed to update user role. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$emit('error', errorMessage);
        });
    },
    handleNewUser() {
      this.$emit('success', 'User added successfully.');
      this.loadUsers();
    }
  }
};
</script>

<template>
  <div class="authorized-user-settings">
    <div v-if="!errorLoading">
      <div class="mb-4">
        <div class="user-list mb-3">
          <user-entry
            v-for="(user, index) in users"
            :key="index"
            :user="user"
            :is-current-user="isCurrentUser(user)"
            @delete="handleUserDelete(index)"
            @promote="handleUserPromote(index)"
            @demote="handleUserDemote(index)"
          />
        </div>
        <new-user-dialog
          ref="newUserDialog"
          @create="handleNewUser"
        />
        <button
          type="button"
          class="btn btn-sm btn-outline-secondary"
          @click="$refs.newUserDialog.showModal"
        >
          <span class="fas fa-plus" /> Add User
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BannerStack from '../common/banner-stack.vue';
import ModalDialog from '../common/modal-dialog.vue';
import Axios from 'axios';

export default {
  components: {
    BannerStack,
    ModalDialog
  },
  props: {
    keyExists: {
      type: Boolean,
      default: true
    }
  },
  emits: {
    keyChanged: null
  },
  data() {
    return {
      waitingForRequest: false
    };
  },
  methods: {
    show() {
      this.$refs.regenDialog.show();
    },
    dismiss() {
      this.$refs.regenDialog.dismiss();
    },
    handleRegenKey() {
      if (this.$data.waitingForRequest) {
        return;
      }

      this.$refs.banners.clearType('danger');

      this.$data.waitingForRequest = true;
      Axios.put(`/api/settings/publish/key`)
        .then((response) => {
          this.$emit('keyChanged', response.data.publicKey);
        })
        .catch((error) => {
          let errorMessage = "Failed to generate SSH key. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.banners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        })
        .then(() => {
          this.$data.waitingForRequest = false;
        });
    }
  }
};
</script>

<template>
  <div class="regen-dialog-wrapper">
    <modal-dialog
      ref="regenDialog"
      :is-waiting="waitingForRequest"
      :title="(keyExists ? 'Regenerate' : 'Generate') + ' SSH Key'"
      :ok-button-text="keyExists ? 'Regenerate' : 'Generate'"
      :is-destructive="keyExists"
      @accept="handleRegenKey"
    >
      <banner-stack ref="banners" />
      <template v-if="keyExists">
        <p>
          Are you sure you want to regenerate the SSH key used to authenticate with the destination server? Doing so
          will cause future publishes to fail until the new public key is enabled on the server.
        </p>
        <p>
          <b>This operation cannot be undone and the existing key will be lost.</b>
        </p>
      </template>
      <p v-else>
        Are you sure you want to generate a new SSH key?
      </p>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '../common/modal-dialog.vue';
import HtmlUtil from '../../shared/htmlutil.js';
export default {
  components: {
    ModalDialog
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    selected: Boolean,
    enableDrag: Boolean,
    preventEdit: Boolean,
  },
  emits: {
    edit: null,
    delete: null
  },
  computed: {
    unsaved() {
      return this.id === null;
    }
  },
  methods: {
    showDeleteModal() {
      this.$refs.deleteModal.show();
    },
    emitEditEvent() {
      if (!this.unsaved && !this.selected && !this.preventEdit) {
        this.$emit('edit');
      }
    },
    handleDeleteModalAccept() {
      this.$refs.deleteModal.dismiss();
      this.$emit('delete');
    },
    decodeEntities(value) {
      return HtmlUtil.decodeHtmlEntities(value);
    }
  }
};
</script>

<template>
  <div class="chapter-tab">
    <modal-dialog
      ref="deleteModal"
      title="Confirm Delete"
      ok-button-text="Delete"
      is-destructive
      enable-clickaway
      @accept="handleDeleteModalAccept"
    >
      <p>
        Are you sure you want to remove the chapter "{{ title || 'Untitled chapter' }}"?
        Pages in this chapter will no longer have a chapter assigned.
      </p>
    </modal-dialog>
    <div
      class="chapter"
      :class="{ active: selected, new: unsaved }"
      @click="emitEditEvent()"
    >
      <div class="row gx-2">
        <div
          class="col-auto drag-handle"
          :class="{ disabled: !enableDrag }"
          @click.stop
        >
          <span class="fas fa-grip-vertical" />
        </div>
        <div class="col">
          {{ decodeEntities(title) || 'Untitled chapter' }}
        </div>
        <div
          v-if="!unsaved"
          class="col-auto delete"
          @click.stop="showDeleteModal"
        >
          <span class="fas fa-trash-alt" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.chapter-tab {
  .chapter {
    padding: 0.3rem 0.5rem;
    border-radius: 3px;
    border: 1px solid $blue-200;
    margin-bottom: 0.5rem;
    cursor: pointer;

    &.active:not(.new) {
      border-color: $blue-400;
      background-color: $blue-400;
      color: $white;
      cursor: unset;

      .drag-handle {
        color: $blue-200;

        &:hover {
          color: $white;
        }
      }

      .delete {
        visibility: visible;
        color: $blue-200;

        &:hover {
          color: $red-200;
        }
      }

      &:hover .edit {
        visibility: hidden;
      }
    }

    &.new {
      background-color: $white;
      border: 2px dashed $blue-200;
      cursor: unset;
    }

    .edit {
      visibility: hidden;
      color: $gray-500;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }

    .delete {
      visibility: hidden;
      color: $gray-500;
      cursor: pointer;

      &:hover {
        color: $danger;
      }
    }

    &:hover {
      .edit, .delete {
        visibility: visible;
      }
    }

    .drag-handle {
      color: $blue-300;
      cursor: grab;

      &:hover {
        color: $blue-500;
      }

      &:active {
        cursor: grabbing;
      }

      &.disabled, &.disabled:hover, &.disabled:active {
        cursor: default;
        color: $gray-400;
      }
    }
  }
}
</style>

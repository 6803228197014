<script>
import BannerStack from '../common/banner-stack.vue';
import UserSettings from './user-settings.vue';
import AdminSettings from './admin-settings.vue';
import Axios from 'axios';
export default {
  components: {
    UserSettings,
    AdminSettings,
    BannerStack
  },
  data() {
    return {
      errorLoading: false,
      loggedInUser: null,
      isReady: false
    };
  },
  created() {
    this.loadUserInfo();
  },
  methods: {
    loadUserInfo() {
      Axios.get('/api/user')
        .then((response) => {
          this.$data.loggedInUser = response.data;
          this.$refs.mainBanners.clearType('danger');
        })
        .catch((error) => {
          console.error(error);

          this.$data.errorLoading = true;
          let errorMessage = "Failed to load settings. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        })
        .then(() => {
          this.$data.isReady = true;
        });
    }
  }
};
</script>

<template>
  <div class="settings-content">
    <banner-stack ref="mainBanners" />
    <div
      v-if="!errorLoading && isReady"
      class="row"
    >
      <div class="settings-nav col-2">
        <ul class="nav nav-pills nav-fill flex-column">
          <li class="nav-item">
            <button
              class="nav-link active"
              type="button"
              role="tab"
              data-bs-toggle="tab"
              data-bs-target="#prefTab"
            >
              User Preferences
            </button>
          </li>
          <li
            v-if="loggedInUser?.isAdmin"
            class="nav-item"
          >
            <button
              class="nav-link"
              type="button"
              role="tab"
              data-bs-toggle="tab"
              data-bs-target="#adminTab"
            >
              Site Settings
            </button>
          </li>
        </ul>
      </div>
      <div class="tab-content col">
        <div
          id="prefTab"
          class="tab-pane active"
        >
          <user-settings :user-info="loggedInUser" />
        </div>

        <div
          v-if="loggedInUser?.isAdmin"
          id="adminTab"
          class="tab-pane"
        >
          <admin-settings :current-user-id="loggedInUser.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.settings-content {
  .settings-nav {
    border-right: 1px solid $gray-400;
    padding: 0 1rem;
    min-width: 14em;
    .nav-item {
      margin-bottom: 0.3rem;
    }
  }
  .tab-content {
    padding: 0 1rem 0 2rem;
  }
}
</style>

import { DateTime } from 'luxon';
export default {
  getUsDateString: (isoDate, zone) => new Date(isoDate).toLocaleDateString(
    'en-us',
    {
      timeZone: zone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  ),

  getUsTimeString: (isoDate, zone, includeSeconds = false) => {
    let format = {
      timeZone: zone,
      hour: 'numeric',
      hour12: true,
      minute: '2-digit',
      timeZoneName: 'short'
    };

    if (includeSeconds) {
      format.second = 'numeric';
    }

    return new Date(isoDate).toLocaleTimeString('en-us', format);
  },

  getLongUsDateString: (isoDate, zone) => {
    const specifiedDate = DateTime.fromISO(isoDate, { zone: zone });
    const currentDate = DateTime.now({ zone: zone });

    let format = {
      timeZone: zone,
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    };

    if (specifiedDate.year != currentDate.year) {
      format.year = 'numeric';
    }

    return new Date(isoDate).toLocaleDateString('en-us', format);
  },

  getTimeZoneShortName: (isoDate, zone) => new Date(isoDate).toLocaleDateString(
    'en-us',
    {
      day: '2-digit',
      timeZone: zone,
      timeZoneName: 'short'
    }
  ).substring(4),

  getConvertedIsoStringMidnight: (isoDate, zone) => {
    let convertedDate = DateTime.fromISO(isoDate, { zone: zone });
    convertedDate = convertedDate.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    return convertedDate.toISO();
  },

  getTimeDifference: (startIsoDate, endIsoDate = null) => {
    const startDate = DateTime.fromISO(startIsoDate);
    const endDate = endIsoDate !== null ? DateTime.fromISO(endIsoDate) : DateTime.now();

    const timeDiff = endDate.diff(startDate, ['hours', 'minutes', 'seconds']);
    let hours = Math.abs(Math.round(timeDiff.hours));
    let minutes = Math.abs(Math.round(timeDiff.minutes));
    let seconds = Math.abs(Math.round(timeDiff.seconds));

    if (hours === 0 && minutes === 0 && seconds === 0) {
      return '0 seconds';
    }

    let durationStrings = [];
    if (hours > 1) {
      durationStrings.push(`${hours} hours`);
    }
    else if (hours == 1) {
      durationStrings.push(`${hours} hour`);
    }

    if (minutes > 1) {
      durationStrings.push(`${minutes} minutes`);
    }
    else if (minutes == 1) {
      durationStrings.push(`${minutes} minute`);
    }

    if (seconds > 1) {
      durationStrings.push(`${seconds} seconds`);
    }
    else if (seconds == 1) {
      durationStrings.push(`${seconds} second`);
    }

    return durationStrings.join(', ');
  }
};

<script>
import FanartEntry from './fanart-entry.vue';
import UploadDialog from './upload-dialog.vue';
import BannerStack from '../common/banner-stack.vue';
import Axios from 'axios';
import { nextTick } from 'vue';
export default {
  components: {
    FanartEntry,
    UploadDialog,
    BannerStack
  },
  props: {
    hasPendingChanges: Boolean
  },
  data() {
    return {
      errorLoading: false,
      fanarts: [],
      changesPendingFlag: this.hasPendingChanges,
      editingFanart: null,
      loadingDialog: false
    };
  },
  created() {
    this.loadFanart();
  },
  methods: {
    async showUploadDialog() {
      await nextTick();
      this.$refs.uploadDialog.showModal();
    },
    handleDelete(index) {
      let id = this.$data.fanarts[index].id;

      Axios.delete(`/api/fanart/${id}`)
        .then(() => {
          this.$refs.mainBanners.clearType('danger');
          this.$data.fanarts.splice(index, 1);
          this.$data.changesPendingFlag = true;
        })
        .catch((error) => {
          let errorMessage = "Failed to delete fanart. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            timeout: 5000,
            message: errorMessage
          });
        });
    },
    handleAddButton() {
      this.$data.editingFanart = null;
      this.showUploadDialog();
    },
    handleEditButton(index) {
      this.$data.loadingDialog = true;
      this.reloadFanart(index);
    },
    handleEditReloadCallback(index, success) {
      this.$data.loadingDialog = false;

      if (!success) {
        return;
      }

      this.$data.editingFanart = this.$data.fanarts[index];
      this.showUploadDialog();
    },
    handleDialogComplete() {
      this.$refs.mainBanners.add({
        class: 'success',
        timeout: 5000,
        message: `Fanart ${this.$data.editingFanart ? 'updated' : 'created'} successfully.`
      });

      this.loadFanart();
      this.$data.changesPendingFlag = true;
    },
    loadFanart() {
      Axios.get('/api/fanart')
        .then((response) => {
          this.$data.fanarts = response.data;
          this.$refs.mainBanners.clearType('danger');
        })
        .catch((error) => {
          this.$data.errorLoading = true;
          let errorMessage = "Failed to load fanart data. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        });
    },
    reloadFanart(index) {
      let fanartToReload = this.$data.fanarts[index];
      Axios.get('/api/fanart/' + fanartToReload.id)
        .then((response) => {
          this.$data.fanarts[index] = response.data;
          this.$refs.mainBanners.clearType('danger');
          this.handleEditReloadCallback(index, true);
        })
        .catch((error) => {
          this.$data.errorLoading = true;
          let errorMessage = "Failed to load fanart data. Please try reloading the page or try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.mainBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });

          this.handleEditReloadCallback(index, false);
        });
    }
  }
};
</script>

<template>
  <div class="fanart-content-wrapper">
    <h1>Fanart Management</h1>
    <banner-stack ref="mainBanners" />
    <div
      v-if="changesPendingFlag"
      class="alert alert-info"
    >
      Some changes to fanart have not yet been published.
      <a
        href="/publishing"
        class="alert-link"
      >Go to Publishing</a>
      to deploy them.
    </div>
    <button
      type="button"
      class="btn btn-primary mb-3"
      @click="showUploadDialog"
    >
      <span class="fas fa-plus" /> New Fanart
    </button>
    <upload-dialog
      ref="uploadDialog"
      :key="editingFanart"
      :initial-fanart-data="editingFanart"
      @upload="handleDialogComplete"
    />
    <div
      v-if="fanarts.length"
      class="row gx-3"
    >
      <div
        v-for="(fanart, index) in fanarts"
        :key="index"
        class="col-auto"
      >
        <fanart-entry
          :fanart-data="fanart"
          :buttons-disabled="loadingDialog"
          @delete="handleDelete(index)"
          @edit="handleEditButton(index)"
        />
      </div>
    </div>
    <div
      v-else
      class="text-muted italic"
    >
      No fanart has been added. Click "New Fanart" to add one.
    </div>
  </div>
</template>

<script>
import ModalDialog from '../common/modal-dialog.vue';
import UploadDialog from './upload-dialog.vue';
export default {
  components: {
    UploadDialog,
    ModalDialog
  },
  props: {
    incentiveData: {
      type: Object,
      default: () => {}
    },
    isCurrent: Boolean
  },
  emits: {
    upload: null,
    delete: null
  },
  computed: {
    altText() {
      return `${this.isCurrent ? 'Current' : 'Queued'} incentive image`;
    }
  },
  methods: {
    showQueueModal() {
      this.$refs.dialog.showModal();
    },
    showDeleteModal() {
      this.$refs.deleteModal.show();
    },
    acceptDelete() {
      this.$emit('delete');
      this.$refs.deleteModal.dismiss();
    }
  }
};
</script>

<template>
  <div class="incentive-entry-wrapper">
    <div
      :class="{ current: isCurrent }"
      class="row gx-0 incentive-entry"
    >
      <img
        v-if="incentiveData"
        :src="incentiveData.imageUrl"
        :alt="altText"
        class="col-auto mr-3"
      >
      <div class="col">
        <template v-if="!incentiveData">
          <div class="mb-2">
            No incentive image is currently set.
          </div>
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            @click="showQueueModal"
          >
            <span class="fas fa-plus" /> Queue New
          </button>
        </template>
        <template v-else-if="isCurrent">
          <div class="heading">
            <span class="fas fa-check-circle" /> Currently Live
          </div>
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            @click="showQueueModal"
          >
            <span class="fas fa-plus" /> Queue New
          </button>
        </template>
        <template v-else>
          <div class="heading">
            Queued for Next Publish
          </div>
          <button
            type="button"
            class="btn btn-sm btn-outline-danger"
            @click="showDeleteModal"
          >
            <span class="fas fa-trash-alt" /> Delete
          </button>
        </template>
      </div>
    </div>
    <upload-dialog
      v-if="isCurrent"
      ref="dialog"
      @upload="$emit('upload')"
    />
    <modal-dialog
      v-else
      ref="deleteModal"
      title="Confirm Delete"
      ok-button-text="Delete"
      is-destructive
      enable-clickaway
      @accept="acceptDelete"
    >
      Are you sure you want to remove the queued incentive image?
    </modal-dialog>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.incentive-entry-wrapper {
  .incentive-entry {
    padding: 1rem;
    background-color: $gray-100;
    border: 1px solid $gray-400;
    border-radius: 3px;
    margin-bottom: 1rem;

    img {
      margin-right: 1rem;
    }

    .heading {
      color: $secondary;
      font-weight: bold;
      margin-bottom: 0.6rem;
      font-size: 1.1em;
    }

    &.current {
      .heading {
        color: $green-500;
      }
    }
  }
}
</style>

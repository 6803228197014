<script>
import DateUtil from '../../shared/dateutil.js';
export default {
  props: {
    changes: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content')
    };
  },
  methods: {
    getDateString(isoDate) {
      return DateUtil.getLongUsDateString(isoDate, this.$data.timeZonePreference);
    },
    getTimeString(isoDate) {
      return DateUtil.getUsTimeString(isoDate, this.$data.timeZonePreference);
    }
  }
};
</script>

<template>
  <div class="scheduled-publish-block">
    <div
      v-for="dateKey in Object.keys(changes)"
      :key="dateKey"
      class="change-section"
    >
      <div class="dash-heading">
        {{ getDateString(dateKey) }}
      </div>
      <ul>
        <li
          v-for="change in changes[dateKey]"
          :key="change"
        >
          {{ change.type }}:
          <template v-if="change.link">
            <a :href="change.link">{{ change.title }}</a>
          </template>
          <template v-else>
            {{ change.title }}
          </template>
          <span class="text-muted">
            &ndash;
            <span class="fas fa-clock fas-alternate" />
            {{ getTimeString(change.date) }}
          </span>
        </li>
      </ul>
    </div>
    <div
      v-if="Object.keys(changes).length === 0"
      class="smalltext italic"
    >
      No changes scheduled.
    </div>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.scheduled-publish-block {
  border-radius: 3px;
  background-color: $gray-100;
  border: 1px solid $gray-400;
  padding: 1rem;
  max-width: 45rem;
  margin: 0 0.5rem;

  .dash-heading {
    font-weight: bold;
    margin-bottom: 0.4rem;
  }

  ul {
    margin-bottom: 0;
  }

  .change-section {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $gray-400;
  }
  .change-section:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
  }
}
</style>

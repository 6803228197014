<script>
import BannerStack from '../common/banner-stack.vue';
import CroppableImageUpload from '../common/croppable-image-upload.vue';
import ModalDialog from '../common/modal-dialog.vue';
import ObjectUtil from '../../shared/objectutil.js';
import HtmlUtil from '../../shared/htmlutil.js';
import Axios from 'axios';
import { DatePicker } from 'v-calendar';
export default {
  components: {
    ModalDialog,
    DatePicker,
    CroppableImageUpload,
    BannerStack
  },
  props: {
    initialFanartData: {
      type: Object,
      default: () => {}
    }
  },
  emits: {
    upload: null
  },
  data() {
    var data = {
      cropperKey: Math.round(Math.random() * 1000)
    };
    Object.assign(data, this.initialData());
    return data;
  },
  computed: {
    initialCropData() {
      if (!this.initialFanartData) {
        return null;
      }

      return {
        'x': this.roundDecimal(this.initialFanartData.imageCrop.x),
        'y': this.roundDecimal(this.initialFanartData.imageCrop.y),
        'width': this.initialFanartData.imageCrop.width,
        'height': this.initialFanartData.imageCrop.height
      };
    },
    isEditing() {
      return !!this.initialFanartData;
    },
    modalHeaderText() {
      if (this.isEditing) {
        return "Edit Fanart";
      }
      return "Add Fanart";
    },
    modalAcceptText() {
      if (this.isEditing) {
        return "Save";
      }
      return "Queue for Publishing";
    },
    uploadValidationErrors() {
      if (this.isEditing && !this.$data.imageFile) {
        return null;
      }

      if (!this.$data.imageFile) {
        return 'Required';
      }

      return null;
    },
    dateValidationErrors() {
      if (!!this.$data.date && this.$data.date > new Date()) {
        return 'Date must be today or earlier';
      }

      return null;
    },
    linkValidationErrors() {
      let linkRegex = new RegExp('^[hH][tT]{2}[pP][sS]?:\\/\\/.+$');
      if (!!this.$data.link && !linkRegex.test(this.$data.link)) {
        return 'Must be a valid URL';
      }

      return null;
    },
    isFormValid() {
      // Don't make the form ugly until a submission attempt has been made.
      if (!this.$data.validationEnabled) {
        return true;
      }

      return !this.uploadValidationErrors &&
        !this.dateValidationErrors &&
        !this.linkValidationErrors;
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  methods: {
    beforeWindowUnload(e) {
      if (ObjectUtil.commonPropertiesMismatch(this.$data.initialState, this.$data)) {
        e.preventDefault();
        return e.returnValue = 'There are unsaved changes. Are you sure you want to leave?';
      }
    },
    initialData() {
      var initialDate = null;
      if (this.initialFanartData?.dateReceived) {
        initialDate = new Date(this.initialFanartData.dateReceived);
      }

      let data = {
        validationEnabled: false,
        date: initialDate,
        link: this.initialFanartData?.link,
        artistName: this.initialFanartData?.artist,
        imageFile: null,
        imageCropData: null,
        cropping: false,
        submitPending: false
      };

      data.initialState = {
        date: data.date,
        imageFile: data.imageFile,
        artistName: data.artistName,
        link: data.link
      };

      return data;
    },
    reset() {
      this.$refs.dialogBanners.clearAll();
      this.$refs.previewImg.reset();
      Object.assign(this.$data, this.initialData());
    },
    roundDecimal(number) {
      return Math.round(number * 1000) / 1000;
    },
    showModal() {
      this.$refs.uploadModal.show();
    },
    submitUpload() {
      this.$data.validationEnabled = true;

      if (!this.isFormValid) {
        return;
      }

      this.$data.submitPending = true;
      this.$refs.dialogBanners.clearType('danger');
      let formData = new FormData();

      if (!this.isEditing || !!this.$data.imageFile) {
        formData.append('image', this.$data.imageFile);
      }

      if (this.$data.link) {
        formData.append('link', HtmlUtil.decodeHtmlEntities(this.$data.link));
      }

      if (this.$data.date) {
        formData.append('receiveDate', this.$data.date.toISOString().substring(0, 10));
      }

      if (this.$data.artistName) {
        formData.append('artist', HtmlUtil.decodeHtmlEntities(this.$data.artistName));
      }

      formData.append('cropX', this.roundDecimal(this.$data.imageCropData.x));
      formData.append('cropY', this.roundDecimal(this.$data.imageCropData.y));
      formData.append('cropWidth', this.$data.imageCropData.width);
      formData.append('cropHeight', this.$data.imageCropData.height);

      let route = this.isEditing
        ? `/api/fanart/${this.initialFanartData.id}`
        : '/api/fanart';

      Axios.post(route, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          this.$emit('upload');
          this.$refs.uploadModal.dismiss();
        })
        .catch((error) => {
          let errorMessage = "Failed to save fanart. Please try again later.";
          if (error.response && error.response.data.message) {
            errorMessage += ` Message: ${error.response.data.message}`;
          }

          this.$refs.dialogBanners.add({
            class: 'danger',
            dismissible: false,
            message: errorMessage
          });
        })
        .then(() => {
          this.$data.submitPending = false;
        });
    },
    handleFileSelect(file) {
      this.$data.imageFile = file;
    },
    handleImageCropped(cropData) {
      this.$data.imageCropData = cropData;
    },
    handleDialogDisplayed() {
      this.$data.cropperKey = Math.random();
    },
    decodeEntities(value) {
      return HtmlUtil.decodeHtmlEntities(value);
    }
  }
};
</script>

<template>
  <div class="fanart-upload-wrapper">
    <modal-dialog
      ref="uploadModal"
      waiting-text="Saving"
      size-large
      :title="modalHeaderText"
      :ok-button-text="modalAcceptText"
      :ok-button-disabled="cropping || (validationEnabled && !isFormValid)"
      :is-waiting="submitPending"
      @showing="handleDialogDisplayed"
      @closed="reset"
      @accept="submitUpload"
    >
      <banner-stack ref="dialogBanners" />
      <div class="row gx-2 align-items-center">
        <div class="col-8 mb-3">
          <croppable-image-upload
            ref="previewImg"
            :key="cropperKey"
            :validation-enabled="validationEnabled"
            :validation-errors="uploadValidationErrors"
            :crop-aspect="1"
            :crop-preview-target="$refs.thumbPreview"
            :initial-image="initialFanartData?.imageUrl"
            :initial-crop-data="initialCropData"
            @select="handleFileSelect"
            @cropped="handleImageCropped"
            @crop-start="cropping = true"
            @crop-end="cropping = false"
          />
        </div>
        <div class="col center thumbnail-area">
          <div class="mb-1">
            Thumbnail Preview
          </div>
          <div
            ref="thumbPreview"
            class="thumbnail-preview mb-4"
          />
          <button
            type="button"
            class="btn btn-outline-secondary mb-2"
            @click="$refs.previewImg.triggerUploadDialog()"
          >
            <span class="fas fa-file-upload" /> Select Image
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary"
            :disabled="cropping || (!imageFile && !initialFanartData?.imageUrl)"
            @click="$refs.previewImg.changeThumbnail()"
          >
            <span class="fas fa-crop-alt" /> Change Thumbnail
          </button>
        </div>
      </div>
      <div class="row gx-3">
        <div class="col-sm">
          <form
            novalidate
            @submit.prevent.stop="submitUpload"
          >
            <div class="mb-3">
              <label
                class="form-label"
                for="dateReceived"
              >
                Date Received
              </label>
              <date-picker
                v-model="date"
                mode="date"
                timezone="UTC"
                :masks="{ input: 'MM/DD/YYYY' }"
              >
                <template #default="{ inputValue, showPopover }">
                  <div class="input-group has-validation">
                    <span class="input-group-text">
                      <span
                        class="fas fa-calendar-alt center"
                        style="width: 1rem;"
                      />
                    </span>
                    <input
                      id="dateReceived"
                      ref="receivedDate"
                      type="text"
                      class="form-control"
                      :value="inputValue"
                      :class="{ 'is-invalid': validationEnabled && !!dateValidationErrors }"
                      @click="showPopover()"
                      @focus="showPopover()"
                    >
                    <div class="invalid-feedback">
                      {{ dateValidationErrors }}
                    </div>
                  </div>
                </template>
              </date-picker>
            </div>
            <div class="mb-3">
              <label
                class="form-label"
                for="artistName"
              >
                Artist Name
              </label>
              <input
                id="artistName"
                :value="decodeEntities(artistName)"
                type="text"
                class="form-control"
                @input="artistName = $event.target.value"
              >
            </div>
            <div class="mb-3">
              <label
                class="form-label"
                for="link"
              >
                Original Artwork/Artist Link
              </label>
              <input
                id="link"
                :value="decodeEntities(link)"
                :class="{ 'is-invalid': validationEnabled && !!linkValidationErrors }"
                type="text"
                class="form-control"
                placeholder="https://www.deviantart.com/..."
                @input="link = $event.target.value"
              >
              <div class="invalid-feedback">
                {{ linkValidationErrors }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.fanart-upload-wrapper {
  .thumbnail-area {
    .btn {
      width: 12em;
    }

    /*
    * HACK: The cropper keeps changing the size of the preview box, so mark it
    * with !important.
    */
    .thumbnail-preview {
      border: 1px solid #aaa;
      width: 75px !important;
      height: 75px !important;
      margin: auto;
      background-color: #ccc;
      overflow: hidden;
    }
  }
}
</style>

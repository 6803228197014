<script>
import PaginationArea from '../common/pagination-area.vue';
import DateUtil from '../../shared/dateutil.js';
export default {
  components: {
    PaginationArea
  },
  props: {
    runs: {
      type: Array,
      default: () => []
    }
  },
  emits: {
    details: null
  },
  data() {
    return {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content'),
      itemsDisplayed: 5,
      itemOffset: 0,
    };
  },
  computed: {
    displayedRuns() {
      let start = this.$data.itemOffset;
      let end = Math.min(start + this.$data.itemsDisplayed, this.runs.length);

      return this.runs.slice(start, end);
    }
  },
  methods: {
    getStatusIcon(run) {
      if (!run.isSuccess && !run.canceled) {
        return ['fa-times-circle', 'text-danger'];
      }
      else if (!run.isSuccess) {
        return ['fa-minus-circle', 'text-muted'];
      }

      return ['fa-check-circle', 'text-success'];
    },
    getStatusText(run) {
      return (!run.isSuccess) ? 'Failed' : 'Succeeded';
    },
    getPublishTime(run) {
      const date = DateUtil.getUsDateString(run.startTime, this.timeZonePreference);
      const time = DateUtil.getUsTimeString(run.startTime, this.timeZonePreference);
      return `${date} ${time}`;
    },
    handlePageChange(numberDisplayed, offset) {
      this.$data.itemsDisplayed = numberDisplayed;
      this.$data.itemOffset = offset;
    },
    handleDetailsClick(id) {
      this.$emit('details', id);
    }
  }
};
</script>

<template>
  <div class="previous-publishes-wrapper">
    <div class="px-2">
      <table class="table">
        <thead>
          <tr>
            <th
              scope="col"
              class="center"
            >
              Status
            </th>
            <th scope="col">
              Date/Time
            </th>
            <th scope="col">
              Started by
            </th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="run in displayedRuns"
            :key="run"
          >
            <td class="center">
              <span
                class="fas"
                :class="getStatusIcon(run)"
                :title="getStatusText(run)"
              />
            </td>
            <td>{{ getPublishTime(run) }}</td>
            <td :class="{ 'italic': !run.startedBy }">
              {{ run.startedBy ?? 'Automatic' }}
            </td>
            <td>
              <a
                href="#"
                @click.prevent="handleDetailsClick(run.id)"
              >Details</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination-area
      compact
      :page-options="[5, 10, 20]"
      :item-count="runs.length"
      :initial-items-per-page="itemsDisplayed"
      @page-change="handlePageChange"
    />
  </div>
</template>

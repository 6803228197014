<script>
import ChapterTab from './chapter-tab.vue';
import Sortable from 'sortablejs';
export default {
  components: {
    ChapterTab
  },
  props: {
    chapters: {
      type: Array,
      default: () => []
    },
    selectedChapter: {
      type: Object,
      default: () => {}
    },
    preventEdit: Boolean
  },
  emits: {
    edit: null,
    create: null,
    delete: null,
    reorder: null
  },
  mounted() {
    Sortable.create(this.$refs.chapterList, {
      handle: '.drag-handle:not(.disabled)',
      draggable: '.chapter-tab',
      animation: 150,
      onSort: (evt) => {
        this.$emit('reorder', evt.oldDraggableIndex, evt.newDraggableIndex);
      }
    });
  },
  methods: {
    fireCreateEvent() {
      if (this.selectedChapter != null) {
        return;
      }
      this.$emit('create');
    }
  }
};
</script>

<template>
  <div class="d-grid">
    <button
      type="button"
      class="btn btn-primary mb-3 py-2"
      :disabled="preventEdit || !!selectedChapter"
      @click="fireCreateEvent"
    >
      <span class="fas fa-plus" /> New Chapter
    </button>
  </div>
  <div
    ref="chapterList"
    class="chapter-list"
  >
    <div
      v-if="!chapters.length"
      class="text-muted center mt-2"
    >
      No chapters have been set up. Click "New Chapter" to add one.
    </div>
    <chapter-tab
      v-for="(chapter, index) in chapters"
      :id="chapter.id"
      :key="index"
      :title="chapter.title"
      :selected="chapter === selectedChapter"
      :enable-drag="!preventEdit && (!selectedChapter || chapter === selectedChapter)"
      :prevent-edit="preventEdit"
      @edit="$emit('edit', index)"
      @delete="$emit('delete', index)"
    />
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
export default {
  components: {
    VueCropper
  },
  props: {
    validationEnabled: Boolean,
    validationErrors: {
      type: String,
      default: null
    },
    initialImage: {
      type: String,
      default: null
    },
    initialCropData: {
      type: Object,
      default: null
    },
    cropViewMode: {
      type: Number,
      default: 2
    },
    cropAutoCropArea: {
      type: Number,
      default: 0.8
    },
    cropAspect: {
      type: Number,
      default: 1
    },
    cropPreviewTarget: {
      type: Object,
      default: () => {}
    },
    cropGuides: {
      type: Boolean,
      default: false
    },
    cropHighlight: {
      type: Boolean,
      default: false
    },
    cropMovable: {
      type: Boolean,
      default: false
    },
    cropRotatable: {
      type: Boolean,
      default: false
    },
    cropScalable: {
      type: Boolean,
      default: false
    },
    cropDragMode: {
      type: String,
      default: 'none'
    },
    cropZoomable: {
      type: Boolean,
      default: false
    },
    cropBackground: {
      type: Boolean,
      default: false
    },
    fileTypeAccept: {
      type: String,
      default: "image/*"
    },
    previewWidth: {
      type: Number,
      default: 0
    },
    previewHeight: {
      type: Number,
      default: 300
    },
    minCropHeight: {
      type: Number,
      default: 0
    },
    minCropWidth: {
      type: Number,
      default: 0
    }
  },
  emits: {
    error: null,
    cropped: null,
    cropChange: null,
    select: null,
    imageLoaded: null,
    cropStart: null,
    cropEnd: null,
    reset: null
  },
  data() {
    return this.initialData();
  },
  computed: {
    cssProps() {
      var props = {
        '--preview-width': 'auto',
        '--preview-height': `${this.previewHeight}px`
      };

      if (this.previewWidth != 0) {
        props['--preview-width'] = `${this.previewWidth}px`;
      }

      return props;
    },
    hasImage() {
      return !!this.$data.stagedFilePath || !!this.initialImage;
    }
  },
  watch: {
    stagedFilePath(newValue, oldValue) {
      if (newValue == oldValue) {
        return;
      }

      if (this.$refs.previewImg) {
        this.$refs.previewImg.destroy();
      }

      if (!newValue) {
        this.$data.displayedImage = this.initialImage;
      }
      else {
        this.$data.displayedImage = this.$data.stagedFilePath;
      }
    }
  },
  methods: {
    initialData() {
      return {
        stagedFilePath: null,
        uploadFiles: null,
        cropping: false,
        lastCrop: null,
        uploadDragging: 0,
        previewKey: null,
        displayedImage: this.initialImage
      };
    },
    reset() {
      if (this.hasImage) {
        this.$refs.previewImg.destroy();
      }

      Object.assign(this.$data, this.initialData());

      this.$emit('reset');
    },
    handleUploadAreaClick() {
      if (!this.cropping) {
        this.triggerUploadDialog();
      }
    },
    triggerUploadDialog() {
      this.$refs.uploadField.click();
    },
    handleFileDragEnter() {
      this.$data.uploadDragging++;
    },
    handleFileDragLeave() {
      this.$data.uploadDragging--;
    },
    handleFileDrop(ev) {
      this.$data.uploadDragging = 0;

      if (!ev.dataTransfer || ev.dataTransfer.files.length === 0) {
        return;
      }

      if (!ev.dataTransfer.files[0].type.startsWith("image/")) {
        return;
      }

      this.handleFiles(ev.dataTransfer.files);
    },
    handleFileSelection(ev) {
      if (!ev.target.files[0].type.startsWith("image/")) {
        this.$data.uploadFiles = null;
        return;
      }

      this.handleFiles(ev.target.files);
    },
    handleFiles(files) {
      if (files.length === 0) {
        this.$data.stagedUpload = null;
        return;
      }

      if (this.$data.cropping) {
        this.rejectCropChange();
      }

      if (this.hasImage) {
        this.$refs.previewImg.destroy();
      }

      this.$data.lastCrop = null;
      this.$data.uploadFiles = files;
      this.$data.uploadError = null;
      this.$data.stagedUpload = null;
      this.$data.stagedFilePath = URL.createObjectURL(files[0]);
      this.$emit('select', files[0]);
    },
    handleImageLoaded(ev) {
      let imageHeight = ev.target.naturalHeight;
      let imageWidth = ev.target.naturalWidth;
      this.$emit('imageLoaded', imageHeight, imageWidth);

      if (this.$data.stagedFilePath) {
        URL.revokeObjectURL(this.$data.stagedFilePath);
      }

      let minWidth = this.minCropWidth;
      let minHeight = this.minCropHeight;

      // If we were given minimum dimensions, destroy the cropper if the image
      // is below minimum size.
      if (
        !!minWidth &&
        !!minHeight &&
        (imageWidth < minWidth || imageHeight < minHeight)
      ) {
        this.$refs.previewImg.destroy();
        return;
      }

      this.initialCrop();
    },
    initialCrop() {
      this.$refs.previewImg.initCrop();

      if (!!this.initialCropData && this.initialImage == this.$data.displayedImage) {
        this.$refs.previewImg.setData(this.initialCropData);
      }

      let cropBoxData = this.$refs.previewImg.getCropBoxData();
      let cropData = this.$refs.previewImg.getData();
      this.$emit('cropped', cropData);
      this.$data.lastCrop = cropBoxData;
      this.$refs.previewImg.clear();
    },
    changeThumbnail() {
      this.$data.cropping = true;
      this.$emit('cropStart');
      this.$refs.previewImg.initCrop();
      this.$refs.previewImg.setCropBoxData(this.$data.lastCrop);
    },
    handleCropChanging(ev) {
      let width = ev.detail.width;
      let height = ev.detail.height;
      let minWidth = this.minCropWidth;
      let minHeight = this.minCropHeight;

      if (width == 0 || height == 0) {
        return;
      }

      if (
        !!minWidth &&
        !!minHeight &&
        (width < minWidth || height < minHeight)
      ) {
        this.$refs.previewImg.setData({
          width: minWidth,
          height: minHeight
        });
        return;
      }

      this.$emit('cropChange', ev.detail);
    },
    acceptCropChange() {
      let cropBoxData = this.$refs.previewImg.getCropBoxData();
      let cropData = this.$refs.previewImg.getData();
      this.$emit('cropped', cropData);
      this.$data.lastCrop = cropBoxData;
      this.$refs.previewImg.clear();
      this.$data.cropping = false;
      this.$emit('cropEnd');
    },
    rejectCropChange() {
      this.$refs.previewImg.setCropBoxData(this.$data.lastCrop);
      this.$refs.previewImg.clear();
      this.$data.cropping = false;
      this.$emit('cropEnd');
    }
  }
};
</script>

<template>
  <div class="croppable-wrapper">
    <input
      ref="uploadField"
      type="file"
      hidden
      :class="{ 'is-invalid': validationEnabled && !!validationErrors }"
      :files="uploadFiles"
      :accept="fileTypeAccept"
      @change="handleFileSelection"
    >
    <div
      class="me-auto ms-auto thumbnail-upload-area d-flex align-items-center justify-content-center"
      :class="{ 'has-image': hasImage }"
      :style="cssProps"
      @click="handleUploadAreaClick"
      @drop.stop.prevent="handleFileDrop"
      @dragenter.stop.prevent="handleFileDragEnter"
      @dragover.stop.prevent
      @dragleave.stop.prevent="handleFileDragLeave"
    >
      <div
        v-if="uploadDragging > 0"
        class="upload-hover"
      />
      <div
        class="upload-img-preview"
        :class="{ cropping: cropping }"
      >
        <vue-cropper
          v-if="hasImage"
          ref="previewImg"
          :key="`previewImg-${displayedImage}`"
          alt="Upload preview"
          :src="displayedImage"
          :view-mode="cropViewMode"
          :auto-crop="false"
          :auto-crop-area="cropAutoCropArea"
          :aspect-ratio="cropAspect"
          :preview="cropPreviewTarget"
          :guides="cropGuides"
          :highlight="cropHighlight"
          :movable="cropMovable"
          :rotatable="cropRotatable"
          :scalable="cropScalable"
          :zoomable="cropZoomable"
          :drag-mode="cropDragMode"
          :background="cropBackground"
          :restore="false"
          @ready="handleImageLoaded"
          @crop="handleCropChanging"
        />
        <div v-else>
          Drag an image here or click this area to get started.
        </div>
      </div>
      <div
        v-if="cropping"
        class="crop-btns"
      >
        <button
          type="button"
          class="btn btn-sm btn-success me-2"
          @click.stop="acceptCropChange"
        >
          <span class="fas fa-check" />
        </button>
        <button
          type="button"
          class="btn btn-sm btn-danger"
          @click.stop="rejectCropChange"
        >
          <span class="fas fa-trash-alt" />
        </button>
      </div>
    </div>
    <div class="invalid-feedback">
      {{ validationErrors }}
    </div>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
@import "cropperjs/dist/cropper";
.croppable-wrapper {
  .thumbnail-upload-area {
    border: 1px solid #aaa;
    background-color: #ccc;
    height: var(--preview-height);
    width: var(--preview-width);
    position: relative;
    cursor: pointer;

    .upload-img-preview {
      text-align: center;
      font-size: 0.8rem;
    }

    .upload-hover {
      position: absolute;
      top: 0.3rem;
      bottom: 0.3rem;
      left: 0.3rem;
      right: 0.3rem;
      background-color: rgba(var(--bs-primary-rgb), 0.5);
      background-image: url(../img/upload-white.svg);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 10%;
      border: 3px dashed $primary;
      border-radius: 5px;
      z-index: 15001;
    }

    &.cropping {
      cursor: unset;
    }

    .crop-btns {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 1rem;
      z-index: 15000;
    }

    &.has-image {
      background-color: $black;

      .upload-img-preview {
        height: 100%;
        width: 100%;

        div {
          height: 100%;
          width: 100%;
        }
      }

      img {
        display: block;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
};
</style>

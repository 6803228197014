<script>
// TODO: waiting state doesn't properly prevent escape/clickaway.
import { Modal } from 'bootstrap';
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    dismissButtonText: {
      type: String,
      default: 'Cancel'
    },
    okButtonText: {
      type: String,
      default: 'OK'
    },
    isDestructive: Boolean,
    disableAnimation: Boolean,
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showDismissButton: {
      type: Boolean,
      default: true
    },
    enableClickaway: Boolean,
    okButtonDisabled: Boolean,
    sizeSmall: Boolean,
    sizeLarge: Boolean,
    sizeXLarge: Boolean,
    isWaiting: Boolean,
    waitingText: {
      type: String,
      default: 'Loading'
    }
  },
  emits: {
    closed: null,
    accept: null,
    showing: null,
    shown: null
  },
  data() {
    return {
      bsModal: null
    };
  },
  computed: {
    canClickAway() {
      return this.enableClickaway && !this.isWaiting;
    }
  },
  mounted() {
    this.$data.bsModal = new Modal(this.$el);
  },
  methods: {
    show() {
      this.$data.bsModal.show();
    },
    dismiss() {
      this.$data.bsModal.hide();
    },
    handleClose() {
      this.$emit('closed');
    },
    handleShowing() {
      // Give a bit of time for the DOM to be rendered.
      setTimeout(() => this.$emit('showing'), 200);
    },
    handleShown() {
      this.$emit('shown');
    }
  }
};
</script>

<template>
  <div
    class="modal"
    tabindex="-1"
    :class="{ 'fade': !disableAnimation }"
    :data-bs-backdrop="canClickAway || 'static'"
    :data-bs-keyboard="canClickAway"
    v-on="{ 'hidden.bs.modal': handleClose, 'shown.bs.modal': handleShown, 'show.bs.modal': handleShowing}"
  >
    <div
      class="modal-dialog"
      :class="{ 'modal-sm': sizeSmall, 'modal-lg': sizeLarge, 'modal-xl': sizeXLarge}"
    >
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header">
            <h5 class="modal-title">
              {{ title }}
            </h5>
          </slot>
          <button
            v-if="showCloseButton"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            :disabled="isWaiting"
          />
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer">
          <div
            v-if="isWaiting"
            class="me-auto"
          >
            <span class="fas fa-circle-notch" /> {{ waitingText }}&hellip;
          </div>
          <button
            v-if="showDismissButton"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            :disabled="isWaiting"
          >
            {{ dismissButtonText }}
          </button>
          <button
            type="button"
            :class="{ 'btn-danger': isDestructive, 'btn-primary': !isDestructive }"
            :disabled="okButtonDisabled || isWaiting"
            class="btn"
            @click="$emit('accept')"
          >
            {{ okButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.modal {
  @keyframes spinner {
    to {transform: rotate(390deg);}
  }

  .fa-circle-notch {
    transform: rotate(30deg);
    animation: spinner 1s ease infinite;
  }
}
</style>

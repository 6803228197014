<script>
import DateUtil from '../../shared/dateutil.js';
import PublishScheduled from '../publishing/publish-scheduled.vue';
import PublishUnscheduled from '../publishing/publish-unscheduled.vue';
export default {
  components: {
    PublishUnscheduled,
    PublishScheduled
  },
  props: {
    scheduledChangeData: {
      type: Array,
      default: () => []
    },
    unscheduledChangeData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      timeZonePreference: document.querySelector('meta[name="tz-pref"]').getAttribute('content')
    };
  },
  computed: {
    unscheduledChanges() {
      let items = this.unscheduledChangeData;
      return {
        'incentive': this.splitToChangeTypes(items.filter(x => x.itemType == 'incentive')),
        'dailySketch': this.splitToChangeTypes(items.filter(x => x.itemType == 'sketch')),
        'chapter': this.splitToChangeTypes(items.filter(x => x.itemType == 'chapter')),
        'fanart': this.splitToChangeTypes(items.filter(x => x.itemType == 'fanart')),
        'comic': this.splitToChangeTypes(items.filter(x => x.itemType == 'page'))
      };
    },
    scheduledChanges() {
      let items = this.scheduledChangeData;

      let dates = {};
      items.forEach(item => {
        let shortDate = DateUtil.getConvertedIsoStringMidnight(item.publishDateTime, this.$data.timeZonePreference);
        if (!(shortDate in dates)) {
          dates[shortDate] = [];
        }

        // Sketches use the link as the title.
        let title = item.title;
        if (item.itemType == 'sketch') {
          title = item.link?.replace(/https?:\/\/(?:www\.)?/i, '');
        }

        dates[shortDate].push({
          'date': item.publishDateTime,
          'type': this.translateScheduledItemType(item.itemType),
          'title': title,
          'link': item.link
        });
      });

      return dates;
    }
  },
  methods: {
    splitToChangeTypes(changeItems) {
      return {
        'add': changeItems.filter(x => x.changeType == 'add'),
        'modify': changeItems.filter(x => x.changeType == 'modify'),
        'remove': changeItems.filter(x => x.changeType == 'delete')
      };
    },
    translateScheduledItemType(type) {
      switch (type) {
        case 'page':
          return 'Page';
        case 'sketch':
          return 'Daily Sketch';
        case 'incentive':
          return 'Incentive';
        case 'chapter':
          return 'Chapter';
        case 'fanart':
          return 'Fanart';
        default:
          return 'Unknown';
      }
    }
  }
};
</script>

<template>
  <div class="publish-change-content">
    <div class="mb-4">
      <h2 class="mb-1">
        Ready for Publishing
      </h2>
      <p class="text-muted smalltext mb-2">
        These items are ready for publishing but are not scheduled. They will be published on the next
        manual or automatic publish.
      </p>

      <publish-unscheduled
        v-if="!!unscheduledChangeData.length"
        :changes="unscheduledChanges"
        class="mb-3"
      />
      <div
        v-else
        class="mb-3 italic text-muted mt-3"
      >
        No unscheduled changes are currently queued.
      </div>
    </div>

    <h2 class="mb-1">
      Upcoming Automatic Publishes
    </h2>
    <p class="smalltext text-muted mb-2">
      Items listed below will automatically publish at the listed date and time.
    </p>
    <publish-scheduled
      v-if="!!scheduledChangeData.length"
      :changes="scheduledChanges"
    />
    <div
      v-else
      class="italic text-muted mt-3"
    >
      No scheduled changes are currently queued.
    </div>
  </div>
</template>

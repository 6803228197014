<script>
export default {
  props: {
    previewImg: {
      type: String,
      default: ''
    },
    previewFallback: {
      type: String,
      default: 'No image available'
    },
    previewAlt: {
      type: String,
      default: ''
    },
    heading: {
      type: String,
      default: ''
    },
    buttonLink: {
      type: String,
      default: ''
    },
    isUnavailable: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
  <div class="col-auto mb-4 dash-current">
    <div class="dash-heading">
      {{ heading }}
    </div>
    <div class="center">
      <div class="preview">
        <a
          v-if="!isUnavailable && previewImg"
          :href="buttonLink"
        >
          <img
            :src="previewImg"
            :alt="previewAlt"
          >
        </a>
        <div v-else-if="!isUnavailable">
          {{ previewFallback }}
        </div>
        <div
          v-else
          class="italic"
        >
          Nothing is currently published.
        </div>
      </div>
      <a
        :href="buttonLink"
        class="btn btn-sm btn-outline-primary"
      >
        Manage
      </a>
    </div>
  </div>
</template>

<style lang="scss">
@import "resources/sass/_bs";
.dash-current {
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-radius: 5px;
  padding: 0.7rem;
  margin-right: 1rem;
  display: inline-block;

  .dash-heading {
    color: $black;
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 1.1em;
  }

  .preview {
    padding: 0 1rem;
    margin-bottom: 0.8rem;
    height: 210px;
    width: calc(160px + 1rem);
    overflow: hidden;

    img {
      object-fit: contain;
    }

    div, a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }
  }
}
</style>
